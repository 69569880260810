<template>
  <v-dialog content-class="send_message" persistent max-width="1500" v-model="isModalOpen">
    <v-card>
      <v-progress-linear
        style="z-index: 6"
        color="primary"
        indeterminate
        height="4"
        v-if="isLoading"
      ></v-progress-linear>
      <v-icon
        class="d-flex ml-auto pt-2 pr-4 justify-end"
        @click.native="closeModal()"
      >
        close
      </v-icon>
      <v-card-text>
        <v-layout wrap>
          <v-flex sm5 xs12>
            <v-card class="pt-0 mt-0" style="box-shadow: none !important;">
              <v-card-title class="pt-0 mt-0 title_card">
                <span class="title">Send a message</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-flex sm12 xs12>
                  <v-expansion-panels
                    class="mt-2"
                    v-model="isClientsPanelOpen"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>Selected clients({{
                          selectedClients.length
                        }})
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-flex
                          class="d-flex flex-wrap align-center mb-2"
                          :class="isMobile ? 'justify-center' : 'justify-space-between ' "
                        >
                          <v-checkbox
                            color="primary"
                            :label="isAllClientsSelected ? 'Remove all selected clients' : 'Select all clients'"
                            v-model="isAllClientsSelected"
                            :input-value="true"
                            @change="onSelectedAllClients"
                            hide-details
                            dense
                          ></v-checkbox>
                          <v-btn
                            color="primary"
                            :class="isMobile ? 'my-2': ''"
                            small
                            :disabled="selectedClients.length < 1 || isLoading"
                            @click="onUploadRecipients"
                          >
                            Upload recipients
                          </v-btn>
                        </v-flex>
                        <v-select
                          v-model="selectedClients"
                          :items="clientList"
                          label="Selected Client(s)"
                          item-text="association"
                          return-object
                          outlined
                          multiple
                          dense
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip
                              color="primary"
                              :key="item.id"
                              @click:close="closeClientChip(index)"
                              close
                            >
                              {{ item.assoc_abbrev }}
                            </v-chip>
                          </template>
                        </v-select>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>

                <v-flex
                  sm12 xs12
                  v-if="selectedClients && selectedClients.length && isUploadingRecipients"
                >
                  <v-btn-toggle
                    class="mt-2 d-flex flex-row"
                    v-model="recipientType"
                    multiple
                    rounded
                    @change="onChoseRecipientTypes"
                  >
                    <v-btn
                      class="flex-grow-1"
                      small
                    >
                      ADMINS
                    </v-btn>
                    <v-btn
                      class="flex-grow-1"
                      small
                    >
                      COORDINATORS
                    </v-btn>
                    <v-btn
                      class="flex-grow-1"
                      small
                    >
                      KPS
                    </v-btn>
                  </v-btn-toggle>
                </v-flex>

                <v-flex
                  sm12 xs12
                  v-if="recipientType.length > 0 && isUploadingRecipients"
                >
                  <v-expansion-panels
                    class="mt-2"
                    v-model="isRecipientsPanelOpen"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>Selected recipients({{
                          selectedRecipients.length
                        }})
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-checkbox
                          color="primary"
                          :label="isAllRecipientsSelected ? 'Remove all selected recipients' : 'Select all recipients'"
                          v-model="isAllRecipientsSelected"
                          :input-value="true"
                          @change="onSelectedAllRecipients"
                          dense
                        ></v-checkbox>
                        <v-autocomplete
                          v-model="selectedRecipients"
                          :items="recipientsList"
                          outlined
                          dense
                          color="blue-grey lighten-2"
                          label="Select Recipients"
                          :item-text="item => item.nickname + ' ' + item.lastname"
                          multiple
                          return-object
                          :disabled="isLoading"
                          :loading="isLoading"
                          :search-input.sync="searchRecipients"
                          @change="searchRecipients=''"
                          hide-details
                        >
                          <template v-slot:selection="{item}"></template>
                          <template v-slot:item="data">
                            <template>
                              <v-list-item-avatar class="avatar_image">
                                <img v-if="data.item.avatar_url" :alt="data.item.fullname"
                                     :src="getThumbImageMedium(data.item.avatar_url)"/>
                                <img v-else :alt="data.item.nickname" src="images/member-avatar.jpg"/>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="data.item.nickname + ' ' + data.item.lastname"></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                        <div class="pt-1" v-if="selectedRecipients && selectedRecipients.length">
                          <div class="d-flex flex-wrap">
                            <div v-for="(item, index) of selectedRecipients">
                              <v-chip
                                class="ma-1"
                                :style="kpPreferenceBorder(item)"
                                :key="item.id"
                                @click:close="closeRecipientChip(index)"
                                close
                              >
                                <v-icon left size="16" :color="isBad(item.email) ? 'error' : 'primary'">
                                  email
                                </v-icon>
                                <v-icon
                                  v-if="item.bad_cell || item.cell === null"
                                  left
                                  size="16"
                                  color="error"
                                >
                                  no_cell
                                </v-icon>
                                <v-icon
                                  v-else
                                  left
                                  size="16"
                                  color="primary"
                                >
                                  phone_iphone
                                </v-icon>
                                {{ item.nickname }} {{ item.lastname }}
                              </v-chip>
                            </div>
                          </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex sm7 xs12>
            <client-send-message-component
              ref="ClientSendMessageComponent"
              :recipients="selectedRecipients"
              @closeModal="closeModal"
            ></client-send-message-component>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ClientService from "@/services/ClientService";
import SuperAdminService from "@/services/SuperAdminService";
import ClientSendMessageComponent from "@/components/command/clients-message-modal/client-send-message-component"

export default {
  name: 'ClientsMessageModal',
  mixins: [ClientService, SuperAdminService],
  components: {
    "client-send-message-component": ClientSendMessageComponent
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      recipientType: [],
      selectedClients: [],
      clientList: [],
      selectedRecipients: [],
      recipientsList: [],
      displayRecipients: [],
      vipRecipients: [],
      coordinatorRecipients: [],
      kpRecipients: [],
      searchRecipients: '',
      isClientsPanelOpen: false,
      isAllClientsSelected: false,
      isRecipientsPanelOpen: false,
      isAllRecipientsSelected: false,
      isUploadingRecipients: false,
      timer: null,
      timer2: null,
    }
  },
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    async toggle() {
      this.isLoading = true
      this.isModalOpen = true
      await this.clearSettings()
      this.isLoading = false
    },
    async toggleWithScheduleMessage(scheduleMessage) {
      this.isLoading = true
      this.isModalOpen = true
      await this.clearSettings()

      const selectedRecipients = this.getScheduleMessageSelectedRecipients(scheduleMessage)
      this.getScheduleMessageSelectedClients(selectedRecipients)
      this.onUploadRecipients()
      this.awaitOnChoseRecipientType(0)
      this.awaitSetRecipients(0, selectedRecipients)
      this.setMessageSettings(scheduleMessage)
      this.isLoading = false
    },
    setMessageSettings(scheduleMessage) {
      let deliveryAt = moment(scheduleMessage.delivery_at, 'YYYY-MM-DD HH:mm:ss')
      this.$refs.ClientSendMessageComponent.reset()
      this.$refs.ClientSendMessageComponent.isEmail = !scheduleMessage.is_sms
      this.$refs.ClientSendMessageComponent.message = scheduleMessage.message
      this.$refs.ClientSendMessageComponent.subject = scheduleMessage.subject
      this.$refs.ClientSendMessageComponent.from = scheduleMessage.from
      this.$refs.ClientSendMessageComponent.isSendNow = false
      this.$refs.ClientSendMessageComponent.date = deliveryAt.format('YYYY-MM-DD')
      this.$refs.ClientSendMessageComponent.time = deliveryAt.format('HH:mm')
      this.$refs.ClientSendMessageComponent.scheduleMessageId = scheduleMessage.id
    },
    awaitSetRecipients(count, recipients) {
      count++
      if (count > 200) {
        clearTimeout(this.timer2)
        return false;
      }

      if (!this.isUploadingRecipients) {
        clearTimeout(this.timer2)
        this.timer2 = setTimeout(() => {
          this.awaitSetRecipients(count, recipients)
        }, 10)
        return false;
      }

      clearTimeout(this.timer2)
      this.selectedRecipients = [...recipients]
    },
    awaitOnChoseRecipientType(count) {
      count++
      if (count > 200) {
        clearTimeout(this.timer)
        return false;
      }

      if (!this.isUploadingRecipients) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.awaitOnChoseRecipientType(count)
        }, 10)
        return false;
      }

      clearTimeout(this.timer)
      this.onChoseRecipientTypes()
    },
    init() {
      this.getClientList()
    },
    getScheduleMessageSelectedRecipients(scheduleMessage) {
      let recipients = []
      if (scheduleMessage.users && scheduleMessage.users.length) {
        let users = [...scheduleMessage.users]
        users = users.map(user => {
          user.cell = user.cell_phone
          user.is_user = true
          return user
        })
        this.recipientType = [...this.recipientType, 0]
        recipients = [...users, ...scheduleMessage.members]
      } else {
        recipients = [...scheduleMessage.members]
      }
      if (scheduleMessage.members.some(member => member.regions && member.regions.length > 0)) {
        this.recipientType = [...this.recipientType, 1]
      }
      if (scheduleMessage.members.some(member => !member.regions)) {
        this.recipientType = [...this.recipientType, 2]
      }

      return recipients
    },
    getScheduleMessageSelectedClients(selectedRecipients) {
      const selectClientIds = _.cloneDeep(selectedRecipients)
        .map(recipient => recipient.client_id)
        .filter((value, index, self) => self.indexOf(value) === index);

      this.selectedClients = _.cloneDeep(this.clientList).filter(client => selectClientIds.some(id => id === client.id))
    },
    clearSettings() {
      this.selectedClients = []
      this.recipientType = ''
      this.isAllClientsSelected = false
      this.isAllRecipientsSelected = false
      this.isClientsPanelOpen = false
      this.isUploadingRecipients = false
      if (this.$refs.ClientSendMessageComponen) {
        this.$refs.ClientSendMessageComponent.reset()
      }
    },
    getClientList() {
      this.getClients()
        .then(response => {
          this.clientList = response.data.filter(client => client.active)
        })
        .catch(() => {
          this.onCallToastr('error', 'Something went wrong.', 'Error!')
        })
    },
    closeClientChip(clientIndex) {
      this.selectedClients.splice(clientIndex, 1)
    },
    onSelectedAllClients() {
      if (this.isAllClientsSelected) {
        this.selectedClients = _.cloneDeep(this.clientList)
      } else {
        this.selectedClients = []
      }
    },
    closeRecipientChip(recipientIndex) {
      this.selectedRecipients.splice(recipientIndex, 1)
    },
    onSelectedAllRecipients() {
      this.isLoading = true
      if (this.isAllRecipientsSelected) {
        this.selectedRecipients = [...this.recipientsList]
      } else {
        this.selectedRecipients = []
      }
      this.isLoading = false
    },
    onUploadRecipients() {
      this.isLoading = true

      const params = {
        client_ids: _.cloneDeep(this.selectedClients).map(item => item.id)
      }
      this.getRecipients(params)
        .then(response => {
          this.vipRecipients = response.data.admins
          this.coordinatorRecipients = response.data.coordinators
          this.kpRecipients = response.data.kps
          this.isLoading = false
          this.isUploadingRecipients = true
        })
        .catch((err) => {
          this.handleError(err, true)
          this.isLoading = false
        })
    },
    onChoseRecipientTypes() {
      this.isLoading = true
      this.recipientsList = []

      if (this.recipientType.some(item => item === 2)) {
        this.recipientsList = [...this.kpRecipients]
      }
      if (this.recipientType.some(item => item === 1)) {
        this.recipientsList = this.recipientsList.concat([...this.coordinatorRecipients]);
        this.recipientsList
          .filter((recipient, index, self) =>
            index === self.findIndex((item) => (
              item.id === recipient.id
            ))
          )
          .sort((a, b) => a.nickname > b.nickname ? 1 : -1)
      }
      if (this.recipientType.some(item => item === 0)) {
        const uniqueUsers = [...this.vipRecipients]
          .map(recipient => {
            recipient.cell = recipient.cell_phone
            recipient.is_user = true
            return recipient
          })
          .filter(recipient => {
            if (!recipient.member_id) return true
            return !this.recipientsList.some(item => item.id === recipient.member_id)
          })
        this.recipientsList = this.recipientsList.concat([...uniqueUsers])
        this.recipientsList.sort((a, b) => a.nickname > b.nickname ? 1 : -1);
      }
      this.isLoading = false
    },
    closeModal() {
      this.isModalOpen = false
    }
  },
  watch: {
    "selectedClients.length": function () {
      this.isAllClientsSelected = this.selectedClients.length === this.clientList.length
      this.recipientsList = []
      this.selectedRecipients = []
    },
    "selectedRecipients.length": function () {
      this.isAllRecipientsSelected = this.selectedRecipients.length === this.recipientsList.length
    }
  }
};
</script>

<style type="text/css" scoped>
.title_card {
  padding-bottom: 22px !important;
}
</style>

import axios from 'axios'
import qs from 'qs'

export default {
  methods: {
    showClient(clientId) {
      return axios.get(`/clients/${clientId}`)
    },
    getClients(params) {
      return axios.get('/clients', {params})
    },
    createClient(payload) {
      return axios.post('/clients', payload)
    },
    updateClient(payload) {
      return axios.put(`/clients/${payload.id}`, payload)
    },
    deleteClient(clientId) {
      return axios.delete(`/clients/${clientId}`)
    },
    disableClient(clientId) {
      return axios.post(`/clients/${clientId}/disable`)
    },
    downloadMembers(clientId, params) {
      return axios.get(`/clients/${clientId}/export-members`, {
        responseType: 'blob',
        params: params, paramsSerializer: params => qs.stringify(params)
      })
    },
    downloadMemberImportExample(clientId, payload) {
      return axios.post(`/clients/${clientId}/member-import-example`, payload, { responseType: 'blob'})
    },
    downloadLegislators(clientId, params) {
      return axios.get(`/clients/${clientId}/export-legislators`, {
        responseType: 'blob',
        params: params, paramsSerializer: params => qs.stringify(params)
      })
    },
    downloadConstituents(clientId, params) {
      return axios.get(`/clients/${clientId}/export-constituents`, {
        responseType: 'blob',
        params: params, paramsSerializer: params => qs.stringify(params)
      })
    },
    downloadSelectedLegislators(clientId, params) {
      return axios.get(`/clients/${clientId}/export-selected-legislators`, {
        responseType: 'blob',
        params: params, paramsSerializer: params => qs.stringify(params)
      })
    },
    enableClient(clientId) {
      return axios.post(`/clients/${clientId}/enable`)
    },
    getClientUsers(clientId) {
      return axios.get(`/clients/${clientId}/users`)
    },
    getClientDistricts(clientId, withLegislatorsOnly = null) {
      return axios.get(`/clients/${clientId}/districts`, {params: {withLegislatorsOnly}})
    },
    getClientStateDistricts(clientId) {
      return axios.get(`/clients/${clientId}/state/districts`)
    },
    getClientSessions(clientId) {
      return axios.get(`/clients/${clientId}/sessions`)
    },
    getClientActiveSession(clientId) {
      return axios.get(`/clients/${clientId}/active-session`)
    },
    getClientMembers(clientId) {
      return axios.get(`/clients/${clientId}/members`)
    },
    getClientMember(clientId, memberId) {
      return axios.get(`/clients/${clientId}/members/${memberId}`)
    },
    getClientMemberUser(clientId, memberId) {
      return axios.get(`/clients/${clientId}/members/${memberId}/user`)
    },
    getClientMembersWithPagination(clientId, params) {
      return axios.get(`/clients/${clientId}/members/paginate`, {
        params: params, paramsSerializer: params => qs.stringify(params)
      })
    },
    getClientVipLegislators(clientId) {
      return axios.get(`/clients/${clientId}/vip-legislators`)
    },
    getClientLegislators(clientId, params) {
      return axios.get(`/clients/${clientId}/legislators`, {params})
    },
    getClientLegislatorsWithPagination(clientId, params) {
      return axios.get(`/clients/${clientId}/legislators/paginate`, {
        params: params, paramsSerializer: params => qs.stringify(params)
      })
    },
    getClientDistrictsWithPagination(clientId, params) {
      return axios.get(`/clients/${clientId}/districts/paginate`, {
        params: params, paramsSerializer: params => qs.stringify(params)
      })
    },
    getClientLegislator(clientId, legislatorId) {
      return axios.get(`/clients/${clientId}/legislator/${legislatorId}`);
    },
    getClientMembersByDistrict(clientId, districtId) {
      return axios.get(`/clients/${clientId}/members-by-district/${districtId}`);
    },
    getClientLegislatorsWithCommittees(clientId, params) {
      return axios.get(`/clients/${clientId}/legislators`, {params})
    },
    getClientKPTasks(clientId, params) {
      return axios.get(`/clients/${clientId}/kp-tasks`, {params})
    },
    getClientMessageMembersWithPagination(clientId, params) {
      return axios.get(`/clients/${clientId}/message-members/paginate`, {params})
    },
    getClientMessage(clientId, messageId) {
      return axios.get(`/clients/${clientId}/messages/${messageId}`)
    },
    deleteClientMessage(clientId, messageId) {
      return axios.post(`/clients/${clientId}/messages/${messageId}/delete`)
    },
    getClientScheduleMessages(params) {
      return axios.get(`/schedule-messages`, {params})
    },
    saveClientScheduleMessage(payload) {
      return axios.post(`/schedule-messages`, payload)
    },
    deleteClientScheduleMessage(scheduleMessageId, payload) {
      return axios.post(`/schedule-messages/${scheduleMessageId}/delete`, payload)
    },
    saveScheduleMessageFile(payload) {
      return axios.post(`/schedule-messages/files/store`, payload,  {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      })
    },
    deleteScheduledFile(fileId) {
      return axios.post(`/schedule-messages/files/${fileId}/delete`)
    },
    sendClientScheduleMessage(scheduleMessageId, payload) {
      return axios.post(`/schedule-messages/${scheduleMessageId}/send`, payload)
    },
    getClientMessages(clientId, params) {
      return axios.get(`/clients/${clientId}/messages`, {params})
    },
    getClientMessagesWithPagination(clientId, params) {
      return axios.get(`/clients/${clientId}/messages/paginate`, {params})
    },
    getClientUnseenMessageCount(clientId) {
      return axios.get(`/clients/${clientId}/unseen-message-count`)
    },
    getClientAdvocacyMessages(clientId, params) {
      return axios.get(`/clients/${clientId}/advocacy-messages`, {params})
    },
    sendTestMessage(clientId, payload) {
      return axios.post(`/clients/${clientId}/test-messages`, payload)
    },
    getClientUserSessions(clientId) {
      return axios.get(`/clients/${clientId}/user-sessions`)
    },
    sendEmail(payload, formData = true) {
      return axios.post(`/clients/${payload.clientId}/send-email`,
        formData ? this.packageData(payload) : payload)
    },
    sendText(payload) {
      return axios.post(`/clients/${payload.clientId}/send-text`, payload)
    },
    importClientMembers(clientId, payload) {
      return axios.post(`/clients/${clientId}/import-members`, payload)
    },
    removeAnalyzeResult(clientId, payload) {
      return axios.post(`/remove-analyze-result`, payload)
    },
    importMembersAnalyze(clientId, payload) {
      return axios.post(`/clients/${clientId}/analyze-import`, payload)
    },
    importMembersAnalyzeJson(clientId, payload) {
      return axios.post(`/clients/${clientId}/analyze-import-json`, payload)
    },
    getClientStatus(clientId) {
      return axios.get(`/clients/${clientId}/status`)
    },
    updateClientDistricts(clientId) {
      return axios.post(`/clients/${clientId}/update-districts`)
    },
    getClientKPLeaderboardData(clientId, params) {
      return axios.get(`/clients/${clientId}/kp-leaderboard-data`, {
        params: params, paramsSerializer: params => qs.stringify(params)
      })
    },
    getMyKPs(clientId) {
      return axios.get(`/clients/${clientId}/my-kps`)
    },
    getClientRelationships(clientId) {
      return axios.get(`/clients/${clientId}/relationships`)
    },
    getClientRelationshipsAll(clientId) {
      return axios.get(`/clients/${clientId}/relationships/all`)
    },
    sortClientRelationships(clientId, data) {
      return axios.post(`/clients/${clientId}/relationships/sort`, data)
    },
    getClientRelationshipById(clientId, payload) {
      return axios.get(`/clients/${clientId}/relationships/${payload}`, payload)
    },
    saveClientRelationships(clientId, payload) {
      return axios.post(`/clients/${clientId}/relationships`, payload)
    },
    deleteClientRelationships(clientId, payload) {
      return axios.post(`/clients/${clientId}/relationships/${payload}/delete`)
    },
    // Custom fields
    getClientFields(clientId) {
      return axios.get(`/clients/${clientId}/memberCustomFields`)
    },
    getClientFieldById(clientId, payload) {
      return axios.get(`/clients/${clientId}/memberCustomFields/${payload}`, payload)
    },
    saveClientField(clientId, payload) {
      return axios.post(`/clients/${clientId}/memberCustomFields`, payload)
    },
    deleteClientField(clientId, payload) {
      return axios.post(`/clients/${clientId}/memberCustomFields/${payload}/delete`)
    },
    // Regions
    getClientRegions(clientId) {
      return axios.get(`/clients/${clientId}/regions`)
    },
    getDistrictsWithoutRegion(clientId) {
      return axios.get(`/clients/${clientId}/districts-without-region`)
    },
    getClientRegionDistricts(clientId, regionId) {
      return axios.get(`/clients/${clientId}/regions/${regionId}`)
    },
    sortClientRegions(clientId, data) {
      return axios.post(`/clients/${clientId}/regions/sort`, data)
    },
    getClientRegionById(clientId, payload) {
      return axios.get(`/clients/${clientId}/regions/${payload}`, payload)
    },
    saveClientRegions(clientId, payload) {
      return axios.post(`/clients/${clientId}/regions`, payload)
    },
    deleteClientRegions(clientId, payload) {
      return axios.post(`/clients/${clientId}/regions/${payload}/delete`)
    },
    getKpsByLegislator(clientId, LegislatorId) {
      return axios.get(`/clients/${clientId}/legislators/${LegislatorId}/kps`)
    },
    getSilencedMembers(clientId, params) {
      return axios.get(`/clients/${clientId}/getSilenceMembers`, {params})
    },
    getSystemActions(clientId, params) {
      return axios.get(`/clients/${clientId}/system-actions`, {params})
    },
    getLegislatorNew(clientId, params) {
      return axios.get(`/clients/${clientId}/get-articles`, {params})
    },
    getTariffs() {
      return axios.get(`/tariffs`)
    },
    getFileById(clientId, fileId, payload) {
      return axios.post(`/clients/${clientId}/files/${fileId}`, payload, { responseType: 'blob'})
    },
    sendMessageFilteredTask(clientId, params) {
      return axios.get(`/clients/${clientId}/send-message-filtered-task`, {params})
    },
    getLastActiveSession(clientId) {
      return axios.get(`/clients/${clientId}/last-active-session`)
    },
    getActiveKpsPostion(clientId) {
      return axios.get(`/clients/${clientId}/active-kps-position`)
    },
    changeTariffStatus(payload) {
      return axios.post(`/change-tariff-status`, payload)
    },
  }
}

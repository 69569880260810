<template>
  <div>
    <v-form ref="emailForm" persistent>
      <v-card-text class="pb-0">
        <v-alert type="error" outlined :value="true" v-if="error">{{ error }}</v-alert>
        <v-alert type="error" outlined :value="true" v-if="badEmails.length">
          Members with a red icon have an undeliverable email address and will not receive this email message.
        </v-alert>

        <v-layout wrap>
          <v-flex xs12>
            <v-text-field outlined label="From*" :rules="fromRules" v-model="email.from" @input="error = ''"></v-text-field>
          </v-flex>
          <v-flex xs12 v-if="!client.mailgun_email">
            <v-select
              outlined
              label="Reply To*"
              :items="replyToEmailList"
              :rules="replyToEmailRules"
              v-model="email.reply_to_email"
            ></v-select>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              outlined
              id="subject"
              ref="subject"
              label="Subject*"
              :rules="subjectRules"
              v-model="email.subject"
              @input="error = ''"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 class="mt-3" id="messageBlock">
            <v-layout column class="mb-2">
              <v-flex xs12 class="mb-4">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>Recipients ({{ selectedMembers.length }})</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-select
                        dense
                        outlined
                        return-object
                        :items="filteredCoordinators"
                        v-model="selectedMembers"
                        item-text="fullname"
                        multiple
                        label="Select Coordinator(s)"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip
                            :style="kpPreferenceBorder(item)"
                            :key="item.id"
                            close
                            @click:close="() => selectedMembers.splice(index, 1)"
                          >
                            <v-icon left size="16" :color="isBad(item.email) ? 'error' : 'primary'">
                              email
                            </v-icon>
                            {{ item.fullname }}
                          </v-chip>
                        </template>
                      </v-select>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-flex>
              <v-flex xs12 class="d-flex justify-space-between">
                <div class="pt-3 caption grey--text">Message*</div>
                <v-switch
                  color="primary"
                  hide-details
                  :label="isPreview ? 'Edit' : 'Show Preview'"
                  class="d-inline-block mt-0"
                  :loading="isBusy"
                  :disabled="isBusy"
                  @change="onPreviewMessage"
                  v-model="isPreview"
                ></v-switch>
              </v-flex>
            </v-layout>
            <div class="tiny_editor" v-if="!isPreview">
              <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
              <editor
                v-if="!isBusy"
                :api-key="TINY_MCE_API_KEY"
                v-model="email.message"
                :plugins="tinyPlugins"
                :toolbar="tinyToolbars"
                :init="tinyInit"
                :disabled="disabledEditor"
                @input="inputEmail"
              />
            </div>
            <div v-if="isPreview">
              <editor
                v-if="!isBusy"
                :api-key="TINY_MCE_API_KEY"
                :value="formattedMessage"
                disabled
                :plugins="tinyPlugins"
                :toolbar="tinyToolbars"
                :init="tinyInit"
              />
            </div>
            <div class="d-flex align-baseline justify-space-between">
              <div>
                <v-flex v-if="!isFilesInput" v-model="isFilesInput" @click="isFilesInput = !isFilesInput" class="d-flex align-baseline">
                  <v-icon color="primary" class="pt-4 icon_files">mdi-paperclip</v-icon>
                  <a>Attach files</a>
                </v-flex>
                <v-flex v-if="isFilesInput && !isUploadingFiles" @click="removeAllAttachedFiles" class="d-flex align-baseline">
                  <v-icon class="pt-4 icon_files">mdi-paperclip</v-icon>
                  <span class="v-label red--text">Cancel</span>
                </v-flex>
                <v-file-input
                  v-if="isFilesInput && !isUploadingFiles"
                  class="pt-4"
                  v-model="email.files"
                  color="primary"
                  counter
                  label="File input"
                  multiple
                  placeholder="Select your files"
                  prepend-icon=""
                  outlined
                  :show-size="1000"
                  :rules="fileRules"
                  dense
                  @change="filesInputChanged"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                      color="primary"
                      dark
                      label
                      small
                      close
                      @click:close="removeAttachedFile(index)"
                    >
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
                <div v-if="maxFileSize">
                  <v-alert
                    outlined
                    type="info"
                    text
                  >
                    To reduce the size of the files, you can use
                    <a href="https://shrinkpdf.com/">shrinkpdf.com</a> or
                    <a href="https://imagecompressor.com/">imagecompressor.com</a>
                  </v-alert>
                </div>
                <v-flex v-if="isUploadingFiles" class="pa-5 d-flex align-center justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <div>
                    <span class="ml-3">
                      Please wait, uploading files...
                    </span>
                  </div>
                </v-flex>
                <v-flex v-if="attachedFiles != false" class="pt-5 pb-2">
                  <div>
                    <span>
                      Attached files:
                    </span>
                  </div>
                  <v-chip
                    v-for="(attachedFile, index) in attachedFiles"
                    :key="index"
                    class="mr-2 mt-2"
                    close
                    @click:close="removeAttachedScheduledFile(attachedFile, index)"
                  >
                    {{ attachedFile.name }}
                  </v-chip>
                </v-flex>
                <div v-if="!isSendNow" class="mt-2 full-width">
                  <div class="caption grey--text">Schedule</div>
                  <v-row>
                    <v-col
                      class="py-0"
                      cols="12"
                      sm="12"
                      md="6"
                    >
                      <v-menu
                        ref="dateMenu"
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            v-model="date"
                            label="Select date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            @click:clear="date = null"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          :min="currentDate"
                          color="primary"
                          no-title
                          scrollable
                          @change="$refs.dateMenu.save(date)"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      class="py-0"
                      cols="12"
                      sm="12"
                      md="6"
                    >
                      <v-menu
                        ref="timeMenu"
                        v-model="timeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        :disabled="!date"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            v-model="getFormattedTime"
                            label="Select time"
                            prepend-icon="mdi-clock-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            @click.clear="time = null"
                            :disabled="!date"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="timeMenu"
                          v-model="time"
                          :min="currentDate == date ? currentTime : ''"
                          color="primary"
                          full-width
                          ampm-in-title
                          @click:minute="$refs.timeMenu.save(time)"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                </div>
                <a v-if="email.message" @click="onOpenTestMessageModal">
                  Send a test... <!-- to {{ !isSms ? userData.email : userData.cell_phone }}-->
                </a>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-form>
    <TestMessageModal
      ref="TestMessageModal"
      :isEmail="!isSms"
      :legislatorId="legislatorId"
      :memberId="memberId"
      :email="email"
    >
    </TestMessageModal>
  </div>
</template>

<script>
import AppService from '@/services/AppService'
import ClientService from '@/services/ClientService'
import Editor from '@tinymce/tinymce-vue'
import TinyConfig from "@/mixins/tiny-mce-config"
import TestMessageModal from '@/components/global/test-message-modal'
import ShorterUrl from "@/mixins/shorterUrl";

export default {
  name: "email-coordinator-message",
  props: [
    'client',
    'clientId',
    'coordinators',
    'isSendNow',
    'legislators',
    'scheduledMessage',
    'isSendCopiesToAdmins',
    'isEmail',
  ],
  mixins: [AppService, ClientService, TinyConfig, ShorterUrl],
  components: {
    'editor': Editor, // Do not change, this is a requirement of tinyMCE
    TestMessageModal
  },
  data() {
    return {
      email: {},
      isPreview: false,
      formattedMessage: '',
      formattedSubject: '',
      error: '',
      isBusy: false,
      files: [],
      currFiles: [],
      isFilesInput: false,
      totalLegislatorsList: [],
      selectedMembers: [],
      time: null,
      timeMenu: false,
      dateMenu: false,
      date: null,
      formattedTime: '',
      isUploadingFiles: false,
      attachedFiles: [],
      filteredCoordinators: [],
      isChild: true
    }
  },
  computed: {
    timeValidate() {
      if (this.time) {
        let curTime = this.currentTime.split(':')
        let selectedTime = this.time.split(':')
        if (selectedTime[0] < curTime[0]) {
          return false
        } else if (selectedTime[0] == curTime[0]) {
          if (selectedTime[1] <= curTime[1]) {
            return false
          } else {
            return true
          }
        }
      }
      return true
    },
    currentTime() {
      const timezone = this.getTimezone(this.client);
      return moment().tz(timezone).add(1, 'hour').format('HH:mm');
    },
    badEmails() {
      return this.selectedMembers.filter(member => this.isBad(member.email))
    },
    currentDate() {
      return new Date().toISOString().substr(0, 10)
    },
    getFormattedTime: {
      get() {
        return this.time ? moment(this.time, "hh:mm").format('h:mm a') : ''
      },
      set(value) {
        this.formattedTime = value
      },
    },
    nextDay() {
      let date = new Date();
      date.setDate(date.getDate() + 1);
      return date.toISOString().substr(0, 10)
    },
    formattedDate() {
      return this.date ? moment(this.date).format('dddd, MMMM Do YYYY') : ''
    },
    fromRules() {
      return [
        v => !!v || 'From name is required.',
      ]
    },
    replyToEmailRules() {
      return [
        v => !!v || 'Reply to email address is required.',
      ]
    },
    subjectRules() {
      return [
        v => !!v || 'Subject is required.',
      ]
    },
    messageRules() {
      return [
        v => !!v || 'Message is required.',
      ]
    },
    replyToEmailList() {
      const emailList = []
      if (this.client && this.client.reply_to_email) {
        emailList.push(this.client.reply_to_email)
      }
      emailList.push(this.userEmail)
      return emailList
    },
    getLegislatorList: {
      get: function () {
        return this.legislators
      },
      set: function (newValue) {
        this.totalLegislatorsList = newValue
      }
    },
    getTosList() {
      return this.selectedMembers.map(member => member.email)
    },
    isSms() {
      return this.email.is_sms
    },
    legislatorId() {
      return this.legislators[0].id
    },
    memberId() {
     return this.selectedMembers.length ? this.selectedMembers[0].id : 0
    }
  },
  methods: {
    toggle(clientId) {
      this.isPreview = false
      this.toFilterCoordinators()
      this.error = ''
      if (this.$refs.emailForm) this.$refs.emailForm.reset()

      this.$nextTick().then(() => {
        this.email = {
          clientId,
          from: this.userName,
          files: [],
          is_kps_send: 0,
          reply_to_email: this.replyToEmailList[0],
          tos: this.getTosList,
          message: `Hi {nickname},<p><br /></p>Thanks! -${this.userData.nickname}`,
          member_legislator: this.legislators[0].member_legislators[0]
        }
      })
    },
    toFilterCoordinators() {
      this.filteredCoordinators = this.coordinators
          .filter(coordinator => coordinator.email)
          .filter(coordinator => !coordinator.silence)
      this.selectedMembers.push(...this.filteredCoordinators)
    },
    toggleOnScheduleMessagePage(clientId, scheduledMessage) {
      this.isModalOpen = true
      this.time = null
      this.filteredCoordinators = this.coordinators.filter(coordinator => coordinator.email)
      this.isSendNow = true
      this.isPreview = false
      this.error = ''
      this.hasBadEmails = scheduledMessage.members.some(member => this.isBad(member.email))
      this.attachedFiles = scheduledMessage.files ? scheduledMessage.files : []

      if (this.$refs.emailForm) this.$refs.emailForm.reset()

      this.$nextTick().then(() => {
        let deliveryAt = moment(scheduledMessage.delivery_at, 'YYYY-MM-DD HH:mm:ss')
        this.date = deliveryAt.format('YYYY-MM-DD')
        this.time = deliveryAt.format('HH:mm')

        this.selectedMembers = scheduledMessage.members

        this.email = {
          id: scheduledMessage.id,
          clientId,
          from: this.userName,
          is_sms: false,
          is_kps_send: 0,
          files: [],
          delivery_at: scheduledMessage.delivery_at,
          send_copies_to_admins: scheduledMessage.is_admin_send,
          subject: scheduledMessage.subject ? scheduledMessage.subject : '',
          members: this.selectedMembers,
          message: scheduledMessage.message ? scheduledMessage.message : `
            Hi {kp_first},<p><br/></p>
            Thanks! -${this.userData.nickname}
          `
        }
      })
    },
    async inputEmail() {
      this.$nextTick(async () => {
        const newContent = await this.parsUrl(this.email.message)

        if (newContent !== this.email.message) {

          this.email.message = newContent
          this.disabledEditor = false
          document.activeElement.blur()
        }
      })
    },
    async onPreviewMessage() {
      if (this.email.message) {
        this.isBusy = true
        this.$emit('changeIsBusy', true)
        this.formattedMessage = ''
        const message = {
          legislator_id: this.legislatorId,
          member_id: this.selectedMembers[0].id,
          message: this.email.message,
        }

          this.formattedMessage = (await this.getAppFormattedMessage(message)).data
          this.isBusy = false
          this.$emit('changeIsBusy', false)
        }
      },
      async removeAttachedScheduledFile(file, index) {
        try {
          await this.deleteScheduledFile(file.id)
          this.attachedFiles.splice(index, 1)
        }
        catch (e) {
          this.onCallToastr('error', 'File hasn`t been deleted', 'Error!')
          console.error(e)
        }
      },
      getArrayOfMembersIds() {
        return this.selectedMembers.filter(member => member.email)
          .map(member => member.id)
      },
      removeAttachedFile (index) {
        this.email.files.splice(index, 1)
        this.currFiles.splice(index, 1)
      },
      removeAllAttachedFiles() {
        this.currFiles = []
        this.email.files = []
        this.isFilesInput = false
      },
      filesInputChanged () {
        this.currFiles = [
          ...this.currFiles,
          ...this.email.files
        ]
        this.currFiles = [...new Set(this.currFiles)]
        this.email.files = [...new Set(this.currFiles)]
      },
      closeModal() {
        this.removeAllAttachedFiles()
        this.$emit('close')
      },
      async sendMessage() {
        if (this.scheduledMessage && this.scheduledMessage.id) {

        if (this.email.files.length) {
          this.isUploadingFiles = true
          let promises = []
          for (let file of this.email.files) {
            let data = new FormData()
            data.append('file', file)
            promises.push(this.saveScheduleMessageFile(data))
          }

          this.email.files = (await Promise.all(promises)).map(res => {
            return res.data
          })
        }

        if (this.attachedFiles.length) this.email.files.push(...this.attachedFiles)

        await this.sendClientScheduleMessage(this.scheduledMessage.id, this.email)
      } else {
        await this.addFiles()
        await this.saveClientScheduleMessage(this.email)
      }
    },
    async onSend() {
      if (!this.$refs.emailForm.validate()) return
      this.isBusy = true
      this.$emit('changeIsBusy', true)
      this.email.is_admin_send = this.isSendCopiesToAdmins ? +this.isSendCopiesToAdmins : 0
      this.email.members = this.getArrayOfMembersIds()
      this.email.is_sms = 0

      try {
        await this.sendMessage()
        this.isBusy = false
        this.onCallToastr('success', 'Email has been sent.', 'Success!')
        this.$emit('changeIsBusy', false)
        this.$emit('update')
        this.$emit('close')
      } catch (err) {
        this.isBusy = false
        this.isUploadingFiles = false
        this.error = 'Something went wrong!'
      }
    },
    async addFiles() {
      if (this.email.files.length) {
        let promises = []

        for (let file of this.email.files) {
          let data = new FormData()
          data.append('file', file)
          promises.push(this.saveScheduleMessageFile(data))
        }

        this.email.files = (await Promise.all(promises)).map(res => {
          return res.data
        })
      }
    },
    async onSendSchedule() {
      if (!this.$refs.emailForm.validate()) return

      this.$emit('changeIsBusy', true)
      this.isUploadingFiles = true
      this.email.delivery_at = `${this.date} ${this.time}`
      this.email.is_sms = false
      this.email.is_admin_send = this.isSendCopiesToAdmins ? +this.isSendCopiesToAdmins : 0
      this.email.members = this.getArrayOfMembersIds()
      this.email.to_regional_coordinators = true
      this.isBusy = true

      await this.addFiles()

      if (this.attachedFiles.length) this.email.files.push(...this.attachedFiles)

      this.saveClientScheduleMessage(this.email)
          .then(() => {
            this.isBusy = false
            this.$emit('changeIsBusy', false)
            this.onCallToastr('success', 'Email has been scheduled.', 'Success!')
            this.closeModal()
            this.isUploadingFiles = false
          })
          .catch(() => {
            this.isBusy = false
            this.$emit('changeIsBusy', false)
            this.isUploadingFiles = false
            this.error = 'Something went wrong!'
          })
    },
    onOpenTestMessageModal() {
      this.$refs.TestMessageModal.open()
    },
  },
  watch: {
    selectedMembers: function (val) {
      this.email.tos = val.map(member => member.email)
      this.$emit('changeCount', this.selectedMembers.length)
    },
    time: function () {
      this.$emit('getTime', this.time)
    },
    date: function () {
      this.$emit('getDate', this.date)
    },
    'email.message': function () {
      this.$emit('getMessage', this.email.message)
    },
  },
  mounted() {
    if (this.scheduledMessage) {
      this.toggleOnScheduleMessagePage(this.clientId, this.scheduledMessage)
    } else {
      this.toggle(this.clientId)
    }
  }
}
</script>

<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-layout row justify-center>
    <v-dialog v-model="dialog" modal persistent scrollable :fullscreen="isMobile">
      <div class="fill-width">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title class="mr-4">{{ pageTitle }}</v-toolbar-title>
            <div v-if="formalAddress && (isDesktop || isDevice)"><span> ({{ formalAddress && formalAddress.address }}</span>
              <a class="district_modal_legislator_name" @click="$refs.legislatorDetailModal.toggle(districtLegislator)"> {{ formalAddress.fullname }}</a>)
            </div>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="districtModalEnabled = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <div v-if="formalAddress && isMobile" class="text-center"><span> ({{ formalAddress && formalAddress.address }}</span>
              <a @click="$refs.legislatorDetailModal.toggle(districtLegislator)"> {{ formalAddress.fullname }}</a>)
            </div>
            <v-alert v-if="isKP && formalAddress" type="info" :dense="isMobile" primary outlined >
                If meeting in person with {{ formalAddress && formalAddress.address }} soon, consider requesting donations from this list of consituents that
                either live or work in the district. Donations will help gain access and are always appreciated by the legislator. Remove any people from the Recipient list that
                you wish and you may edit the sample text before sending.
                </v-alert>
            <v-layout wrap :class="{'pa-2': isDesktop || isDevice, 'pt-2': isMobile}">
              <v-flex class="d-flex align-center flex-wrap">
                <export-members
                  v-if="isSuperAdmin || isVIP"
                  :params="exportProps"
                  :block="false"
                  :district="selectedDistrict"
                  :disabled="!!filteredMemberList.length"
                  :client="selectedClient"
                ></export-members>
                <v-menu offset-y max-width="350px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      :class="isMobile ? 'mt-2' : 'ml-2'"
                      small
                      :block="isMobile"
                      :disabled="!filteredMemberList.length"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Send Each a Message
                      <v-icon
                        right
                        dark
                      >
                        mail_outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                        link
                        three-line
                    >
                      <v-list-item-content @click="$refs.blankEmailModal.toggle('blank', null)">
                        <v-list-item-title>Blank Message</v-list-item-title>
                        <v-list-item-subtitle>Admins only - send any message to district constituents</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="districtLegislator && districtLegislator.id"
                        v-for="(message, index) in messageTemplates"
                        :key="index"
                        link
                        three-line
                    >
                      <v-list-item-content @click="$refs.blankEmailModal.toggle('', message)">
                        <v-list-item-title v-html="message.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="message.description"></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn color="primary" :class="isMobile ? 'mt-3' : 'ml-2'" :block="isMobile" small @click="onUpdateDistricts" :loading="isUpdating"
                       v-if="isSuperAdmin && selectedClientId">
                  Update Districts
                  <v-icon dark class="ml-2">update</v-icon>
                </v-btn>
              </v-flex>
              <v-flex>
                <v-text-field append-icon="search" label="Search" single-line clearable hide-details class="search-input" v-model="search"></v-text-field>
              </v-flex>
              <div class="d-flex align-end justify-end md1" v-if="isSuperAdmin || isVIP || isCoordinator">
                <div class="legislators_filter__additional--filter" :class="{ 'w-100': isMobile }">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    left
                    offset-y
                    min-width="300"
                    max-width="300"
                  >
                    <template v-slot:activator="{ on }" v-if="(isVIP || isSuperAdmin)">
                      <v-btn small rounded :outlined="isDesktop || isDevice" color="primary" v-on="on">
                        <span>Filters</span>
                        <v-icon>filter_list</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-text class="members_filter-overflow">
                        <div class="legislators_filter__additional--item_wrap">
                          <h4>Filters:</h4>
                        </div>
                        <div class="mb-4">
                          <h4>KP preference</h4>
                        </div>
                        <div v-for="item in kpPreference">
                          <v-checkbox
                            color="primary"
                            hide-details
                            class="ma-0"
                            :label="item.title"
                            :value="kpPreferenceResults.indexOf(item.value) >= 0"
                            @change="editKpPreferenceResults($event, item.value)"
                          ></v-checkbox>
                        </div>
                        <br />
                        <div
                          v-if="filter.customFields && filter.customFields.length"
                          v-for="(item, index) in filter.customFields"
                          :key="index"
                          class="legislators_filter__additional--item_wrap"
                        >
                          <div v-if="item.type == 'text'" class="mb-4">
                            <h4>{{ item.name }}</h4>
                            <v-text-field v-model="item.value" label="Search" hide-details></v-text-field>
                          </div>
                          <div v-if="item.type == 'checkbox' && item.metadata && item.metadata.length">
                            <h4>{{ item.name }}</h4>
                            <v-radio-group
                              v-for="(data, j) in item.metadata"
                              v-model="data.type"
                              :label="data.name"
                              :key="j"
                            >
                              <v-radio
                                color="primary"
                                v-for="(label, key) in checkboxLabels"
                                :key="key"
                                :label="label.name"
                                :value="label.value"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                          <div v-if="item.type == 'toggle'">
                            <h4>{{ item.name }}</h4>
                            <v-checkbox
                              color="primary"
                              :label="item.name"
                              class="mt-0"
                              v-model="item.value"
                            ></v-checkbox>
                          </div>
                          <div v-if="item.type == 'dropdown' && item.metadata && item.metadata.length">
                            <h4 class="my-2">{{ item.name }}</h4>
                            <v-checkbox
                              color="primary"
                              v-for="(data, j) in item.metadata"
                              :key="j"
                              :label="data.name"
                              class="mt-0"
                              v-model="data.checked"
                            >
                            </v-checkbox>
                          </div>
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="error" text @click="clearFilter">Clear</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text color="secondary" @click="menu = false">Cancel</v-btn>
                        <v-btn color="primary" text @click="doFilter">Apply</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </div>
              </div>
            </v-layout>

            <div class="district_lookup_filters_list pt-3" v-if="checkSelectedFilters">
              <div
                v-for="(filter, index) in checkSelectedFilters"
                class="district_lookup_filters_list_item"
                :key="index"
              >
                <v-chip
                  v-if="filter.type == 'checkbox'"
                  v-for="(data, i) in filter.value"
                  :key="i"
                  class="ma-2"
                  small
                  close
                  color="primary"
                  outlined
                  @click:close="removeFilterItem(filter, data)"
                >
                  {{ data }}
                </v-chip>
                <v-chip
                  v-if="filter.type =='text' || filter.type == 'toggle'"
                  class="ma-2"
                  small
                  close
                  color="primary"
                  outlined
                  @click:close="removeFilterItem(filter)"
                >
                  <span v-if="filter.type == 'text'">{{ filter.value }}</span>
                  <span v-else>{{ filter.name }}</span>
                </v-chip>
                <v-chip
                  v-if="filter.type == 'dropdown'"
                  v-for="(data, j) in filter.value"
                  :key="j"
                  class="ma-2"
                  small
                  close
                  color="primary"
                  outlined
                  @click:close="removeFilterItem(filter, data)"
                >
                  {{ data }}
                </v-chip>
              </div>
            </div>


            <v-data-table
              :loading="isLoading"
              class="elevation-1 table_default__mobile"
              :options.sync="pagination"
              :headers="memberTableHeader"
              :items="filteredMemberList"
              :custom-filter="customFilter"
              :search="search"
              :mobile-breakpoint="0"
            >
              <template v-slot:item.lastname="{ item }">
                <div class="d-flex align-center" :class="{'inactive': !item.active}">
                  <a class="mr-1 no-line-break" @click="openMemberDetailModal(item)" >
                    {{ item.nickname }} {{ item.lastname }}
                  </a>
                  <sup v-if="item.is_kp1">KP1</sup>
                  <v-tooltip bottom v-if="item.kp_preference !== 'unknown'">
                    <template v-slot:activator="{ on }">
                      <v-avatar v-on="on" :color="kpPreferenceColor(item)" size="10"></v-avatar>
                    </template>
                    <span>{{ kpPreferenceTitle(item) }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.political_leaning_id !== null">
                    <template v-slot:activator="{ on }">
                      <v-avatar class="mx-1" v-on="on" :color="politicalLearningsColor(item)" size="10"></v-avatar>
                    </template>
                    <span>{{ politicalLearningsTitle(item) }}</span>
                  </v-tooltip>
                  <span v-if="isKP">
                    {{ item.nickname }} {{ item.lastname }}
                  </span>
                  <v-tooltip bottom v-if="!!item.cell">
                    <template v-slot:activator="{ on }">
                      <a :href="'tel:' + item.cell" v-on="on">
                        <v-icon v-if="item.bad_cell" color="error" size="16" class="mx-1">no_cell</v-icon>
                        <v-icon v-else color="primary" size="16" class="mx-1">phone_iphone</v-icon>
                      </a>
                    </template>
                    <span>{{ item.cell }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="!!item.email">
                    <template v-slot:activator="{ on }">
                      <a :href="'mailto:' + item.email" v-on="on">
                        <v-icon :color="isBad(item.email) ? 'error' : 'primary'" size="16" class="mx-1">email</v-icon>
                      </a>
                    </template>
                    <span>{{ item.email }}</span>
                  </v-tooltip>
                  <div v-if="!!item.notes && (isSuperAdmin || isVIP || isCoordinator)">
                    <v-menu open-on-hover auto bottom offset-y max-width="300">
                      <template v-slot:activator="{ on }">
                        <v-btn icon small text v-on="on">
                          <v-icon size="16" color="primary">note</v-icon>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-text>
                          {{ item.notes }}
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                  <v-icon size="18" color="primary" v-if="item.has_reply && !isKP" class="mx-1">forum</v-icon>
                </div>
              </template>

              <template v-slot:item.eligible="{ item }" v-if="isSuperAdmin || isVIP || isCoordinator">
                <div>
                  <v-icon color="success" v-if="item.eligible">check_circle</v-icon>
                  <v-icon color="error" v-else>cancel</v-icon>
                </div>
              </template>

              <template v-slot:item.home_house_district="{ item }" v-if="isHouseDistrictsRequired">
                <div class="d-flex flex-column">
                  <div v-for="dist in item.home_house_district" :key="dist" :class="{'matched': dist === selectedDistrict}">
                    {{ dist }}
                  </div>
                </div>
                <v-tooltip bottom v-if="item.home_address && (item.home_house_district[0] === null || typeof item.home_house_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ selectedClient.state.state }}</span>
                </v-tooltip>
              </template>

              <template v-slot:item.home_sen_district="{ item }">
                <div class="d-flex flex-column">
                  <div v-for="dist in item.home_sen_district" :key="dist" :class="{'matched': dist === selectedDistrict}">
                    {{ dist }}
                  </div>
                </div>
                <v-tooltip bottom v-if="item.home_address && (item.home_sen_district[0] === null || typeof item.home_sen_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ selectedClient.state.state }}</span>
                </v-tooltip>
              </template>

              <template v-slot:item.work_house_district="{ item }" v-if="isHouseDistrictsRequired">
                <div class="d-flex flex-column">
                  <div v-for="dist in item.work_house_district" :key="dist" :class="{'matched': dist === selectedDistrict}">
                    {{ dist }}
                  </div>
                </div>
                <v-tooltip bottom v-if="item.work_address && (item.work_house_district[0] === null || typeof item.work_house_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ selectedClient.state.state }}</span>
                </v-tooltip>
              </template>

              <template v-slot:item.work_sen_district="{ item }">
                <div class="d-flex flex-column">
                  <div v-for="dist in item.work_sen_district" :key="dist" :class="{'matched': dist === selectedDistrict}">
                    {{ dist }}
                  </div>
                </div>
                <v-tooltip bottom v-if="item.work_address && (item.work_sen_district[0] === null || typeof item.work_sen_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ selectedClient.state.state }}</span>
                </v-tooltip>
              </template>

              <template v-slot:item.work2_house_district="{ item }" v-if="isHouseDistrictsRequired && is2ndWorkDistrictRequired">
                <div class="d-flex flex-column">
                  <div v-for="dist in item.work2_house_district" :key="dist" :class="{'matched': dist === selectedDistrict}">
                    {{ dist }}
                  </div>
                </div>
                <v-tooltip bottom v-if="item.work2_address && (item.work2_house_district[0] === null || typeof item.work2_house_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ selectedClient.state.state }}</span>
                </v-tooltip>
              </template>

              <template v-slot:item.work2_sen_district="{ item }" v-if="is2ndWorkDistrictRequired">
                <div class="d-flex flex-column">
                  <div v-for="dist in item.work2_sen_district" :key="dist" :class="{'matched': dist === selectedDistrict}">
                    {{ dist }}
                  </div>
                </div>
                <v-tooltip bottom v-if="item.work2_address && (item.work2_sen_district[0] === null || typeof item.work2_sen_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ selectedClient.state.state }}</span>
                </v-tooltip>
              </template>

              <template v-slot:item.work3_house_district="{ item }" v-if="isHouseDistrictsRequired && is3rdWorkDistrictRequired">
                <div class="d-flex flex-column">
                  <div v-for="dist in item.work3_house_district" :key="dist" :class="{'matched': dist === selectedDistrict}">
                    {{ dist }}
                  </div>
                </div>
                <v-tooltip bottom v-if="item.work3_address && (item.work3_house_district[0] === null || typeof item.work3_house_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ selectedClient.state.state }}</span>
                </v-tooltip>
              </template>

              <template v-slot:item.work3_sen_district="{ item }" v-if="is3rdWorkDistrictRequired">
                <div class="d-flex flex-column">
                  <div v-for="dist in item.work3_sen_district" :key="dist" :class="{'matched': dist === selectedDistrict}">
                    {{ dist }}
                  </div>
                </div>
                <v-tooltip bottom v-if="item.work3_address && (item.work3_sen_district[0] === null || typeof item.work3_sen_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ selectedClient.state.state }}</span>
                </v-tooltip>
              </template>

              <template v-slot:item.work4_house_district="{ item }" v-if="isHouseDistrictsRequired && is4thWorkDistrictRequired">
                <div class="d-flex flex-column">
                  <div v-for="dist in item.work4_house_district" :key="dist" :class="{'matched': dist === selectedDistrict}">
                    {{ dist }}
                  </div>
                </div>
                <v-tooltip bottom v-if="item.work4_address && (item.work4_house_district[0] === null || typeof item.work4_house_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ selectedClient.state.state }}</span>
                </v-tooltip>
              </template>

              <template v-slot:item.work4_sen_district="{ item }" v-if="is4thWorkDistrictRequired">
                <div class="d-flex flex-column">
                  <div v-for="dist in item.work4_sen_district" :key="dist" :class="{'matched': dist === selectedDistrict}">
                    {{ dist }}
                  </div>
                </div>
                <v-tooltip bottom v-if="item.work4_address && (item.work4_sen_district[0] === null || typeof item.work4_sen_district[0] == 'undefined')">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">cancel</v-icon>
                  </template>
                  <span>Address outside {{ selectedClient.state.state }}</span>
                </v-tooltip>
              </template>

              <template v-slot:no-data>
                <p class="text-xs-center mb-0">No member filtered.</p>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <member-detail-modal ref="memberDetailModal" :selected-client="selectedClient"></member-detail-modal>
        <legislator-detail-modal ref="legislatorDetailModal" :refresh="update"></legislator-detail-modal>
        <blank-email-modal
          ref="blankEmailModal"
          :selectedClient="selectedClient"
          :selectedDistrict="selectedDistrict"
          :districtLegislator="districtLegislator"
          :memberList="eligibleMemberList"
          :formalAddress="formalAddress"
        ></blank-email-modal>
      </div>
    </v-dialog>
  </v-layout>
</template>

<script>
import ClientService from '@/services/ClientService'
import MemberService from '@/services/MemberService'
import DistrictService from '@/services/DistrictService'

import legislatorDetailModal from '@/components/legislators/legislator-detail-modal'
import exportMembers from '@/components/common/export-button'

import CustomFieldsFilter from '@/mixins/custom-fields-filter'
import blankEmailModal from "@/components/district-lookup/blank-email-modal";
import swal from "sweetalert2";
import CustomMessageService from "@/services/CustomMessageService";
import {mapGetters} from "vuex";
import politicalLeaningsBorder from "@/mixins/politicalLeaningsBorder";

export default {
  name: 'DistrictModal',
  mixins: [ClientService, MemberService, DistrictService, CustomFieldsFilter, CustomMessageService, politicalLeaningsBorder],
  components: {
    'member-detail-modal': () => import('@/components/members/member-detail-modal.vue'),
    'legislator-detail-modal': legislatorDetailModal,
    'export-members': exportMembers,
    'blank-email-modal': blankEmailModal
  },
  data () {
    return {
      menu: false,
      dialog: true,
      isLoading: false,
      search: '',
      selectedClientId: null,
      districtLegislator: {},
      politicalLearnings: [],
      messageTemplates: [],
      isUpdating: false,
      clientList: [],
      districtList: [],
      memberList: [],
      exportProps: {},
      kpPreferenceResults: [],
      checkSelectedFilters: [],
      filter: {
        search: '',
        customFields: []
      },
      pagination: {
        itemsPerPage: -1,
      },
      checkboxLabels: [
        { value: 'default', name: 'All' },
        { value: 1, name: 'Yes' },
        { value: 0, name: 'No' },
      ]
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    selectedDistrict(){
      return this.selectedModalDistrict;
    },
    pageTitle () {
      if (!this.selectedDistrict || !this.selectedClient) return 'District Lookup'
      return `District ${this.selectedDistrict} `
    },
    isHouseDistrictsRequired () {
      return this.selectedClient && this.selectedClient.state && this.selectedClient.state.house_districts
    },
    is2ndWorkDistrictRequired () {
      return this.selectedClient && this.selectedClient.uses_2nd_work
    },
    is3rdWorkDistrictRequired () {
      return this.selectedClient && this.selectedClient.uses_3rd_work
    },
    is4thWorkDistrictRequired () {
      return this.selectedClient && this.selectedClient.uses_4th_work
    },
    memberTableHeader () {
      let tableHeader = [
        { text: 'Member', value: 'lastname', align: 'left' },
        { text: 'KP Eligible?', value: 'eligible', align: 'center' },
        { text: 'HHD', value: 'home_house_district', align: 'center' },
        { text: 'HSD', value: 'home_sen_district', align: 'center' },
        { text: 'WHD', value: 'work_house_district', align: 'center' },
        { text: 'WSD', value: 'work_sen_district', align: 'center' },
        { text: 'W2HD', value: 'work2_house_district', align: 'center' },
        { text: 'W2SD', value: 'work2_sen_district', align: 'center' },
        { text: 'W3HD', value: 'work3_house_district', align: 'center' },
        { text: 'W3SD', value: 'work3_sen_district', align: 'center' },
        { text: 'W4HD', value: 'work4_house_district', align: 'center' },
        { text: 'W4SD', value: 'work4_sen_district', align: 'center' },
      ]
      let hiddenColumns = []
      if (this.isKP) {
        hiddenColumns.push('KP Eligible?')
      }
      if (!this.isHouseDistrictsRequired) {
        hiddenColumns.push('HHD', 'WHD', 'W2HD', 'W3HD', 'W4HD')
      }
      if (!this.is2ndWorkDistrictRequired) {
        hiddenColumns.push('W2HD', 'W2SD')
      }
      if (!this.is3rdWorkDistrictRequired) {
        hiddenColumns.push('W3HD', 'W3SD')
      }
      if (!this.is4thWorkDistrictRequired) {
        hiddenColumns.push('W4HD', 'W4SD')
      }
      tableHeader = tableHeader.filter(header => !hiddenColumns.includes(header.text))
      return tableHeader
    },
    filteredMemberList () {
      return this.memberList.filter(member => member.active)
    },
    eligibleMemberList () {
      return this.filteredMemberList.filter(member => !!member.eligible)
    },
    selectedClient() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    formalAddress () {
      if (!this.districtLegislator.fullname) return null
      let formalAddress = ''
      if (this.selectedDistrict[0] === 'H') {
        formalAddress = `${this.selectedClient.formal_house} `
      } else if (this.selectedDistrict[0] === 'S') {
        formalAddress = `${this.selectedClient.formal_senate} `
      }
      formalAddress = {
        address: formalAddress,
        fullname: this.districtLegislator.fullname
      }
      return formalAddress
    },
  },
  async mounted () {
    await this.refreshDistrictList()
    await this.refreshMemberList()
    this.politicalLearnings = (await this.getPoliticalLeanings()).data
    this.loadCustomFields(this.selectedClient.id)
  },
  methods: {
    update() {
      this.dialog = false
      this.$parent.update()
    },
    async refreshDistrictList () {
      if (!this.selectedClient) return
      this.isLoading = true
      this.memberList = []
      this.isLoading = false
      this.districtList = (await this.getClientDistricts(this.selectedClient.id)).data
        .filter(district => /^(H|S)\d+\w$/.test(district.district))
    },
    async refreshMemberList() {
      if (!this.selectedClient || !this.selectedDistrict) return
      this.isLoading = true
      this.exportProps = {
        custom_fields: this.collectCustomFieldsFilter(),
      }
      const res = (await this.searchMembersByDistrict({
        client_id: this.selectedClient.id,
        district: this.selectedDistrict,
        custom_fields: this.collectCustomFieldsFilter(),
        kp_preference: this.kpPreferenceResults
      })).data
      this.districtLegislator = { ...res.district_legislator, district: {} } || {}
      this.districtLegislator.district.district = this.selectedDistrict
      this.memberList = res.member_list
      await this.getMessageTemplates()
      this.isLoading = false
      this.checkSelectedFilters = this.getCheckSelectedFilters()
    },
    getMessageTemplates() {
      const params = {dlu: 'true'}
      this.getCustomMessages(this.selectedClient.id, params)
          .then((response) => {
            let messages = response.data
            if (this.districtLegislator && this.districtLegislator.id) {
              messages = messages.map(message => {
                let legSalutation = '';
                if (this.selectedDistrict[0] === 'H') {
                  legSalutation = this.selectedClient.formal_house + ' ' + this.districtLegislator.nickname + ' ' + this.districtLegislator.lastname;
                }
                if (this.selectedDistrict[0] === 'S') {
                  legSalutation = this.selectedClient.formal_senate + ' ' + this.districtLegislator.nickname + ' ' + this.districtLegislator.lastname;
                }

                //Setting the value of email variable here.
                //Setting the value of the name variable
                message.name = message.name.replace(/{leg_fullname}/g, `${this.districtLegislator.nickname} ${this.districtLegislator.lastname}`)
                //Setting the value of the subject variable
                message.subject = message.subject.replace(/{leg_first}/g, `${this.districtLegislator.nickname}`)
                    .replace(/{leg_last}/g, `${this.districtLegislator.lastname}`)
                    .replace(/{client_abbreviation}/g, `${this.selectedClient.assoc_abbrev}`)
                //Setting the value of the description variable
                message.description = message.description
                    .replace(/{leg_fullname}/g, `${this.districtLegislator.nickname} ${this.districtLegislator.lastname}`)
                    .replace(/{leg_salutation}/g, legSalutation)
                return message
              })
            }

            this.messageTemplates = messages
          })
          .catch((err) => {
            this.error = err && err.response && err.response.data && err.response.data.message
          })
    },
    customFilter(value, search, item) {
      search = search.toString().toLowerCase()
      return item.nickname.toLowerCase().includes(search) || item.lastname.toLowerCase().includes(search)
    },
    openMemberDetailModal(item) {
      this.$refs.memberDetailModal.toggle(item)
    },
    doFilter() {
      this.menu = false
      this.refreshMemberList()
    },
    clearFilter() {
      this.kpPreferenceResults = []
      this.loadCustomFields(this.selectedClient.id).then(() => {
        this.doFilter()
      })
    },
    editKpPreferenceResults(event, value) {
      if (event) {
        this.kpPreferenceResults.push(value)
      } else {
        const index = this.kpPreferenceResults.indexOf(value)
        if (index >= 0) {
          this.kpPreferenceResults.splice(index,1)
        }
      }
    },
    removeFilterItem (item, key = null) {
      this.filter.customFields.forEach(field => {
        if (field.id == item.id) {
          if (item.type == 'checkbox' || item.type == 'dropdown') {
            field.metadata.forEach( data => {
              if (data.name == key) data.checked = false
            })
          } else if (item.type == 'text') {
            field.value = ''
          } else {
            field.value = false
          }
        }
      })
      this.doFilter()
    },
    getCheckSelectedFilters () {
      let selectedFilters = []

      this.filter.customFields.forEach(item => {
        if (item.type == 'text' || item.type == 'toggle')
          if (item.value)
            selectedFilters.push({ id: item.id, name: item.name, type: item.type, value: item.value })
        if (item.type == 'checkbox'  || item.type == 'dropdown') {
          let value = item.metadata.filter(meta => meta.checked).map(meta => meta.name)
          if (value && value.length)
            selectedFilters.push({ id: item.id, type: item.type, name: item.name, value: value })
        }
      })

      return selectedFilters
    },
    onUpdateDistricts() {
      swal({
        title: 'Are you sure?',
        html: `
          This action will overwrite all of the districts for all of members from this district that have a valid address.
          For blank addresses, or invalid addresses for which you have already manually set districts, no changes will be made.
          This is useful after an initial import, or if your state has recently redistricted.<br />
          <b>It cannot be undone.</b>
        `,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, proceed',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.isUpdating = true
          const payload = {
            members: this.memberList
          }
          this.updateMembersDistricts(this.selectedClientId, payload)
            .then(() => {
              this.isUpdating = false
              swal({
                title: 'Updating Districts in progress',
                type: 'info',
                html: `You've started update of districts. Task will run in the background`,
                confirmButtonColor: '#3085d6',
              })
              this.$modals.get('notifications').openAndLoad()
            })
            .catch((err) => {
              this.isUpdating = false
              this.handleError(err)
            })
        }
      })
    },
  },
  watch: {
    search: function() {
      this.exportProps.search = this.search
    }
  }
}
</script>

<template>
  <v-dialog max-width="500" v-model="isModalOpen">
    <v-card>
      <v-progress-linear
        style="z-index: 6"
        color="warning"
        indeterminate
        rounded
        height="3"
        fixed
        top
        v-if="isLoading"
      ></v-progress-linear>
      <v-card-title>
        <span class="title">Add Donation</span>
        <v-spacer />
        <v-icon @click="isModalOpen = false">close</v-icon>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <div class="d-flex justify-end">
          <v-btn
            color="primary"
            class="my-1"
            small
            dark
            @click="$refs.addDonationModal.open()"
          >
            add
          </v-btn>
        </div>
        <v-data-table
          class="elevation-1"
          :items="donations"
          :headers="donationsHeader"
        >
          <template v-slot:item.action="{ item }">
            <div class="d-flex flex-nowrap align-center">
              <v-btn icon class="mx-0" small >
                <v-icon
                  size="18"
                  color="primary"
                  @click="$refs.addDonationModal.open(item)"
                >
                  far fa-edit
                </v-icon>
              </v-btn>
              <v-btn
                icon
                class="mx-0"
                v-if="isVIP || isCoordinator"
              >
                <v-icon
                  size="18"
                  color="error"
                  @click="removeDonation(item)"
                >
                  far fa-trash-alt
                </v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:item.comment="{ item }">
            <span>{{ item.comment }}</span>
          </template>

          <template v-slot:item.amount="{ item }">
            <div class="d-flex flex-wrap justify-center full-width">
              <span>${{ formattedPrice(item.amount) }}</span>
              <h6 class="full-width">{{ item.type }}</h6>
            </div>
          </template>


          <template v-slot:item.donated_at="{ item }">
            <span>{{ getFormattedDate(item.donated_at, 'MM/DD/YYYY') }}</span>
          </template>

        </v-data-table>
      </v-card-text>
      <add-donation-modal
        ref="addDonationModal"
        :sessionTask="task"
        :legislator="legislator"
        :memberId="member.id"
        @refresh="refresh"
      ></add-donation-modal>
    </v-card>
  </v-dialog>
</template>

<script>

import LegislatorService from "@/services/LegislatorService";
import addDonationModal from "@/components/kp-tasks/add-kp-donation-modal";
import swal from "sweetalert2";

export default {
  name: "DonationModal",
  mixins: [LegislatorService],
  components: {
    'add-donation-modal' : addDonationModal
  },
  props: {
    client: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      legislator: {},
      member: {},
      task:{},
      donations: []
    }
  },
  computed: {
    donationsHeader() {
      return [
        {text: '', value: 'action', align: 'left', sortable: false, width: '75px'},
        {text: 'Note', value: 'comment', align: 'left'},
        {text: 'Donation', value: 'amount', align: 'center', sortable: false},
        {text: 'Date', value: 'donated_at', align: 'center'},
      ]
    }
  },
  methods: {
    open(legislator, member, task) {
      this.task = task
      this.legislator = legislator
      this.member = member
      this.isModalOpen = true
      this.getDonationsList()
    },
    async getDonationsList() {
      this.isLoading = true
      try {
        const params = {
          currentSession: 1,
          taskId: this.task.id,
          kpId: this.member.id
        }
        this.donations = (await this.getLegislatorDonationsList(this.client.id, this.legislator.id, params)).data.data
      } catch (e) {
        console.error(e)
      }
      this.isLoading = false
    },
    refresh() {
      this.close()
      this.$emit('refresh')
    },
    formattedPrice(amount) {
      amount = Number.prototype.toFixed.call(parseFloat(amount) || 0, 2)
      return amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
    },
    async removeDonation(donation) {
      let result = await swal({
        title: 'Are you sure you want to delete this donation?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete',
        reverseButtons: true,
      })
      if(result.value) {
        try {
          await this.removeLegislatorDonation(this.client.id, this.legislator.id, donation.id)
          this.onCallToastr('success', 'Donation has been deleted.', 'Success!')
          await this.getDonationsList()
          this.refresh()
        }
        catch (e) {
          this.error = 'Something went wrong!'
          console.error(e)
        }
      }
    },
    close() {
      this.isModalOpen = false
    }
  }
}
</script>

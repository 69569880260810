<template>
  <v-card class="elevation-0">
    <v-card-text class="pa-0">
      <v-tabs
        grow
        v-model="tab"
        show-arrows
      >
        <v-tab class="d-flex flex-wrap">
          <div class="full-width">
            <v-icon :size="isMobile? 18 : 24">fas fa-clipboard-list</v-icon>
          </div>
          <h6>Tasks</h6>
        </v-tab>
        <v-tab class="d-flex flex-wrap">
          <div class="full-width">
            <v-icon :size="isMobile? 18 : 24">fas fa-edit</v-icon>
          </div>
          <h6>Notes</h6>
        </v-tab>
        <v-tab class="d-flex flex-wrap">
          <div class="full-width">
            <v-icon :size="isMobile? 18 : 24">fas fa-dollar-sign</v-icon>
          </div>
          <h6>Donations</h6>
        </v-tab>
        <v-tab class="d-flex flex-wrap">
          <div class="full-width">
            <v-icon :size="isMobile? 18 : 24">far fa-address-card</v-icon>
          </div>
          <h6 >Bio</h6>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat class="mt-4">
            <v-card-text>
              <v-alert type="info" dense outlined class="pa-0">
                This is your task list for {{ legislator.nickname }}. Please complete the tasks and save as you progress.
              </v-alert>
              <v-flex
                v-if="typeOfTasks.length"
                v-for="(task, index) in typeOfTasks"
                :key="index * 3"
                xs12 sm12 md12 lg12 xl12
              >
                <v-checkbox
                  v-if="task.task_type.data_type === 'Boolean'"
                  type="checkbox"
                  color="success"
                  :label="getTaskName(task)"
                  :input-value="task.result ? task.result.result === 'YES' : false"
                  @change="saveTaskResult($event, task)"
                  :hint="task.tooltip"
                  persistent-hint
                  class="my-kps-task-checkbox"
                >
                  <template v-slot:label>
                    <span>{{ getTaskName(task) }}</span>
                    <span :class="getDeadlineColor(task)" class="pl-1 text-caption" v-if="task.deadline">
                      (Due by: {{ formatDate(task.deadline, 'MMM D, YYYY') }})
                    </span>
                  </template>
                </v-checkbox>
                <v-text-field
                  v-if="task.task_type.data_type === 'String'"
                  :label="getTaskName(task)"
                  class="task-input mt-4"
                  color="success"
                  :value="task.result ? task.result.result : ''"
                  @input="setResult($event, task)"
                  :hint="task.tooltip"
                  persistent-hint
                  outlined
                >
                  <template v-slot:label>
                    <span>{{ getTaskName(task) }}</span>
                    <span class="pl-1 text-caption" :class="getDeadlineColor(task)" v-if="task.deadline">
                      (Due by: {{ formatDate(task.deadline, 'MMM D, YYYY') }})
                    </span>
                  </template>
                </v-text-field>
                <v-select
                  v-if="task.task_type.data_type === 'Select'"
                  :label="getTaskName(task)"
                  class="task-input mt-4"
                  color="success"
                  :items="task.task_type.options"
                  :value="task.result ? task.result.result : '' "
                  @change="setResult($event, task)"
                  :hint="task.tooltip"
                  persistent-hint
                  solo
                  clearable
                >
                  <template v-slot:label>
                    <span>{{ getTaskName(task) }}</span>
                    <span class="pl-1 text-caption" :class="getDeadlineColor(task)" v-if="task.deadline">
                      (Due by: {{ formatDate(task.deadline, 'MMM D, YYYY') }})
                    </span>
                  </template>
                </v-select>
              </v-flex>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="mt-4">
            <v-card-text>
            <v-alert type="info" dense outlined class="pa-0">
              Use this area to record pertinent notes about your recent progress or conversations with this
              legislator.
              </v-alert>
              <v-flex
                v-if="legislator.tasks.length > 0 && task.task_type.data_type === 'Notes'"
                xs12
                v-for="(task, index) in legislator.tasks"
                :key="index * 2"
              >
                <v-textarea
                  label="Add Note"
                  class="task-input"
                  :value="note"
                  @input="setNoteResult($event, task)"
                  outlined
                  :rows="isMobile? 5 : 3"
                >
                </v-textarea>
                <v-data-table
                  :headers="notesTableHeader"
                  :items="isMemberTasks? getNotesList() : getNotesListForKpTasks()"
                  disable-sort
                >
                  <template v-slot:item.created_at="{ item }">
                    <div>{{ item.created_at ? getFormatDate(item.created_at) : '' }}</div>
                  </template>
                  <template v-slot:item.result="{ item }">
                    <div v-if="item.result.length < 170">{{item.result}}</div>
                    <div v-else>
                      <span v-if="!readMoreActivated">{{item.result.slice(0, 170)}}</span>
                      <a v-if="!readMoreActivated" @click="activateReadMore">
                        Read more...
                      </a>
                      <span v-if="readMoreActivated"> {{item.result}}</span>
                      <a v-if="readMoreActivated" @click="deactivateReadMore">
                        Read less...
                      </a>
                    </div>
                  </template>
                  <template v-if="isSuperAdmin || isCoordinator || isVIP" v-slot:item.action="{ item }">
                    <v-btn
                      :disabled="isLoading"
                      icon
                      class="mx-0"
                      small
                      @click="onEditTaskResult(item)"
                    >
                      <v-icon size="18" color="primary">edit</v-icon>
                    </v-btn>
                    <v-btn
                      :disabled="isLoading"
                      icon
                      class="mx-0"
                      @click="onDeleteTaskResult(item.id)"
                    >
                      <v-icon size="18" color="pink">delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
                <v-tooltip top max-width="300px">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      slot="activator"
                      class="mr-2 mt-3 icon_absolute"
                      v-on="on"
                      color="primary"
                      dark
                    >help
                    </v-icon>
                  </template>
                  <span>Only notes about current time period such as "spoke to legislator on phone and we have their support" or "cannot get him to call me back". Keep these notes updated when things change.</span>
                </v-tooltip>
              </v-flex>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="mt-4">
            <v-card-text>
            <v-alert type="info" dense outlined class="pa-0">
                Donations: Please report any donations that either you give personally (KP1 $) or that you may either bring with you or help procure from other constituents (Other $).
              </v-alert>
              <div class="d-flex justify-end">
                <v-btn
                  color="primary"
                  class="my-1"
                  small
                  dark
                  @click="$refs.addDonationModal.open()"
                >
                  add
                </v-btn>
              </div>
              
              <v-data-table
                class="elevation-1"
                :items="donations"
                :headers="donationsHeader"
                :mobile-breakpoint="0"
              >
                <template v-slot:item.amount="{ item }">
                  <div class="d-flex flex-wrap justify-center full-width">
                    <span>${{ formattedPrice(item.amount) }}</span>
                  </div>
                </template>

                <template v-slot:item.type="{ item }">
                  <span>{{ item.type }}</span>
                </template>

                <template v-slot:item.comment="{ item }">
                  <div v-if="isMobile">
                    <v-tooltip bottom v-if="!!item.comment" max-width="450">
                      <template v-slot:activator="{ on }">
                        <v-icon color="primary" v-on="on">note</v-icon>
                      </template>
                      <span>{{ item.comment }}</span>
                    </v-tooltip>
                  </div>
                  <div v-else>
                    <span>{{ item.comment }}</span>
                  </div>
                </template>

                <template v-slot:item.action="{ item }">
                  <div class="d-flex flex-nowrap align-center">
                    <v-btn icon class="mx-0" small >
                      <v-icon
                        size="18"
                        color="primary"
                        @click="$refs.addDonationModal.open(item)"
                      >
                        far fa-edit
                      </v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      class="mx-0"
                      v-if="isVIP || isCoordinator"
                    >
                      <v-icon
                        size="18"
                        color="error"
                        @click="removeDonation(item)"
                      >
                        far fa-trash-alt
                      </v-icon>
                    </v-btn>
                  </div>
                </template>

              </v-data-table>
            </v-card-text>
            <v-card-actions class="d-flex" v-if="showDonateButton || isSuperAdmin || isVIP">
              <v-flex class="pr-1 justify-center" xs12>
                <v-btn
                  type="button"
                  color="primary"
                  :block="isMobile"
                  large
                  class="white--text"
                  @click="openDonations(legislator.district.district)"
                >
                  Request Donations for {{ legislator.nickname }}
                  <v-icon
                    right
                    dark
                  >
                    paid
                  </v-icon>
                </v-btn>
              </v-flex>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="pa-2">
            <v-card-text>
          <v-alert type="info" dense outlined class="pa-0">
                Biography & Contact Info: The more you know about a legislator, the better. Consider following them on social media, signing up for their newsletter, and engaging them frequently with short emails, texts, or social media posts that tag them.
              </v-alert>
            <v-flex xs12>
              <div class="my-4">
                Bio: {{ legislator.occupation }}
              </div>
              <div
                  v-if="legislator.leg_data && legislator.leg_data.leg_notes"
                  class="my-4"
              >
                Notes: {{ legislator.leg_data.leg_notes }}
              </div>
            </v-flex>
            <v-divider class="my-4"></v-divider>
            Pertinent Links:
            <v-flex class="d-flex justify-space-between">
              <v-btn v-if="legislator.facebook_url" :href="legislator.facebook_url" small icon target="_blank">
                <i class="fab fa-facebook-square fa-2x" style="color: #1976D2" ></i>
              </v-btn>
              <v-btn v-if="legislator.twitter_url" :href="legislator.twitter_url" small icon target="_blank">
                <i class="fab fa-twitter-square fa-2x" style="color: #1976D2" ></i>
              </v-btn>
              <v-btn v-if="legislator.linkedin_url" :href="legislator.linkedin_url" small icon target="_blank">
                <i class="fab fa-linkedin-square fa-2x" style="color: #512DA8" ></i>
              </v-btn>
              <v-btn v-if="legislator.website_url" :href="legislator.website_url" small icon target="_blank">
                <v-icon color="darken-2">
                  fa-university
                </v-icon>
              </v-btn>
              <v-btn v-if="legislator.ballotpedia_url" :href="legislator.campaign_url" small icon
                     target="_blank">
                <i class="fas fa-globe-europe fa-2x" style="color: #1976D2" ></i>
              </v-btn>
              <v-btn v-if="legislator.ballotpedia_url" :href="legislator.ballotpedia_url" small icon
                     target="_blank">
                <h2 class="blue--text">B</h2>
                <h2 class="orange--text">P</h2>
              </v-btn>
              <v-btn v-if="legislator.instagram_url" :href="legislator.instagram_url" small icon
                     target="_blank">
                <i class="fab fa-instagram-square fa-2x" style="color: #C2185B" ></i>
              </v-btn>
              <v-btn v-if="legislator.youtube_url" :href="legislator.youtube_url" small icon target="_blank">
                <i class="fab fa-youtube-square fa-2x" style="color: #D32F2F" ></i>
              </v-btn>
            </v-flex>
            <v-divider class="my-4"></v-divider>
            Contact Info:
            <div class="detail-container mt-4">
              <div class="detail-modal_info-item" v-if="legislator.official_phone">
                <h4>Capitol Phone:</h4>
                <div><a @click="onRequestCall(legislator.official_phone)">{{ legislator.official_phone }}</a></div>
              </div>
              <div class="detail-modal_info-item" v-if="legislator.local_phone">
                <h4>Local phone:</h4>
                <div><a @click="onRequestCall(legislator.local_phone)">{{ legislator.local_phone }}</a></div>
              </div>
              <div class="detail-modal_info-item" v-if="legislator.cell_phone">
                <h4>Cell:</h4>
                <div><a @click="onRequestCall(legislator.cell_phone)">{{ legislator.cell_phone }}</a></div>
              </div>
              <div class="detail-modal_info-item" v-if="legislator.home_phone">
                <h4>Home:</h4>
                <div><a @click="onRequestCall(legislator.home_phone)">{{ legislator.home_phone }}</a></div>
              </div>
              <div class="detail-modal_info-item" v-if="legislator.work_phone">
                <h4>Work:</h4>
                <div><a @click="onRequestCall(legislator.work_phone)">{{ legislator.work_phone }}</a></div>
              </div>
              <div class="detail-modal_info-item" v-if="legislator.official_email">
                <h4><v-icon>mail_outline</v-icon>:</h4>
                <div><a @click="onSendEmail(legislator)">{{ legislator.official_email }}</a></div>
              </div>
            </div>
              <v-divider class="my-4"></v-divider>

              <div>
                <v-checkbox
                  color="primary"
                  :label="`Receive News Alerts for ${legislator.salutation}`"
                  :input-value="!!(legislator.subscribe_new && legislator.subscribe_new.id)"
                  @change="onSubscribe"
                ></v-checkbox>
              </div>
              <v-expansion-panels focusable>
                <v-expansion-panel>
                  <v-expansion-panel-header :class="isMobile? 'row ma-0' : ''" disable-icon-rotate :hide-actions="isMobile">
                    <div class="mobile-width leg-salutation">
                      {{legislator.salutation}} News Articles:
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-data-table
                      :loading="isLoading"
                      class="elevation-1 pa-2 mt-4"
                      :headers="legislatorNewsHeader"
                      :items="news.items"
                      :server-items-length="news.pagination.totalItems"
                      :options.sync="news.pagination"
                      :footer-props="{ 'items-per-page-options': [2, 5, 10] }"
                      @update:options="getLegislatorNewsList"
                    >
                      <template v-slot:item.title="{ item }">
                        <a :href="item.link" target="_blank">{{ item.title }}</a>
                      </template>

                      <template v-slot:item.published_at="{ item }">
                        <div>{{ item.published_at | dateTimeFormatter }}</div>
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <district-modal v-if="districtModalEnabled"></district-modal>
    <add-donation-modal
      ref="addDonationModal"
      :tasks="tasksForDonations"
      :legislator="legislator"
      :memberId="member.id"
      @refresh="refresh"
    ></add-donation-modal>
    <blank-email-modal
      ref="blankEmailModal"
      :selectedClient="selectedClient"
      :selectedDistrict="legislator.district.district"
      :districtLegislator="legislator"
      :memberList="memberList"
      :formalAddress="formalAddress"
    ></blank-email-modal>
  </v-card>
</template>

<script>
import DistrictService from '@/services/DistrictService';
import MemberService from '@/services/MemberService'
import LegislatorService from '@/services/LegislatorService'

import blankEmailModal from "@/components/district-lookup/blank-email-modal";
import districtModal from '@/components/common/district-modal'
import ClientService from "@/services/ClientService";
import addDonationModal from "@/components/my-tasks/add-donation-modal";
import {mapGetters} from "vuex";
import _ from 'lodash'
import swal from "sweetalert2";
import TaskService from "@/services/TaskService";
import CustomMessageService from "@/services/CustomMessageService";

export default {
  name: 'MyTasksTag',
  mixins: [DistrictService, MemberService, LegislatorService, ClientService, TaskService, CustomMessageService],
  components: {
    'district-modal': districtModal,
    'blank-email-modal': blankEmailModal,
    'add-donation-modal' : addDonationModal
  },
  props: {
    legislator: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    member: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isMemberTasks: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      tab: null,
      eligibleMemberList: [],
      memberList: [],
      notesList: [],
      readMoreActivated: false,
      note: '',
      test: '',
      isLoading: false,
      showDonateButton: false,
      donations: [],
      news: {
        pagination: {
          page: 1,
          itemsPerPage: 2,
          totalItems: 0,
          current_page: 0,
          per_page: 0,
          last_page: 0,
          sortBy: [],
          sortDesc: [],
        },
        items: []
      },
    }
  },
  computed: {
    ...mapGetters('app', [
      'superAdminSelectedClient'
    ]),
    tasksForDonations() {
      return this.typeOfTasks.filter(task => task.task_type.data_type === 'Currency')
    },
    donationsHeader() {
      return [
        {text: 'Amount', value: 'amount', align: 'center', sortable: false},
        {text: 'Type', value: 'type', align: 'center'},
        {text: 'Note', value: 'comment', align: 'left'},
        {text: 'Action', value: 'action', align: 'left', sortable: false, width: '75px'},
      ]
    },
    notesTableHeader() {
      return [
        { text: 'Date', value: 'created_at', align: 'left' },
        { text: 'Note', value: 'result', align: 'left' },
        { text: '', value: 'action', align: 'center', width: '100px'},
      ]
    },
    legislatorNewsHeader() {
      return [
        { text: 'Title', value: 'title', sortable: false},
        { text: 'Published At', value: 'published_at', sortable: false, width: 150},
      ]
    },
    selectedClient() {
      if(this.isSuperAdmin) {
        return this.superAdminSelectedClient
      }
      return this.userClient
    },
    formalAddress () {
      let formalAddress = ''
      if (this.legislator.district.district[0] === 'H') {
        formalAddress = `${this.selectedClient.formal_house} `
      } else if (this.legislator.district.district[0] === 'S') {
        formalAddress = `${this.selectedClient.formal_senate} `
      }
      formalAddress = {
        address: formalAddress,
        fullname: this.legislator.fullname
      }
      return formalAddress
    },
    typeOfTasks() {
      if (this.isMemberTasks) {
        return this.member.sessionTasks
      } else {
        return this.legislator.tasks
      }
    }
  },
  methods: {
    async getDonationsList() {
      try {
        const memberId = this.member && this.member.id ? this.member.id : this.userMemberId
        const params = {
          currentSession: 1,
          kpId: memberId
        }
        this.donations = (await this.getLegislatorDonationsList(this.selectedClient.id, this.legislator.id, params)).data.data
      }
      catch (e) {
        console.error(e)
      }
    },
    getLegislatorNewsList() {
      this.isLoading = true
      let params = {
        page: this.news.pagination.page,
        limit: this.news.pagination.itemsPerPage,
        search: this.legislator.fullname,
      }
      return this.getLegislatorNew(this.selectedClient.id, params).then((response) => {
        this.news.items = response.data.data ? response.data.data : response.data
        this.news.pagination.totalItems = response.data.total ? response.data.total : response.data.length
        this.news.pagination.current_page = response.data.current_page
        this.news.pagination.per_page = response.data.per_page
        this.news.pagination.last_page = response.data.last_page
        this.isLoading = false
      }, () => {
        this.isLoading = false
      })
    },
    onSubscribe() {
      const payload = {
        'legislator_id': this.legislator.id
      }
      const memberId = this.member && this.member.id ? this.member.id : this.userMemberId
      this.subscribeToLegislatorNews(memberId, payload)
        .then(() => {
          this.onCallToastr('success', `Subscribed to News Alerts for ${this.legislator.salutation}`, 'Success!')
        }).catch((err) => {
        this.handleError(err)
      })
    },
    onEditTaskResult(item) {
      this.note = item.result
      this.handleFormChange()
      this.updatingTaskResult(item.id)
    },
    formattedPrice(amount) {
      amount = Number.prototype.toFixed.call(parseFloat(amount) || 0, 2)
      return amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
    },
    onDeleteTaskResult(TaskResultId) {
      swal({
        title: 'Are you sure about that?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteTaskResult(TaskResultId)
            .then(() => {
              this.onCallToastr('success', 'Task Result has been deleted.', 'Success!')
              this.refresh()
            })
            .catch(() => {
              this.onCallToastr('error', 'Something went wrong.', 'Error!')
            })
        }
      })
    },
    getMessageTemplates() {
      const param  = {dlu: true}
      this.getCustomMessages(this.selectedClient.id, param)
          .then((response) => {
            let messages = response.data
            messages.map(message => {
              if (message.name === 'Sample Donation Request') {
                this.showDonateButton = true
              }
            })
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    allMembers() {
      const payload = {
        client_id: this.selectedClient.id,
        district: this.legislator.district.district,
      }
      this.searchMembersByDistrict(payload)
        .then((response) => {
          const members = response.data.member_list

          this.memberList = members
            .filter(member => member.active && !!member.eligible)
        })
        .catch(() => {
          this.onCallToastr('error', 'Something went wrong, please try again later', 'Error!')
        })
    },
    openDonations(district) {
      if (this.isKP) {
        const messageTitle = 'Sample Donation Request'
        const messageType = 'donation'
        this.$refs.blankEmailModal.toggle(messageType, messageTitle)
      } else {
        this.openDistrictModal(district)
      }
    },
    onRequestCall(phone) {
      window.location.href = `tel:${phone}`
    },
    onSendEmail(legislator) {
      window.location.href = `mailto:${legislator.official_email}`
    },
    setResult($event, task) {
      task.result = {
        task_id: task.id,
        result: $event
      }
      this.handleFormChange()
    },
    setNoteResult($event, task) {
      this.note = $event
      task.result = {
        task_id: task.id,
        result: $event
      }
      this.handleNoteChange(task)
    },
    clearNoteField() {
      this.note = ''
    },
    saveTaskResult($event, task) {
      if (task.result && task.result.id) {
        task.result.result = $event ? 'YES' : 'NO'
      } else {
        task.result = {
          task_id: task.id,
          result: $event ? 'YES' : 'NO'
        }
      }
      this.handleFormChange()
    },
    getTaskName(task) {
      let name = task.name
      if (name === 'KP1 Donations') {
        name = 'My donations to this legislator'
      }
      if (name === 'Other Donations') {
        name = 'Other non-PAC donations'
      }
      return name
    },
    getDeadlineColor(task) {
      const remainingDays = moment.duration(moment(task.deadline).diff(moment())).asDays()
      if (remainingDays > 28) return 'success--text'
      else if (remainingDays > 7) return 'warning--text'
      return 'error--text'
    },
    updatingTaskResult(TaskResultId)
    {
      this.$emit('updatingTaskResult',TaskResultId)
    },
    refresh() {
      this.getDonationsList()
      this.$emit('refresh')
    },
    handleFormChange() {
      this.$emit('handleFormChange')
    },
    handleNoteChange(task) {
      this.$emit('handleNoteChange',  task )
    },
    getNotesList() {
      let tasksIdWithNotes = this.member.sessionTasks
        .filter(task => task.name === 'Notes')
        .map(item => item.id)

      return this.member.task_results.filter((item) => {
        return tasksIdWithNotes.some(id => id===item.task_id)
      }).sort(function (a, b) {
        if (a.created_at < b.created_at) {
          return 1;
        }
        if (a.created_at > b.created_at) {
          return -1;
        }
        return 0;
      })
    },
    getNotesListForKpTasks() {

      let tasksWithNotes = _.cloneDeep(this.legislator).member_legislators[0].session_tasks.filter(item => item.name === 'Notes')
      return tasksWithNotes[0].task_results.filter(item => item.legislator_id === this.legislator.id).sort(function (a, b) {
        if (a.created_at < b.created_at) {
          return 1;
        }
        if (a.created_at > b.created_at) {
          return -1;
        }
        return 0;
      })
    },
    getFormatDate(date) {
     return this.getFormattedDate(date, 'MM/DD/YYYY')
    },
    activateReadMore() {
      this.readMoreActivated = true;
    },
    deactivateReadMore() {
      this.readMoreActivated = false;
    },
    async removeDonation(donation) {
      let result = await swal({
        title: 'Are you sure you want to delete this donation?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete',
        reverseButtons: true,
      })
      if(result.value) {
        try {
          await this.removeLegislatorDonation(this.selectedClient.id, this.legislator.id, donation.id)
          this.onCallToastr('success', 'Donation has been deleted.', 'Success!')
          await this.getDonationsList()
          this.refresh()
        }
        catch (e) {
          this.error = 'Something went wrong!'
          console.error(e)
        }
      }
    }
  },
  mounted() {
    this.getMessageTemplates()
    this.allMembers()
    this.getLegislatorNewsList()
    this.getDonationsList()
  }
}
</script>

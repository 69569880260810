import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import store from '@/store'
import token from '@/utils/storage/token'

let url = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : 'http://kp-dashboard.local';
axios.defaults.baseURL = url + '/api/'
Vue.prototype.$baseURL = url

axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 401) {
    if(error.response.data.message == "Unauthenticated.") {
      token.remove()
      store.commit('setUser', false)
      router.push({name: "login"}).catch(err => {})
    }
  }

  return Promise.reject(error)
})

let auth = token.get()
if (auth) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth
}

<template>
  <v-dialog persistent max-width="900" v-model="isModalOpen">
    <v-card :loading="isBusy">
      <v-icon class="d-flex ml-auto pt-2 pr-4" @click.native="closeModal()"
              @click="$refs.sendEmail.removeAllAttachedFiles()">close
      </v-icon>
      <v-card-title>
        <span class="title">Sending {{ isEmail ? 'Email' : 'Text' }} to {{ kpCount }} Advocate(s)</span>
          <v-chip
            x-small
            class="ma-2"
            color="primary"
            label
            href="https://help.kpdashboard.com/en/articles/8867317-sending-messages"
            target="_blank"
           >
            Message/Variable Help
              <v-icon right x-small>
                mdi-open-in-new
              </v-icon>
          </v-chip>
        <v-spacer></v-spacer>
        <div class="mr-2" v-if="isEmail">
          <v-menu offset-y max-width="350px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="warning"
                  class="ml-2"
                  small
                  :disabled="messageTemplates.length < 1"
                  v-bind="attrs"
                  v-on="on"
              >
                Message Templates
                <v-icon
                    right
                    dark
                >
                  mail_outline
                </v-icon>
              </v-btn>
            </template>
            <v-list>

              <v-list-item
                  v-for="(message, index) in messageTemplates"
                  :key="index"
                  link
                  three-line
              >
                <v-list-item-content @click="uploadMessageTemplate(message)">
                  <v-list-item-title v-html="message.name"></v-list-item-title>
                  <v-list-item-subtitle v-html="message.description"></v-list-item-subtitle>

                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-spacer></v-spacer>
        <message-send-as @changeMessageType="(value) => isEmail = value" :disabled="disabledSwitchSend" class="mr-2" :messageLength="finalMessageLength"/>
      </v-card-title>
      <v-card-text>
        <v-alert v-if="error" dense type="error" outlined :value="true">{{ error }}</v-alert>
        <v-alert v-if="!isEmail && members.length && badCellOrEmails.length" class="mt-2" dense type="error" outlined :value="true">
          Advocates with a red icon have an undeliverable phone/email and will not receive this message.
        </v-alert>
        <v-alert v-if="isEmail && members.length && badEmails.length" class="mt-2" dense type="error" outlined :value="true">
          Advocates with a red icon have an undeliverable email and will not receive this message.
        </v-alert>
        <v-flex xs12 class="px-2 mb-4" v-if="filter.customFields && filter.customFields.length">
          <advocates-filter
            :client="selectedClient"
            :legislators="legislators"
            :committeeList="committeeList"
            @refreshAdvocates="refreshAdvocates"
            :filter="filter"
            :clientRegions="clientRegions"
          ></advocates-filter>
        </v-flex>
        <v-flex xs12 class="px-2 my-4">
          <v-expansion-panels v-model="isKpsOpen">
            <v-expansion-panel>
              <v-expansion-panel-header>Recipients ({{ members.length }})</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-select
                  outlined
                  dense
                  v-model="members"
                  :items="members"
                  label="Selected Advocate(s)"
                  item-text="fullname"
                  return-object
                  multiple
                  :item-value="'id'"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip
                      :style="kpPreferenceBorder(item)"
                      :key="item.id"
                      @click:close="() => members.splice(index, 1)"
                      close
                    >
                      <v-icon left size="16" :color="isBad(item.email) ? 'error' : 'primary'">
                        email
                      </v-icon>
                      <v-icon
                        v-if="item.bad_cell || item.cell === null"
                        left
                        size="16"
                        color="error"
                      >
                        no_cell
                      </v-icon>
                      <v-icon
                        v-else
                        left
                        size="16"
                        color="primary"
                      >
                        phone_iphone
                      </v-icon>
                      {{ item.fullname }}
                    </v-chip>
                  </template>
                </v-select>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>
        <div v-show="!isEmail">
          <send-text
            ref="sendText"
            :setMessage="message"
            :text="text"
            :members="members"
            :isEmail="isEmail"
            :client="selectedClient"
            :files="mmsFiles"
            @getMessage="setMessage"
            @badVariables="onBadVariables"
            @deleteFile="deleteMMSFile"
            @saveFiles="saveMmsFiles"
            @error="childError"
            @maxFileSize="onChangeMaxFileSize"
            @onAttachedFiles="onAttachedFiles"
            @updateImgSize="imgSize => this.updatedImgSize = imgSize"
            @updateFilesSize="fileSize => this.updatedFilesSize = fileSize"
            @checkingText="changeCheckingText"
            @finalMessageLength="value => setFinalMessageLength(value)"
          ></send-text>
        </div>
        <div v-show="isEmail">
          <send-email
            ref="sendEmail"
            :setMessage="message"
            :email="email"
            :legislatorList="legislatorList"
            :members="members"
            :client="selectedClient"
            :isEmail="isEmail"
            :attachedFiles="attachedFiles"
            :isUploadingFiles="isUploadingFiles"
            @getMessage="setMessage"
            @badVariables="onBadVariables"
            @error="childError"
            @maxFileSize="onChangeMaxFileSize"
            @onAttachedFiles="onAttachedFiles"
            @updateImgSize="imgSize => this.updatedImgSize = imgSize"
            @updateFilesSize="fileSize => this.updatedFilesSize = fileSize"
            @finalMessageLength="value => setFinalMessageLength(value)"
          ></send-email>
          <v-alert
            v-if="isSummaryUploadLimit"
            class="mt-3"
            dense type="error"
            outlined
            :value="true"
          >
            The size of images and attached files is more than 25MB
          </v-alert>

        </div>
        <div v-if="!isSendNow" class="mt-2 px-2 full-width">
          <div class="caption grey--text pb-3">Schedule</div>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="6"
            >
              <v-menu
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="newdate"
                    label="Select date"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  :min="currentDate"
                  color="primary"
                  no-title
                  scrollable
                  @change="$refs.dateMenu.save(date)"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
            >
              <v-menu
                ref="timeMenu"
                v-model="timeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                :disabled="!date"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="getFormattedTime"
                    label="Select time"
                    prepend-icon="mdi-clock-outline"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    :clearable="text.id == null"
                    :disabled="!date"
                    hide-details
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="timeMenu"
                  v-model="time"
                  :min="currentDate == date ? currentTime : ''"
                  color="primary"
                  full-width
                  ampm-in-title
                  @click:minute="$refs.timeMenu.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <v-flex>
          <v-checkbox
            color="primary"
            v-model="isSendNow"
            label="Send Now"
            hide-details
            class="mt-3"
          >
          </v-checkbox>
        </v-flex>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="secondary"
          @click.native="closeModal"
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="isSendNow"
          color="primary"
          :loading="isBusy || checkingText"
          :disabled="disabledSendButton || isSummaryUploadLimit || checkingText || isBadVariables"
          @click.native="onSend"
        >
          Send
        </v-btn>
        <v-btn
          v-else
          color="primary"
          :loading="isBusy || checkingText"
          :disabled="(!time && !date && disabledSendButton) || checkingText || isBadVariables"
          @click.native="onSendSchedule"
        >
          Save & Schedule
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import sendEmail from '@/components/members/member-message-modal/send-email'
import sendText from '@/components/members/member-message-modal/send-text'
import MessageSendAs from "@/components/global/message-send-as"
import filter from "@/components/kp-tasks/send-message-modal/advocates-filter"
import swal from "sweetalert2";
import TinyConfig from "@/mixins/tiny-mce-config"
import ClientService from "@/services/ClientService";
import {mapGetters} from "vuex";
import CustomFieldsFilter from "@/mixins/custom-fields-filter";
import _ from "lodash";
import he from "he";
import VipChip from "@/components/legislators/vip-setting/vip-chip";
import CustomMessageService from "@/services/CustomMessageService";
import ShorterUrl from "@/mixins/shorterUrl";


export default {
  name: "MemberMessageModal",
  mixins: [ClientService, CustomFieldsFilter, TinyConfig, CustomMessageService, ShorterUrl],
  components: {
    'advocates-filter': filter,
    'send-email': sendEmail,
    'send-text': sendText,
    'vip-chip': VipChip,
    MessageSendAs,
  },
  props: {
    parentFilter: {
      type: Object,
      default: function () {
        return {
          search: '',
          showInactive: null,
          showInvalid: null,
          districtNotSet: null,
          activeKP1: null,
          kpPreferenceResults: [],
          customFields: [],
          legislators: [],
          exceptLegislators: [],
        }
      }
    },
    committeeList: {
      type: Array,
      default: function () {
        return []
      }
    },
    legislators: {
      type: Array,
      default: function () {
        return []
      }
    },
    clientRegions: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      searchInputFull: '',
      displaySelected: {},
      filteredCommittees: [],
      selected_committees: [],
      committeeTypeList: [
        {id: 0, title: 'House'},
        {id: 1, title: 'Senate'},
      ],
      displayExceptSelected: [],
      disabledSwitchSend: false,
      sendCopiesToAdmins: false,
      isUploadingFiles: false,
      isFilterOpen: false,
      checkingText: false,
      isModalOpen: false,
      maxSizeFile: false,
      isSendNow: true,
      dateMenu: false,
      timeMenu: false,
      date: null,
      time: null,
      isKpsOpen: false,
      isBadVariables: false,
      isEmail: true,
      isBusy: false,
      message: '',
      messageTemplates: [],
      legislatorList: [],
      attachedFiles: [],
      mmsFiles: [],
      members: [],
      error: '',
      finalMessageLength: 0,
      email: {
        finalMessageLength: 0,
      },
      checkboxLabels: [
        {value: 'default', name: 'All'},
        {value: 1, name: 'Yes'},
        {value: 0, name: 'No'},
      ],
      filter: {},
      text: {
        finalMessageLength: 0,
      },
    }
  },
  computed: {
    ...mapGetters('app', [
      'superAdminSelectedClient'
    ]),
    newdate: {
      get() {
        if (this.date == null || this.date == '') {
          return ''
        } else {
          return moment.utc(this.date).format('MMM D, YYYY')
        }
      },
      set(value) {
        if (value == null) {
          this.data = ''
        } else {
          this.data = moment.utc(value).format('MMM D, YYYY')
        }
      }
    },
    selectedClient() {
      if (this.isSuperAdmin) {
        return this.superAdminSelectedClient
      }
      return this.userClient
    },
    kpCount() {
      return this.members ? this.members.length : 0
    },
    disabledSendButton() {
      const checkEmail = !this.message || this.maxSizeFile
      const checkText = !this.message || this.text.finalMessageLength > 320
      return (this.isEmail && checkEmail) ||
        (!this.isEmail && checkText) ||
        (this.members && !this.members.length) ||
        (this.maxFileSize || this.isSummaryUploadLimit)
    },
    badCellOrEmails() {
      return this.members.filter(member => (!member.cell || member.bad_cell) || this.isBad(member.email))
    },
    badEmails() {
      return this.members.filter(member => this.isBad(member.email))
    },
    isSummaryUploadLimit() {
      return (this.updatedImgSize + this.updatedFilesSize) > this.imgSizeLimit
    },
    sortLegislatorsList() {
      const house = this.legislators.filter(legislator => legislator.district.district[0] === 'H')
      const senate = this.legislators.filter(legislator => legislator.district.district[0] === 'S')
      return [
        ...this.checkAndSortArray(house, 'House'),
        ...this.checkAndSortArray(senate, 'Senate')
      ]
    },
    currentDate() {
      return new Date().toISOString().substr(0, 10)
    },
    currentTime() {
      const timezone = this.getTimezone(this.userClient);
      return moment().tz(timezone).add(1, 'hour').format('H:m');
    },
    getFormattedTime() {
      return this.time ? moment(this.time, "hh:mm").format('h:mm a') : ''
    },
    selectedCommitteeList() {
      let selectedCommitteeList = []

      if (this.filter.isDistricts) {
        selectedCommitteeList = [...this.committeeList
          .filter(committee => committee.type === 0 && (this.selectedClient.house_committees || [])
            .includes(committee.id))
        ]
      }
      if (!this.filter.isDistricts) {
        selectedCommitteeList = [
          ...selectedCommitteeList,
          ...this.committeeList
            .filter(committee => committee.type === 1 && (this.selectedClient.senate_committees || [])
              .includes(committee.id))
        ]
      }
      const committeeTypeList = ['H', 'S']

      selectedCommitteeList.forEach((committee) => {
        committee.label = `${committee.fullname} (${committeeTypeList[committee.type]})`
        committee.isSubCommittee = false
      })

      return selectedCommitteeList
    },
  },
  methods: {
    async toggle() {
      this.mmsFiles = []
      this.isModalOpen = true
      this.isBusy = true
      this.isSendNow = true
      this.isBadVariables = false
      this.message = ''
      this.text = {
        clientId: this.selectedClient.id,
        is_sms: true,
        is_kps_send: 0,
        formattedMessage: '',
        finalMessageLength: 0,
        from_members_page: true,
        members: []
      }
      this.email = {
        from: this.selectedClient.assoc_abbrev,
        clientId: this.selectedClient.id,
        reply_to_email: '',
        is_kps_send: 0,
        subject: '',
        is_sms: false,
        formattedMessage: '',
        from_members_page: true,
        files: [],
        members: [],
      }

      this.error = ''
      await this.getMessageTemplates()
      await this.loadLegislators()
      await this.loadCustomFields(this.selectedClient.id)
      this.filter = _.cloneDeep(this.parentFilter)
      await this.refreshMembers()
      this.isBusy = false
    },
    uploadMessageTemplate(message) {
      this.message = message.body
    },
    getMessageTemplates() {
      const params = {advocate: 'true'}
      this.getCustomMessages(this.selectedClient.id, params)
          .then((response) => {
            this.messageTemplates = response.data
          })
          .catch((err) => {
            this.error = err.response.data.message
          })
    },
    onBadVariables(value) {
      this.isBadVariables = value
    },
    setMessage(message) {
      this.message = message
    },
    fillLegislator() {
      this.filter.legislators = !this.filter.isSpecificLegislatorsOnly ? this.legislators : [];
    },
    setFilterLegislators() {
      if (this.filter.isDistricts !== null && this.filter.isDistricts) {
        this.filter.legislators = this.legislators.filter(legislator => legislator.district.district[0] === 'H')
      } else if (this.filter.isDistricts !== null && !this.filter.isDistricts) {
        this.filter.legislators = this.legislators.filter(legislator => legislator.district.district[0] === 'S')
      }
    },
    changeCheckingText(value) {
      this.checkingText = value
    },
    saveMmsFiles(file) {
      this.mmsFiles.push(file)
    },
    deleteMMSFile(id) {
      const indexOfObject = this.mmsFiles.findIndex(file => file.id === id)
      this.mmsFiles.splice(indexOfObject, 1);
    },
    onSelectCommittee(committee, sub = false) {
      if (sub) {
        if (this.filter.selectedSubCommitteeIdList.includes(committee.id)) {
          this.filter.selectedSubCommitteeIdList = this.onSelectCommitteeIdList(this.filter.selectedSubCommitteeIdList, committee.id)
        } else {
          this.filter.selectedSubCommitteeIdList.push(committee.id)
          this.filter.selectedCommitteeIdList = this.onSelectCommitteeIdList(this.filter.selectedCommitteeIdList, committee.id)
        }
      } else {
        if (this.filter.selectedCommitteeIdList.includes(committee.id)) {
          this.filter.selectedCommitteeIdList = this.onSelectCommitteeIdList(this.filter.selectedCommitteeIdList, committee.id)
        } else {
          this.filter.selectedCommitteeIdList.push(committee.id)
        }
      }
      this.onFilterLegislators()
    },
    onFilterLegislators() {
      this.loading = true
      this.filter.legislators = this.legislators.filter((legislator) => {
        if (this.filter.selectedCommitteeIdList.length === 0 && this.filter.selectedSubCommitteeIdList.length === 0) {
          if (this.filter.isDistricts) {
            return legislator.district.district[0] === 'H'
          } else if (!this.filter.isDistricts) {
            return legislator.district.district[0] === 'S'
          }
        }

        if (!legislator.legislator_committees || legislator.legislator_committees.length < 1) return false

        const subCommitteeIds = legislator.legislator_committees
          .filter(legislatorCommittee => legislatorCommittee.membership_states.some(membershipState => membershipState.pivot.subcommittee === 1))
          .map(legislatorCommittee => legislatorCommittee.committee_id)

        const subFilter = this.filter.selectedSubCommitteeIdList.find(sub => subCommitteeIds.includes(sub))
        if (subFilter) return true

        const committeeId = legislator.legislator_committees
          .filter(legislatorCommittee => legislatorCommittee.membership_states.some(membershipState => membershipState.pivot.subcommittee === 0))
          .map(legislatorCommittee => legislatorCommittee.committee_id)

        const mainFilter = this.filter.selectedCommitteeIdList.find(committee => committeeId.includes((committee)))
        if (mainFilter) return true

        if (subCommitteeIds.length === 0 && committeeId.length === 0) return false
      })
      this.loading = false
    },
    onSelectCommitteeIdList(arr, id) {
      return arr.filter(_committeeId => _committeeId !== id)
    },
    checkAndSortArray(array, name) {
      if (array.length) {
        array.sort((a, b) => a.lastname > b.lastname ? 1 : -1)
        array.push({divider: true})
        array.unshift({header: name})
      }
      return array
    },
    getIndex(id, array) {
      return array.findIndex(item => item.id === id)
    },
    removeExceptLegislator(index, array) {
      array.splice(index, 1)
    },
    removeLegislator(index, array, item) {
      swal({
        title: `Remove ${item.fullname}?`,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#4caf50',
        cancelButtonColor: '#9E9E9E',
        confirmButtonText: '&#10003 Remove',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.filter.exceptLegislators.push(item)
          array.splice(index, 1)
        }
      })
    },
    refreshAdvocates(filter) {
      this.filter = filter
      this.refreshMembers()
    },
    refreshMembers() {
      this.isBusy = true
      let params = {
        unlimit: true,
        search: this.filter.search,
        showInactive: this.filter.showInactive ? this.filter.showInactive : null,
        showInvalid: this.filter.showInvalid ? this.filter.showInvalid : null,
        districtNotSet: this.filter.districtNotSet ? this.filter.districtNotSet : null,
        activeKP1: this.filter.activeKP1 ? this.filter.activeKP1 : null,
        is_verified:  this.filter.isVerified ? this.filter.isVerified : null,
        custom_fields: this.collectCustomFieldsFilter(),
        kp_preference: this.filter.kpPreferenceResults,
        legislators: this.filter.legislators.map(legislator => legislator.id),
        reduceLegislators: this.filter.exceptLegislators.map(legislator => legislator.id),
        region: this.filter.region ? this.filter.region : null,
      }
      this.getClientMembersWithPagination(this.selectedClient.id, params)
        .then((response) => {
          const members = response.data.data
          this.members = this.addLegislatorByDistricts(members)
          this.isBusy = false
        })
        .catch((err) => {
          this.error = err
        })
    },
    loadLegislators() {
      this.isBusy = true
      this.getClientLegislators(this.selectedClient.id)
        .then((response) => {
          this.legislatorList = response.data
          this.isBusy = false
        })
        .catch((err) => {
          this.error = err.response.data.message
        })
    },
    addLegislatorByDistricts(members) {
      return members.map(member => {
        member.homeHouseLegislator = this.legislatorList.find(legislator => legislator.district.district.includes(member.home_house_district[0]))
        member.homeSenateLegislator = this.legislatorList.find(legislator => legislator.district.district.includes(member.home_sen_district[0]))
        member.workHouseLegislator = this.legislatorList.find(legislator => legislator.district.district.includes(member.work_house_district[0]))
        member.workSenateLegislator = this.legislatorList.find(legislator => legislator.district.district.includes(member.work_sen_district[0]))
        return member
      })
    },
    clearFilter() {
      this.filter.showInactive = null
      this.filter.showInvalid = null
      this.filter.districtNotSet = null
      this.filter.activeKP1 = null
      this.filter.isVerified = null
      this.filter.kpPreferenceResults = []
      this.filter.legislators = []
      this.filter.exceptLegislators = []
      this.loadCustomFields(this.selectedClient.id).then(() => {
        this.refreshMembers()
      })
    },
    editKpPreferenceResults(event, value) {
      if (event) {
        this.filter.kpPreferenceResults.push(value)
      } else {
        const index = this.filter.kpPreferenceResults.indexOf(value)
        if (index >= 0) {
          this.filter.kpPreferenceResults.splice(index, 1)
        }
      }
    },
    onAttachedFiles(value) {
      this.attachedFiles = value
    },
    onChangeMaxFileSize(value) {
      this.maxSizeFile = value
    },
    closeModal() {
      this.isModalOpen = false
      this.disabledSwitchSend = false
      this.isUploadingFiles = false
      this.sendCopiesToAdmins = false
      this.isSendNow = true
      this.filter = []
      this.isBusy = false
      this.email.files = []
      this.currentImgsSize = 0
      this.updatedImgSize = 0
      this.$refs.sendEmail.removeAllAttachedFiles()
    },
    childError() {
      this.error = ''
    },
    async addFiles(entity) {
      if (entity.files && entity.files.length) {
        this.isUploadingFiles = true
        let promises = []
        for (let file of entity.files) {
          let data = new FormData()
          data.append('file', file)
          promises.push(this.saveScheduleMessageFile(data))
        }

        entity.files = (await Promise.all(promises)).map(res => {
          return res.data
        })
      }
    },
    async onSendEmail() {
      this.email.members = this.members
        .filter(member => !this.isBad(member.email))
        .map(member => member.id)
      this.email.message = this.message
      await this.addFiles(this.email)
      await this.saveClientScheduleMessage(this.email)
    },
    html2Text(html) {
      let find = /(<p><br[\/]?><[\/]?p>)/,
        re = new RegExp(find, 'g');

      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    },
    setFinalMessageLength(value) {
      this.finalMessageLength = value
    },
    async onSendText() {
      this.text.files = this.mmsFiles
      this.text.message = this.html2Text(this.message)
      this.text.members = this.members.map(member => member.id)
      await this.saveClientScheduleMessage({...this.text})
    },
    dateTimeFormatter(value) {
      if (!value) return ''
      const utc = moment.utc(value).toDate()
      return moment(utc).local().format('MMM D, YYYY hh:mm A')
    },
    somethingWentWrong() {
      this.isBusy = false
      this.isUploadingFiles = false
      this.error = 'Something went wrong!'
    },
    onSend() {
      swal({
        text: 'Ready to Send?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Send',
        cancelButtonText: 'Continue Editing',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          if (!this.members || !this.members.length) {
            this.somethingWentWrong()
            return
          }

          if (this.isEmail) {
            if (!this.$refs.sendEmail.validationForm) return
            this.isBusy = true
            this.email.is_sms = 0
            this.message = this.removeHighlight(this.message)
            this.email.is_admin_send = this.sendCopiesToAdmins ? 1 : 0

            this.onSendEmail().then(() => {
              this.onCallToastr('success', 'Email has been sent.', 'Success!')
              this.$emit('update')
              this.closeModal()
            }, (err) => {
              console.error(err)
              this.somethingWentWrong()
            })
          } else {
            this.isBusy = true
            this.text.is_sms = 1
            this.message = this.removeHighlight(this.message)
            this.text.is_admin_send = this.sendCopiesToAdmins ? 1 : 0

            this.onSendText().then(() => {
              this.onCallToastr('success', 'Text has been sent.', 'Success!')
              this.$emit('update')
              this.closeModal()
            }, (err) => {
              console.error(err)
              this.somethingWentWrong()
            })
          }
        }
      })
    },
    onSendSchedule() {
      swal({
        text: 'Ready to Send?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Save & Schedule',
        cancelButtonText: 'Continue Editing',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          if (!this.members || !this.members.length) {
            this.somethingWentWrong()
            return
          }
          if (this.isEmail) {
            if (!this.$refs.sendEmail.validationForm) return
            this.isBusy = true
            this.email.is_sms = 0
            this.message = this.removeHighlight(this.message)
            this.email.is_admin_send = this.sendCopiesToAdmins ? 1 : 0
            this.email.delivery_at = `${this.date} ${this.time}`

            this.onSendEmail().then(() => {
              this.onCallToastr('success', `Email has been scheduled.`, 'Success!')
              this.$emit('update')
              this.closeModal()
            }, (err) => {
              this.somethingWentWrong()
              console.error(err)
            })
          } else {
            this.isBusy = true
            this.text.is_sms = 1
            this.message = this.removeHighlight(this.message)
            this.text.is_admin_send = this.sendCopiesToAdmins ? 1 : 0
            this.text.delivery_at = `${this.date} ${this.time}`

            this.onSendText().then(() => {
              this.onCallToastr('success', `Text has been scheduled.`, 'Success!')
              this.$emit('update')
              this.closeModal()
            }, (err) => {
              this.somethingWentWrong()
              console.error(err)
            })
          }
        }
      })
    },
  },
  watch: {
    isEmail: function () {
      this.isBadVariables = false
    },
    isSendNow: function () {
      this.dateMenu = false
      this.timeMenu = false
      this.date = null
      this.time = null
    }
  }
}
</script>

<template>
  <v-card>
    <v-card-title
      class="pt-0"
      :class="!isEmail ? 'pb-0' : 'title_card'"
    >
      <span class="title">Sending {{ isEmail ? 'Email' : 'Text' }} to {{ recipients.length }} recipient(s)</span>
      <v-spacer></v-spacer>
      <message-send-as @changeMessageType="(value) => isEmail = value" :disabled="isLoading" class="mr-2"
      :messageLength="finalMessageLength"/>
      <v-card-subtitle v-if="!isEmail" class="pa-0">
        This will send a text to all recipients with valid cell phone # and an email if they do not.
      </v-card-subtitle>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-alert v-if="!isEmail && badCellOrEmails.length" dense type="error" outlined :value="true">
        Recipients with a red icon have an undeliverable phone/email and will not receive this message.
      </v-alert>
      <v-alert v-if="isEmail && badEmails.length" dense type="error" outlined :value="true">
        Recipients with a red icon have an undeliverable email and will not receive this message.
      </v-alert>
      <div v-show="!isEmail">
        <send-text
          ref="sendText"
          :recipients="recipients"
          :isBusy="isLoading"
          :propMessage="message"
          @badVariables="onBadVariables"
          @setMessage="value => this.message = value"
          @onLoading="onLoading"
          @finalMessageLength="value => setFinalMessageLength(value)"        
          ></send-text>
      </div>
      <div v-show="isEmail">
        <send-email
          ref="sendEmail"
          :recipients="recipients"
          :isBusy="isLoading"
          :propMessage="message"
          :propFrom="from"
          :propSubject="subject"
          :isUploadingFiles="isUploadingFiles"
          @setMessage="value => this.message = value"
          @setSubject="value => this.subject = value"
          @setFrom="value => this.from = value"
          @onAttachedFiles="onAttachedFiles"
          @maxFileSize="onChangeMaxFileSize"
          @badVariables="onBadVariables"
          @onLoading="onLoading"
          @finalMessageLength="value => setFinalMessageLength(value)"
        ></send-email>
      </div>
      <div v-if="!isSendNow" class="mt-2 px-2 full-width">
        <div class="caption grey--text pb-3">Schedule</div>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-menu
              ref="dateMenu"
              v-model="dateMenu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="newdate"
                  label="Select date"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                :min="currentDate"
                color="primary"
                no-title
                scrollable
                @change="$refs.dateMenu.save(date)"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-menu
              ref="timeMenu"
              v-model="timeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
              :disabled="!date"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="getFormattedTime"
                  label="Select time"
                  prepend-icon="mdi-clock-outline"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!date"
                  hide-details
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="timeMenu"
                v-model="time"
                :min="currentDate == date ? currentTime : ''"
                color="primary"
                full-width
                ampm-in-title
                @click:minute="$refs.timeMenu.save(time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <v-flex>
        <v-checkbox
          color="primary"
          v-model="isSendNow"
          label="Send Now"
          hide-details
          class="mt-3"
        >
        </v-checkbox>
      </v-flex>
      <v-alert
        v-if="isSizeUploadLimit"
        class="mt-3"
        dense
        type="error"
        outlined
        :value="true"
      >
        The size of images and attached files is more than 25MB
      </v-alert>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-layout wrap class="justify-space-between">
        <div class="mt-3">
          <v-btn
            outlined
            color="secondary"
            class="mr-2"
            @click.native="closeModal"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="isSendNow"
            color="primary"
            :loading="isLoading"
            :disabled="disabledSendButton || isSizeUploadLimit || isBadVariables"
            @click.native="onSend"
          >
            Send
          </v-btn>
          <v-btn
            v-else
            color="primary"
            :loading="isLoading"
            :disabled="!time && !date && disabledSendButton || isBadVariables"
            @click.native="onSendSchedule"
          >
            Save & Schedule
          </v-btn>
        </div>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import sendEmail from '@/components/command/clients-message-modal/send-email'
import sendText from '@/components/command/clients-message-modal/send-text'
import MessageSendAs from "@/components/global/message-send-as"
import TinyConfig from "@/mixins/tiny-mce-config"

import ClientService from "@/services/ClientService";
import swal from "sweetalert2";
import he from "he";


export default {
  name: 'ClientSendMessageComponent',
  mixins: [TinyConfig, ClientService],
  components: {
    sendEmail, sendText, MessageSendAs,
  },
  props: {
    recipients: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      isEmail: true,
      isLoading: false,
      isSendNow: true,
      dateMenu: false,
      timeMenu: false,
      subject: '',
      from: '',
      date: null,
      time: null,
      message: '',
      isUploadingFiles: false,
      isBadVariables: false,
      finalMessageLength: 0,
      scheduleMessageId: null
    }
  },
  computed: {
    newdate: {
      get() {
        if (this.date == null || this.date == '') {
          return ''
        } else {
          return moment.utc(this.date).format('MMM D, YYYY')
        }
      },
      set(value) {
        if (value == null) {
          this.data = ''
        } else {
          this.data = moment.utc(value).format('MMM D, YYYY')
        }
      }
    },
    disabledSendButton() {
      const checkEmail = !this.message || this.maxSizeFile
      const checkText = !this.message || this.finalMessageLength > 320
      return (this.isEmail && checkEmail) || (!this.isEmail && checkText) || (this.members && !this.members.length)
    },
    currentTime() {
      return moment().format('H:m')
    },
    currentDate() {
      return new Date().toISOString().substr(0, 10)
    },
    getFormattedTime() {
      return this.time ? moment(this.time, "hh:mm").format('h:mm a') : ''
    },
    badCellOrEmails() {
      return this.recipients.filter(recipient => !recipient.cell || recipient.bad_cell)
    },
    badEmails() {
      return this.recipients.filter(recipient => this.isBad(recipient.email))
    },
    isSizeUploadLimit() {
      return (this.updatedImgSize + this.updatedFilesSize) > this.imgSizeLimit
    },
  },
  methods: {
    reset() {
      this.isBadVariables = false
      this.isEmail = true
      this.isSendNow = true
      this.dateMenu = false
      this.timeMenu = false
      this.isUpdateMessage = false
      this.date = null
      this.time = null
      this.message = ''
      this.subject = ''
      this.from = ''
    },
    onAttachedFiles(value) {
      this.attachedFiles = value
    },
    onBadVariables(value) {
      this.isBadVariables = value
    },
    onLoading(value) {
      this.isLoading = value
    },
    somethingWentWrong() {
      this.isLoading = false
      this.isUploadingFiles = false
      this.onCallToastr('error', 'Something went wrong!', 'Error!')
    },
    onChangeMaxFileSize(value) {
      this.maxSizeFile = value
    },
    onSend() {
      swal({
        text: `Ready to Send?`,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Send',
        cancelButtonText: 'Continue Editing',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          if (!this.recipients || !this.recipients.length) {
            this.somethingWentWrong()
            return
          }

          let payload = {
            message: this.message,
            is_kps_send: 0,
            is_admin_send: 0,
            users: _.cloneDeep(this.recipients).filter(recipient => recipient.is_user).map(user => user.id),
            members: _.cloneDeep(this.recipients).filter(recipient => !recipient.is_user).map(member => member.id),
            is_sms: +!this.isEmail,
          }

          if (this.scheduleMessageId) payload.id = this.scheduleMessageId;

          if (this.isEmail) {
            if (!this.$refs.sendEmail.validationForm) return;
            payload.subject = this.subject
            payload.from = this.from
          }

          this.saveClientScheduleMessage(payload)
            .then(() => {
              this.isLoading = false
              this.onCallToastr('success', 'Email has been sent.', 'Success!')
              this.closeModal()
            })
            .catch(() => {
              this.isLoading = false
              this.isUploadingFiles = false
              this.onCallToastr('error', 'Something went wrong!', 'Error!')
            })
        }
      })
    },
    html2Text(html) {
      let find = /(<p><br[\/]?><[\/]?p>)/,
        re = new RegExp(find, 'g');

      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    },
    setFinalMessageLength(value) {
      this.finalMessageLength = value
    },
    onSendSchedule() {
      swal({
        text: `Ready to Save & Schedule?`,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Save & Schedule',
        cancelButtonText: 'Continue Editing',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          if (!this.recipients || !this.recipients.length) {
            this.somethingWentWrong()
            return
          }

          let payload = {
            message: this.message,
            is_kps_send: 0,
            is_admin_send: 0,
            users: _.cloneDeep(this.recipients).filter(recipient => recipient.is_user).map(user => user.id),
            members: _.cloneDeep(this.recipients).filter(recipient => !recipient.is_user).map(member => member.id),
            is_sms: +!this.isEmail,
            delivery_at: !this.isSendNow ? `${this.date} ${this.time}` : null
          }

          if (this.scheduleMessageId) payload.id = this.scheduleMessageId;

          if (this.isEmail) {
            if (!this.$refs.sendEmail.validationForm) return;
            payload.subject = this.subject
            payload.from = this.from
          }

          this.saveClientScheduleMessage(payload)
            .then(() => {
              this.isLoading = false
              this.onCallToastr('success', 'Email has been scheduled.', 'Success!')
              this.closeModal()
            })
            .catch(() => {
              this.isLoading = false
              this.isUploadingFiles = false
              this.onCallToastr('error', 'Something went wrong!', 'Error!')
            })
        }
      })
    },
    closeModal() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style type="text/css" scoped>
.v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.title_card {
  padding-bottom: 22px !important;
}
</style>
<template>
  <v-dialog max-width="500" v-model="dialog" @click:outside="close">
    <v-card class="message-modal-content">
      <v-card-title>
        <span class="title">
          {{ form.id > 0 ? 'Edit district' : 'Create district' }}
          <v-progress-circular
              indeterminate
              color="primary"
              size="20"
              width="2"
              v-if="loading"
          ></v-progress-circular>
        </span>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="py-3">
        <v-text-field
            v-model="form.district"
            :error-messages="errorMessages('district')"
            label="District"
            :disabled="loading"
        >
        </v-text-field>
        <v-text-field
            v-model="form.name"
            :error-messages="errorMessages('name')"
            label="District name"
            :disabled="loading"
        >
        </v-text-field>
        <v-text-field
            v-model="form.ocd_id"
            :error-messages="errorMessages('ocd_id')"
            label="Ocd id"
            :disabled="loading"
        >
        </v-text-field>
        <v-text-field
            v-model="form.ls_id"
            :error-messages="errorMessages('ls_id')"
            label="Legistorm id"
            :disabled="loading"
        >
        </v-text-field>
        <v-text-field
            v-model="form.kml"
            :error-messages="errorMessages('kml')"
            label="KML"
            :disabled="loading"
        >
        </v-text-field>
        <v-checkbox
          color="primary"
          v-model="form.is_federal"
          label="Is Federal?"
          :disabled="loading"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Cancel</v-btn>
        <v-btn color="primary" @click="save" :loading="loading" :disabled="loading || disabled">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DistrictService from "@/services/DistrictService";

export default {
  name: "DistrictsFormModal",
  mixins: [DistrictService],
  props: ['state_id'],
  data() {
    return {
      dialog: false,
      errors: {
        email: []
      },
      loading: false,
      form: {
        id: '',
        name: '',
        is_federal: false,
        district: '',
        state_id: '',
        ocd_id: '',
        ls_id: '',
        kml: ''
      }
    }
  },
  computed: {
    disabled() {
      return !this.form.name && !this.form.district && !this.form.ocd_id
    }
  },
  methods: {
    open(id) {
      if (id > 0) {
        this.getStateDistrict(id)
      }

      if (!this.form.state_id) {
        this.form.state_id = this.state_id
      }

      this.errors = false
      this.dialog = true
    },
    close() {
      this.form = {
        id: '',
        name: '',
        is_federal: 0,
        district: '',
        state_id: '',
        ocd_id: '',
        ls_id: '',
        kml: ''
      }

      this.$emit('update')
      this.dialog = false
    },
    save() {
      this.loading = true

      let data = {
        ...this.form,
        is_federal: this.form.is_federal ? 1 : 0
      }

      this.saveStateDistrict(data).then(()=> {
        this.onCallToastr('success', 'Regions list has been updated.', 'Success!')
        this.close()
        this.loading = false
        this.errors = false
      }, err => {
        this.errors = err.response.data.errors
        this.loading = false
      })
    },
    getStateDistrict(id) {
      this.loading = true
      this.getStateDistrictById(id).then(response => {
        this.form = response.data
        this.loading = false
      })
    },
    errorMessages(field) {
      return (this.errors && this.errors[field] && this.errors[field].length > 0) ? this.errors[field][0] : []
    },
  }
}
</script>
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message_layout",staticStyle:{"height":"100%"}},[(_vm.userClient)?_c('client-settings-modal'):_vm._e(),_c('profile-modal'),_c('app-toolbar',{staticClass:"header"}),(!_vm.isMember && !_vm.isProfileUpdate)?_c('app-sidebar'):_vm._e(),_c(VMain,{attrs:{"app":""}},[_c(VFlex,{staticClass:"d-flex align-center justify-end flex-wrap"},[_c(VBtn,{staticClass:"ml-2",attrs:{"color":"primary","small":"","disabled":!_vm.client},on:{"click":function($event){return _vm.$refs.LargeSendMessage.toggle()}}},[_vm._v(" Send Message ")]),_c('br'),_c('br')],1),_c(VTabs,{attrs:{"fixed-tabs":"","next-icon":"mdi-arrow-right-bold-box-outline","prev-icon":"mdi-arrow-left-bold-box-outline","show-arrows":_vm.isMobile},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.getTabs),function(tab){return _c(VTab,{key:tab.id,on:{"click":function($event){return _vm.toCurrentPage(tab.route)}}},[(tab.name === 'Scheduled')?_c(VBadge,{staticClass:"mt-0",attrs:{"color":"primary","value":_vm.scheduledMessagesCount},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.scheduledMessagesCount > 9 ? '9+' : _vm.scheduledMessagesCount))])]},proxy:true}],null,true)},[_c('span',[_vm._v(_vm._s(tab.name))])]):_c('span',[_vm._v(_vm._s(tab.name))])],1)}),1),_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[_c('router-view',{attrs:{"route":_vm.$route.path},on:{"getScheduledMessagesCount":_vm.getScheduledMessagesCount}})],1)],1),_c('large-send-message',{ref:"LargeSendMessage",attrs:{"client":_vm.client}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
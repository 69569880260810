<template>
  <v-layout column>
    <v-data-table
        v-if="(list.items.length)
        && !(listVacantDistricts
        && listVacantDistricts.length)"
      class="elevation-1 px-xs-2 mt-4"
      :options.sync="list.pagination"
      :server-items-length="list.pagination.totalItems"
      :footer-props="tableFooter"
      :headers="legislatorTableHeader"
      @update:options="onSort"
      :items="list.items"
    >
      <template v-slot:item.action="{ item }">
        <div class="d-flex align-center" v-if="item.legislator">
        
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" small outlined class="mr-2" :disabled="item.legislator && item.legislator.abdicated_at !== null && !isSuperAdmin" color="primary" @click="edit(item.legislator)">
                    <v-icon small>manage_accounts</v-icon>
                  </v-btn>
                </template>
                <span>Manage {{ item.legislator.nickname }} {{ item.legislator.lastname }}</span>
              </v-tooltip>
              
            <v-tooltip v-if="!isLegSupport" bottom>
              <template v-slot:activator="{ on }">
                <v-btn style="color:#9155fd" class="mr-2" small outlined v-on="on" :disabled="item.legislator && item.legislator.abdicated_at !== null" @click="openAssignmentModal(item.legislator)">
                KP
                  <v-icon small color="primary">edit</v-icon>
                </v-btn>
              </template>
              <span>KP Assignments</span>
            </v-tooltip>
            
            <v-tooltip v-if="!isLegSupport" bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon small v-on="on" @click="$refs.donationModal.open(item.legislator)">
                  <v-icon small color="primary">monetization_on</v-icon>
                </v-btn>
              </template>
              <span>Manage Donations</span>
            </v-tooltip>
            
            <v-tooltip v-if="!isLegSupport" bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon small v-on="on" @click="$refs.TimelineComponent.toggle(item.legislator)">
                  <v-icon small color="primary">fa-regular fa-list-timeline</v-icon>
                </v-btn>
              </template>
              <span>Open Timeline</span>
              
            </v-tooltip>
            <v-tooltip v-if="!isLegSupport" bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon small v-on="on" @click="$refs.legConnectionsSmallModal.toggle(item.legislator)">
                  <v-icon small color="primary">fa-regular fa-chart-network</v-icon>
                </v-btn>
              </template>
              <span>Connections</span>
            </v-tooltip>
        </div>
      </template>

      <template v-slot:item.lastname="{ item }">
        <div class="d-flex align-center">
          <img
            class="ma-1"
            :style="{ 'padding-left': '1px', 'border-left': `3px solid ${partyColorList[item.legislator && item.legislator.party]}` }"
            :src="item.legislator && item.legislator.official_image ? getThumbImage(item.legislator.official_image) : '/images/member-avatar.jpg'"
            :alt="item.legislator && item.legislator.nickname + ' ' + item.legislator && item.legislator.lastname"
            width="50"
            @click="detail(item.legislator && item.legislator)"
          />
          <a class="ml-2 mr-1 no-line-break" @click="detail(item.legislator && item.legislator)" v-if="item.legislator && item.legislator.nickname && item.legislator.lastname">{{ item.legislator.nickname }} {{ item.legislator.lastname }}</a>
          <p v-else>Vacant</p>
          <vip-chip
            v-if="item.legislator && item.legislator.vip"
            size="small"
            :legislator="item.legislator && item.legislator"
            :show="'table_code'"
          >
          </vip-chip>

          <v-tooltip bottom v-if="item.legislator && item.legislator.lame_duck">
            <template v-slot:activator="{ on }">
              <a v-on="on">
            <v-icon color="#EECB23" size="18" class="mx-2">fa-solid fa-duck</v-icon>
              </a>
            </template>
              <span>Lame Duck</span>
          </v-tooltip>

           <v-tooltip bottom v-if="item.legislator && item.legislator.leg_data && !!item.legislator.leg_data.leg_notes" max-width="250">
           <template v-slot:activator="{ on }">
             <a v-on="on">
               <v-icon color="primary" size="14" class="mx-2">fa-duotone fa-file</v-icon>
             </a>
           </template>
           <span>{{ item.legislator && item.legislator.leg_data && item.legislator.leg_data.leg_notes }}</span>
         </v-tooltip>

         <v-tooltip bottom v-if="!!(item.legislator && item.legislator.leg_data && item.legislator.leg_data.vip_notes) && isVIP" max-width="250">
           <template v-slot:activator="{ on }">
             <a v-on="on">
               <v-icon color="secondary" size="14" class="mx-2">fa-duotone fa-file-lock</v-icon>
             </a>
           </template>
           <span>{{ item.legislator && item.legislator.leg_data && item.legislator.leg_data.vip_notes }}</span>
         </v-tooltip>
         <!-- This archived chip shows when legislator is archived. -->
          <v-chip v-if="item.legislator && item.legislator.abdicated_at" class="ma-2" color="#9e9e9e" text-color="white" x-small>ARCHIVED</v-chip>
         
        </div>
      </template>


      <template v-slot:item.district="{ item }">
        <v-btn text small @click="districtModal(item.district)">
          <span class="clickable-cell" >
          {{ item.district }}
          </span>
        </v-btn>
      </template>
      <template v-if="!isSuperAdmin && userClient.uses_regions" v-slot:item.district.region="{ item }">
        <span v-if="!item.isVacant">
          <span>
            {{ item.region ? item.region.name : '' }}
          </span>
        </span>
      </template>

      <template v-if="!isSuperAdmin" v-slot:item.leg_support="{ item }">
        <v-layout v-if="item.legislator && item.legislator.leg_data && item.legislator.leg_data.leg_support">
          <v-icon
            v-if="value <= (item.legislator.leg_data && item.legislator.leg_data.leg_support)"
            size="18"
            color="orange lighten-3"
            v-for="value in [1, 2, 3, 4, 5]" :key="value"
          >
            mdi-star
          </v-icon>
        </v-layout>
      </template>

      <template v-slot:item.elected_at="{ item }" >
        <span v-if="!item.isVacant">
          {{ item.legislator && item.legislator.elected_at ? item.legislator.elected_at.split('-')[0] : '' }}
        </span>
      </template>

      <template v-for="(val, index) in member_legislators_keys" v-slot:[val]="{ item }" >
        <div v-if="item.legislator && item.legislator.member_legislators && item.legislator.member_legislators[index]" :key="index" class="d-flex align-center" >
          <span class="no-line-break">
            <strong>KP{{ item.legislator.member_legislators[index].kp_position }}:</strong>
            <a @click="memberDetailModal(item.legislator.member_legislators[index].member)"> {{ item.legislator.member_legislators[index].member.nickname }} {{ item.legislator.member_legislators[index].member.lastname }}</a>
            <sup v-if="item.legislator.member_legislators[index].send_copy === 1">CC</sup>
          </span>
          <v-tooltip bottom v-if="item.legislator.member_legislators[index].notes" max-width="450">
            <template v-slot:activator="{ on }">
              <v-icon class="ml-1" size="18" v-on="on">note</v-icon>
            </template>
            <span>{{ item.legislator.member_legislators[index].notes }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="item.legislator.member_legislators[index].isKpHomeInLegDistrict" max-width="450">
            <template v-slot:activator="{ on }">
              <v-icon class="ml-1" color="success" size="18" v-on="on">fa-light fa-location-check</v-icon>
            </template>
            <span>Constituent</span>
          </v-tooltip>

          <v-tooltip bottom v-if="!item.legislator.member_legislators[index].isKpHomeInLegDistrict" max-width="450">
            <template v-slot:activator="{ on }">
              <v-icon class="ml-1" color="error" size="18" v-on="on">fa-light fa-location-xmark</v-icon>
            </template>
            <span>Not a Constituent</span>
          </v-tooltip>

          <v-tooltip bottom v-for="(relationship, j) in item.legislator.member_legislators[index].relationships" :key="j" max-width="450">
            <template v-slot:activator="{ on }">
              <v-avatar size="20" :color="relationship.color" class="ml-1" v-on="on">
                <v-icon size="14" color="white" v-if="relationship.icon == 'phone'">{{ relationship.icon }}</v-icon>
                <v-icon size="14" color="white" v-else-if="relationship.icon == 'fa-brands fa-twitter'">{{ relationship.icon }}</v-icon>
                <small v-else class="white--text">{{ relationship.icon }}</small>
              </v-avatar>
            </template>
            <span>{{ relationship.name }}</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:no-data>
        <p class="text-xs-center mb-0">No legislator registered yet.</p>
      </template>
    </v-data-table>
    <div
        v-if="!(list.pagination.last_page  == list.pagination.current_page || list.pagination.totalItems <= list.pagination.itemsPerPage)
        && listVacantDistricts
        && listVacantDistricts.length > 0"
        class="mt-8 my-8"
    >
      <v-data-table
        class="elevation-1"
        :footer-props="tableFooter"
        :headers="headers"
        :items="listVacantDistricts"
        v-if="listVacantDistricts.length"
      >
        <template v-slot:top>
          <v-toolbar
              flat
          >
            <v-toolbar-title>Vacant Districts</v-toolbar-title>
          </v-toolbar>
        </template>
        <template v-slot:item.district="{ item }">
          <span>{{item.district}}</span>
        </template>
        <template v-slot:item.name="{ item }">
          <span>{{item.name}}</span>
        </template>
        <template v-slot:item.ocd_id="{ item }">
          <span >{{item.ocd_id}}</span>
        </template>
      </v-data-table>
    </div>
    <donation-modal :clientId="clientId" ref="donationModal"></donation-modal>
    <timeline-component
        ref="TimelineComponent"
        :clientId="clientId"
    ></timeline-component>
  </v-layout>
</template>

<script>

  import donationModal from './donation-modal'
  import vipChip from  '@/components/legislators/vip-setting/vip-chip'
  import TimelineComponent from '@/components/legislators/timeline-modal'
  import { partyColorList } from '@/entities/party-color-list'

  export default {
    props: {
      list: {
        default: function () {
          return {
            items: [],
            pagination: {
              last_page: 0
            }
          }
        },
        type: Object
      },
      listVacantDistricts: {
        default: function () {
          return []
        },
        type: Array
      },
      clientId: {
        default: null,
        type: Number
      }
    },
    name: "LegislatorsTable",
    data() {
      return {
        partyColorList: partyColorList(),
        member_legislators_keys: [],
        tableFooter: {
          'items-per-page-options': [24, 48, 72]
        },
        headers: [
          { text: 'District', value: 'district', align: 'center', sortable: false, width: '10%'  },
          { text: 'District name', value: 'name', align: 'center', sortable: false, width: '70%' },
          { text: 'District ocd', value: 'ocd_id', align: 'center', sortable: false, width: '20%' },
        ]
      }
    },
    computed: {
      legislatorTableHeader () {
        let tableHeader = [
          { text: '', value: 'action', align: 'center', sortable: false },
          { text: 'Legislator', value: 'lastname', align: 'left', sortable: false },
          { text: 'District', value: 'district', align: 'center', sortable: false },
          { text: 'Region', value: 'district.region', align: 'center', sortable: false, width: '150' },
          /*{ text: 'Notes', value: 'leg_notes', align: 'center', sortable: false },*/
          { text: 'Support', value: 'leg_support', align: 'center', sortable: true },
          { text: 'Elected', value: 'elected_at', align: 'center', sortable: true },
        ]

        let hiddenColumns = [];
        if (this.isSuperAdmin) {
          hiddenColumns = ['Region', 'Notes', 'Support']
        } else if (!this.userClient.uses_regions) {
          hiddenColumns = ['Region']
        }

        tableHeader = tableHeader.filter((header) => !hiddenColumns.includes(header.text))

        let maxLength = 0
        let filteredLegislatorList = this.list.items.filter((item, index) => {
          return this.list.pagination.itemsPerPage == -1 || index <= this.list.pagination.itemsPerPage
        })

        filteredLegislatorList.forEach((item) => {
          if(item.legislator && item.legislator.member_legislators && item.legislator.member_legislators.length > maxLength) {
            maxLength = item.legislator.member_legislators.length
          }
        })

        this.member_legislators_keys = []

        for(let i = 0; i < maxLength; i++) {
          this.member_legislators_keys.push(`item.elem-${i}`)
          tableHeader.push({
            text: `KP${i + 1}`,
            value: `elem-${i}`,
            align: 'center',
            sortable: false,
            width: '200' 
          })
        }
        return tableHeader
      },
    },
    methods: {
      cardBeforeEnter(el) {
        el.style.opacity = 0;
        el.style.transform = 'scale(90%)';
        el.style.height = 0;
      },
      onSort(object) {
        this.$emit('onOrder', object.sortBy[0])
        this.$emit('isDesc', object.sortDesc[0])
      },
      cardEnter(el, done) {
        let delay = 200;
        setTimeout(() => {
          Velocity(
            el,
            { opacity: 1, height: '100%', scale: '100%' },
            { complete: done }
          );
        }, delay);
      },
      cardLeave(el, done) {
        let delay = 200;
        setTimeout(() => {
          Velocity(
            el,
            { opacity: 0, height: 0, scale: '90%' },
            { complete: done }
          );
        }, delay);
      },
      //modals
      edit (item) {
        this.$emit('edit', item)
      },
      addData (item) {
        this.$emit('add', item)
      },
      districtModal(item) {
        this.$emit('districtOpen', item)
      },
      detail(item) {
        this.$emit('detail', item)
      },
      memberDetailModal(item) {
        this.$emit('memberModalOpen', item)
      },
      openAssignmentModal(item) {
        this.$emit('assignment', item)
      },
    },
    watch: {
      'list.pagination.page'() {
        this.$emit('changePage', this.list.pagination.page)
      },
      'list.pagination.itemsPerPage'() {
        this.$emit('changePerPage', this.list.pagination.itemsPerPage)
      },
    },
    components: {
      donationModal,
      'vip-chip': vipChip,
      'timeline-component': TimelineComponent
    }
  }
</script>

<template>
  <v-dialog persistent max-width="750" v-model="isModalOpen">
    <v-card>
      <v-icon class="d-flex ml-auto pt-2 pr-4" @click.native="closeModal()"
              @click="$refs.sendEmail.removeAllAttachedFiles()">close</v-icon>
      <v-card-title>
        <span class="title">Sending {{ isEmail ? 'Email' : 'Text' }} to {{ kpCount }} Recipients</span>
        <v-spacer></v-spacer>
        <v-tooltip
            v-if="kpsCopy && kpsCopy.length > 0"
            bottom
            max-width="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
                v-bind="attrs"
                v-on="on"
            >
              <v-switch
                  color="primary"
                  class="d-inline-block mt-0"
                  label="Send to CC’d KP2+"
                  v-model="isKpsSend"
                  hide-details
              >
              </v-switch>
            </div>
          </template>
          <div>
            By design, only KP1 will receive KP messaging throughout the app.
            By enabling this feature for any KP2-5, each will also receive the same messages sent to KP1.
            They will also be able to log in as a normal KP.
            It is recommended to only enable this feature for those extra KPs who truly work as a team with the KP1.
            If your KP2-5 are only used as backups (and are not deeply involved), then do not enable this feature.
          </div>
        </v-tooltip>
        <v-spacer></v-spacer>
        <message-send-as
            :value="isEmail"
            @changeMessageType="(value) => isEmail = value"
            :disabled="disabledSwitchSend"
            class="mr-2"
            :messageLength="finalMessageLength"
        />
        <v-card-subtitle v-if="!isEmail" class="pa-0">This will send a text to all KPs with valid cell phone # and an
          email if
          they do not.
        </v-card-subtitle>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-alert v-if="error" dense type="error" outlined :value="true">{{ error }}</v-alert>
        <v-alert v-if="!isEmail && badCellOrEmails.length" dense type="error" outlined :value="true">
          Recipients with a red icon have an undeliverable phone/email and will not receive this message.
        </v-alert>
        <v-alert v-if="isEmail && badEmails.length" dense type="error" outlined :value="true">
          Recipients with a red icon have an undeliverable email and will not receive this message.
        </v-alert>
        <v-flex xs12 class="my-4">
          <v-expansion-panels v-model="isKpsOpen">
            <v-expansion-panel>
              <v-expansion-panel-header>Recipients ({{ members && members.length }})</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-select
                  v-model="members"
                  :items="members"
                  label="Selected KP(s)"
                  item-text="fullname"
                  return-object
                  outlined
                  multiple
                  :item-value="'legislator.id'"
                  dense
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip
                      :style="kpPreferenceBorder(item)"
                      :key="item.id"
                      @click:close="() => members.splice(index, 1)"
                      close
                    >
                      <v-icon left size="16" :color="isBad(item.email) ? 'error' : 'primary'">
                        email
                      </v-icon>
                      <v-icon
                        v-if="item.bad_cell || item.cell === null"
                        left
                        size="16"
                        color="error"
                      >
                        no_cell
                      </v-icon>
                      <v-icon
                        v-else
                        left
                        size="16"
                        color="primary"
                      >
                        phone_iphone
                      </v-icon>
                      {{ item.fullname }} {{ !unique && item.legislator ? `( ${item.legislator.fullname} )` : '' }}
                    </v-chip>
                  </template>
                </v-select>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>
        <v-flex xs12 class="my-4" v-if="isKpsSend && (kpsCopy && kpsCopy.length > 0)">
          <v-expansion-panels v-model="isOtherKpsOpen">
            <v-expansion-panel>
              <v-expansion-panel-header>Recipients KP2+ ({{ kpsCopy && kpsCopy.length }})</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-select
                  v-model="kpsCopy"
                  :items="kpsCopy"
                  label="Selected KP2+(s)"
                  item-text="member.fullname"
                  return-object
                  outlined
                  multiple
                  dense
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip
                      :style="kpPreferenceBorder(item.member)"
                      :key="item.id"
                      @click:close="closeKpsCopyChip(index)"
                      close
                    >
                      <v-icon left size="16" :color="isBad(item.member.email) ? 'error' : 'primary'">
                        email
                      </v-icon>
                      <v-icon
                        v-if="item.member.bad_cell || item.member.cell === null"
                        left
                        size="16"
                        color="error"
                      >
                        no_cell
                      </v-icon>
                      <v-icon
                        v-else
                        left
                        size="16"
                        color="primary"
                      >
                        phone_iphone
                      </v-icon>
                      {{ item.member.fullname }} {{ item.kp_position }} {{ !unique && item.legislator ? `( ${item.legislator.fullname} )` : '' }}
                    </v-chip>
                  </template>
                </v-select>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>
        <div v-show="!isEmail">
          <send-text
            ref="sendText"
            :text="text"
            :propMessage="text.message"
            :members="members"
            :isEmail="isEmail"
            :scheduleMessage="scheduleMessage"
            :client="client"
            :unique="unique"
            @setMessage="value => this.text.message = value"
            @error="childError"
            @finalMessageLength="value => setFinalMessageLength(value)"
          ></send-text>
        </div>
        <div v-show="isEmail">
          <send-email
            ref="sendEmail"
            :email="email"
            :propMessage="email.message"
            :members="members"
            :client="client"
            :isEmail="isEmail"
            :attachedFiles="attachedFiles"
            :isUploadingFiles="isUploadingFiles"
            :unique="unique"
            @setMessage="value => this.email.message = value"
            @setSubject="value => this.email.subject = value"
            @setFrom="value => this.email.from = value"
            @error="childError"
            @maxFileSize="onChangeMaxFileSize"
            @onAttachedFiles="onAttachedFiles"
            @updateFilesSize="onFileSizeUpdate"
            @finalMessageLength="value => setFinalMessageLength(value)"
          ></send-email>
        </div>
        <div v-if="!isSendNow" class="mt-2 px-2 full-width">
          <div class="caption grey--text pb-3">Schedule</div>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="6"
            >
              <v-menu
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="newdate"
                    label="Select date"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  :min="currentDate"
                  color="primary"
                  no-title
                  scrollable
                  @change="$refs.dateMenu.save(date)"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
            >
              <v-menu
                ref="timeMenu"
                v-model="timeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                :disabled="!date"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="getFormattedTime"
                    label="Select time"
                    prepend-icon="mdi-clock-outline"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    :clearable="text.id == null"
                    :disabled="!date"
                    hide-details
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="timeMenu"
                  v-model="time"
                  :min="currentDate == date ? currentTime : ''"
                  color="primary"
                  full-width
                  ampm-in-title
                  @click:minute="$refs.timeMenu.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <v-flex>
          <v-checkbox
            color="primary"
            v-model="isSendNow"
            label="Send Now"
            hide-details
            class="mt-3"
          >
          </v-checkbox>
        </v-flex>
        <v-alert
          v-if="isSummaryUploadLimit"
          class="mt-3"
          dense
          type="error"
          outlined
          :value="true"
        >
          The size of images and attached files is more than 25MB
        </v-alert>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-layout wrap class="justify-space-between">
        <v-tooltip v-if="sendCopyCheckbox" top class="pb-4">
          <template v-slot:activator="{ on }">
            <v-checkbox
              color="primary"
              hide-details
              class="mt-3 admins-copies_checkbox"
              v-model="sendCopiesToAdmins"
              v-on="on"
            >
              <template v-slot:label>
                <p class="mb-0">Send copy to select Admins<span class="primary--text">({{ clientAdmins }})</span></p>
              </template>
            </v-checkbox>
          </template>
          <span>This will send a copy of this message to all Admins who have their<br> "Receive copy of outgoing messages" in their Profile within your Team page.</span>
        </v-tooltip>
        <div class="mt-3">
          <v-btn
            outlined
            color="secondary"
            class="mr-2"
            @click.native="closeModal"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="isSendNow"
            color="primary"
            :loading="isBusy"
            :disabled="disabledSendButton || isSummaryUploadLimit"
            @click.native="onSend"
          >
            Send
          </v-btn>
          <v-btn
            v-else
            color="primary"
            :loading="isBusy"
            :disabled="!time && !date && disabledSendButton "
            @click.native="onSendSchedule"
          >
            Save & Schedule
          </v-btn>
        </div>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import sendEmail from '@/components/kp-tasks/send-message-modal/send-email'
import sendText from '@/components/kp-tasks/send-message-modal/send-text'
import MessageSendAs from "@/components/global/message-send-as"
import TinyConfig from "@/mixins/tiny-mce-config"

import ClientService from "@/services/ClientService";
import swal from "sweetalert2";
import he from "he";


export default {
  name: 'SendMessageModal',
  mixins: [TinyConfig, ClientService],
  components: {
    sendEmail, sendText, MessageSendAs,
  },
  props: {
    client: {
      type: Object,
      default: function () {
        return {}
      }
    },
    unique: {
      type: Boolean,
      default: false
    },
    legislators: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      disabledSwitchSend: false,
      sendCopiesToAdmins: false,
      isUploadingFiles: false,
      isModalOpen: false,
      maxSizeFile: false,
      isKpsOpen: false,
      isOtherKpsOpen: false,
      isSendNow: true,
      isKpsSend: false,
      dateMenu: false,
      timeMenu: false,
      isEmail: true,
      isBusy: false,
      date: null,
      time: null,
      scheduleMessage: {},
      legislatorList: [],
      attachedFiles: [],
      members: [],
      kpsCopy: [],
      error: '',
      showTestModal: false,
      formattedTestMessage: '',
      finalMessageLength: 0,
      email: {
        from: '',
        reply_to_email: '',
        subject: '',
        message: '',
        is_sms: false,
        formattedMessage: '',
        finalMessageLength: 0,
        files: [],
        member_legislator: []
      },
      text: {
        message: '',
        is_sms: true,
        formattedMessage: '',
        finalMessageLength: 0,
        member_legislator: [],
        show_legislators: 1
      }
    }
  },
  computed: {
    newdate: {
      get() {
        if (this.date == null || this.date == '') {
          return ''
        } else {
          // return moment(new Date(this.date)).format('MMM D, YYYY')
          return moment.utc(this.date).format('MMM D, YYYY')
        }
      },
      set(value) {
        if (value == null) {
          this.data = ''
        } else {
          this.data = moment.utc(value).format('MMM D, YYYY')
        }
      }
    },
    kpCount() {
      return this.members ? this.members.length : 0
    },
    formatKpsCopy() {
      return [...this.kpsCopy.map(kp => {
        return {
          member_id: kp.member_id,
          legislator_id: kp.leg_id
        }
      })]
    },
    sendCopyCheckbox() {
      return this.client
          && this.client.send_mailgun_notifications
          && this.clientAdmins
          && this.clientAdmins.length > 0;
    },
    disabledSendButton() {
      const checkEmail = !this.email.message || this.maxSizeFile
      const checkText = !this.text.message || this.text.finalMessageLength > 320
      return (this.isEmail && checkEmail) || (!this.isEmail && checkText) || (this.members && !this.members.length)
    },
    badCellOrEmails() {
      return this.members.filter(member => (!member.cell || member.bad_cell) || this.isBad(member.email))
    },
    badEmails() {
      return this.members.filter(member => this.isBad(member.email))
    },
    currentDate() {
      return new Date().toISOString().substr(0, 10)
    },
    currentTime() {
      const timezone = this.getTimezone(this.client);
      return moment().tz(timezone).add(10, 'minutes').format('HH:mm');
    },
    getFormattedTime() {
      return this.time ? moment(this.time, "hh:mm").format('h:mm a') : ''
    },
    titleModalHasEzLink() {
      if (this.hasEzLogin) {
        return `Ready to ${this.titleButtonSend}?`
      } else {
        return 'You did not include an ez_login link and KPs will not be able to easily log into the Dashboard. ' +
            `If this was on purpose, please click ${this.titleButtonSend}. If you'd like for KPs to easily self-report their progress, ` +
            'click Continue Editing and add the {ez_login} variable into the message body.'
      }
    },
    titleButtonSend() {
      return this.isSendNow ? 'Send' : 'Save & Schedule'
    },
    hasEzLogin() {
      let reg = /{ez_login}/
      if (this.isEmail && this.email.message) {
        return reg.test(this.email.message)
      }

      if (!this.isEmail && this.text.message) {
        return reg.test(this.text.message)
      }
    },
    isSummaryUploadLimit() {
      return (this.updatedImgSize + this.updatedFilesSize) > this.imgSizeLimit
    },
    isShowingUnique() {
      return this.unique
    }
  },
  methods: {
    toggle(legislatorList) {
      this.isModalOpen = true
      this.isSendNow = true
      this.isPreview = false
      this.error = ''
      this.kpsCopy = []
      this.legislatorList = legislatorList.filter(legislator => legislator.primary_kp && legislator.primary_kp.member.email)
      this.members = legislatorList.filter(legislator => !legislator.primary_kp.member.silence).map(legislator => {
        return {
          ...legislator.primary_kp.member,
          legislator: legislator,
          client: this.client
        }
      })
      this.getKpsCopy(legislatorList)
      let memberLegislator = this.legislatorList.map(legislator => {
        return {
          member_id: legislator.primary_kp.member.id,
          legislator_id: legislator.id,
        }
      })
      this.isKpsSend = false
      this.$nextTick().then(() => {
        if (this.$refs.sendEmail.$refs.emailForm) this.$refs.sendEmail.$refs.emailForm.reset()
      }).then(() => {
        this.setText(memberLegislator)
        this.setEmail(memberLegislator)
      })
    },
    getKpsCopy(legislatorList) {
      if (this.unique) {
        this.legislators
          .filter(legislator => {
            return legislatorList
              .filter(leg => leg.primary_kp && leg.primary_kp.member.email && !leg.primary_kp.member.silence)
              .map(leg => leg.primary_kp.member)
              .some(kp => legislator.primary_kp && legislator.primary_kp.id == kp.id)
          })
          .filter(legislator => legislator.active_kps.some(kp => kp.kp_position != 1 && kp.send_copy))
          .forEach(legislator => {
            legislator.active_kps.forEach(kp => {
              const isKp1 = this.members.some(member => member.id === kp.member.id)
              const duplicate = this.kpsCopy.some(member => member.id === kp.member.id)
              if (kp.kp_position != 1 && kp.send_copy && !isKp1 && !duplicate) {
                this.kpsCopy.push(kp)
              }
            })
          })
      } else {
        this.legislatorList.forEach(legislator => {
          legislator.active_kps.forEach(kp => {
            if (kp.kp_position != 1 && kp.send_copy) {
              this.kpsCopy.push(kp)
            }
          })
        })
      }
    },
    toggleOnScheduleMessagePage(scheduleMessage) {
      this.isModalOpen = true
      this.time = null
      this.error = ''
      this.kpsCopy = []
      this.isSendNow = false
      this.isPreview = false
      this.scheduleMessage = scheduleMessage
      this.disabledSwitchSend = true
      this.isKpsSend = !!scheduleMessage.is_kps_send

      this.attachedFiles = scheduleMessage.files ? scheduleMessage.files : []
      this.members = scheduleMessage.members.filter(member => !member.silence && !member.pivot.is_kps_send)

      if (scheduleMessage.members[0].pivot.legislator_id) {
        this.members.map(member => {
          let memberLegislator = member.member_legislator
              .filter(memberLegislator => {
                return memberLegislator.leg_id === member.pivot.legislator_id && memberLegislator.kp_position == 1
              })

          if (memberLegislator && memberLegislator.length < 1) memberLegislator.shift()

          if (memberLegislator.length < 1)  memberLegislator.legislator = {}

          if(memberLegislator && memberLegislator.legislator) {
            memberLegislator.legislator.districtShortName = memberLegislator && memberLegislator.legislator && memberLegislator.legislator.length > 0 ? memberLegislator.legislator && memberLegislator.legislator.district && memberLegislator.legislator.district.district : '';
          }
         
          return {
            ...member,
            legislator: memberLegislator.legislator,
            client: this.client
          }
        })
      }

      const memberLegislator = this.scheduleMessage.members.map(item => {
        return item.pivot
      })

      this.kpsCopy = scheduleMessage.members.filter(member => !member.silence && member.pivot.is_kps_send)
        .map(member => {
          let memberLegislator = member.member_legislator
            .filter(memberLegislator => {
              return memberLegislator.leg_id === member.pivot.legislator_id && memberLegislator.kp_position !== 1
            })
            .shift()
          memberLegislator.member = member
         return memberLegislator
        })
      this.$nextTick().then(() => {
        let deliveryAt = moment(scheduleMessage.delivery_at, 'YYYY-MM-DD HH:mm:ss')
        this.date = deliveryAt.format('YYYY-MM-DD')
        this.time = deliveryAt.format('HH:mm')
        this.sendCopiesToAdmins = scheduleMessage.is_admin_send

        if (scheduleMessage.is_sms) {
          this.isEmail = false
          this.setText(memberLegislator, scheduleMessage)
        } else {
          this.isEmail = true
          this.setEmail(memberLegislator, scheduleMessage)
        }
      })

    },
    dateTimeFormatter(value) {
      if (!value) return ''
      const utc = moment.utc(value).toDate()
      return moment(utc).local().format('MMM D, YYYY hh:mm A')
    },
    setText(memberLegislator, scheduleMessage = {}) {
      if (scheduleMessage && scheduleMessage.id) {
        this.text = {
          id: scheduleMessage.id,
          clientId: this.client.id,
          is_sms: 1,
          is_kps_send: scheduleMessage.is_kps_send,
          finalMessageLength: 0,
          from_dashboard_page: true,
          message: scheduleMessage.message ? scheduleMessage.message : `Hi {kp_first},\n\nThanks! -${this.userData.nickname}`
        }

        if (!this.unique) {
          this.text.member_legislator = memberLegislator
        }

      } else {
        this.text = {
          clientId: this.client.id,
          is_sms: 1,
          is_kps_send: this.isKpsSend ? 1 : 0,
          from_dashboard_page: true,
          member_legislator: memberLegislator,
          finalMessageLength: 0,
          message: `Hi {kp_first},\n\nThanks! -${this.userData.nickname}`
        }
      }
    },
    setEmail(memberLegislator, scheduleMessage = {}) {
      if (scheduleMessage && scheduleMessage.id) {
        this.email = {
          id: scheduleMessage.id,
          clientId: this.client.id,
          from: this.userName,
          from_dashboard_page: true,
          files: [],
          is_sms: 0,
          is_kps_send: scheduleMessage.is_kps_send,
          show_legislators: +!this.isShowingUnique,
          delivery_at: scheduleMessage.delivery_at,
          subject: scheduleMessage.subject ? scheduleMessage.subject : '',
          message: scheduleMessage.message ? scheduleMessage.message : `
            Hi {kp_first},<p><br/></p>Thanks! -${this.userData.nickname}
          `
        }
        if (!this.unique) this.email.member_legislator = memberLegislator
      } else {
        this.email = {
          clientId: this.client.id,
          is_kps_send: this.isKpsSend ? 1 : 0,
          from_dashboard_page: true,
          from: this.userName,
          is_sms: 0,
          member_legislator: memberLegislator,
          show_legislators: +!this.isShowingUnique,
          message: `Hi {kp_first},<p><br/></p>Thanks! -${this.userData.nickname}`
        }
      }
    },
    onAttachedFiles(value) {
      this.attachedFiles = value
    },
    onChangeMaxFileSize(value) {
      this.maxSizeFile = value
    },
    closeKpsCopyChip(memberIndex) {
      this.kpsCopy.splice(memberIndex, 1)
      if (this.kpsCopy && this.kpsCopy.length < 1) {
        this.isKpsSend = false
      }
    },
    closeModal() {
      this.isModalOpen = false
      this.disabledSwitchSend = false
      this.isUploadingFiles = false
      this.sendCopiesToAdmins = false
      this.isBusy = false
      this.email.files = []
      this.date = ''
      this.time = ''
      this.currentImgsSize = 0
      this.updatedImgSize = 0
      this.currentFilesSize = 0
      this.updatedFilesSize = 0
      this.$refs.sendEmail.removeAllAttachedFiles()
    },
    childError() {
      this.error = ''
    },
    async addFiles() {
      if (this.email.files && this.email.files.length) {
        this.isUploadingFiles = true
        let promises = []

        for (let file of this.email.files) {
          let data = new FormData()
          data.append('file', file)
          promises.push(this.saveScheduleMessageFile(data))
        }

        this.email.files = (await Promise.all(promises)).map(res => {
          return res.data
        })
      }
    },
    async onSendEmail() {
      if (this.unique || this.email.member_legislator) {
        this.email.members = this.members.map(member => member.id)
      } else {
        this.email.member_legislator = this.members.map(member => {
          return this.email.member_legislator.find(memberLegislator => memberLegislator.member_id === member.id && memberLegislator.legislator_id === member.legislator.id)
        })
      }

      if (this.scheduleMessage && this.scheduleMessage.id) {
        await this.addFiles()
        if (this.attachedFiles && this.attachedFiles.length) this.email.files.push(...this.attachedFiles)
        await this.saveClientScheduleMessage(this.email)
      } else {
        await this.addFiles()
        await this.saveClientScheduleMessage(this.email)
      }
    },
    html2Text(html) {
      let find = /(<br>|<\/p><p>)/,
          re = new RegExp(find, 'g');
      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    },
    setFinalMessageLength(value) {
      this.finalMessageLength = value
    },
    async onSendText() {
      this.text.message = this.html2Text(this.text.message)
      await this.saveClientScheduleMessage({...this.text})
    },
    somethingWentWrong() {
      this.isBusy = false
      this.isUploadingFiles = false
      this.error = 'Something went wrong!'
    },
    onSend() {
      swal({
        text: this.titleModalHasEzLink,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.titleButtonSend,
        cancelButtonText: 'Continue Editing',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          if (!this.members || !this.members.length) {
            this.somethingWentWrong()
            return
          }

          if (this.isEmail) {
            
            if (!this.$refs.sendEmail.validationForm) return;

            this.isBusy = true
            this.email.is_sms = 0
            this.email.is_admin_send = this.sendCopiesToAdmins ? 1 : 0
            this.email.show_legislators = +!this.isShowingUnique
            this.email.from_dashboard_page = true
            this.email.is_kps_send = this.isKpsSend ? 1 : 0
            this.email.kp2_members = this.formatKpsCopy;
            delete this.email.delivery_at;

            this.onSendEmail().then(() => {
              this.onCallToastr('success', 'Email has been sent.', 'Success!')
              this.$emit('update')
              this.closeModal()
            }, (err) => {
              this.somethingWentWrong()
              console.error(err)
            });

          } else {

            this.isBusy = true
            this.text.is_sms = 1
            this.text.show_legislators = +!this.isShowingUnique
            this.text.is_admin_send = this.sendCopiesToAdmins ? 1 : 0
            this.text.from_dashboard_page = true
            this.text.is_kps_send = this.isKpsSend ? 1 : 0
            this.text.kp2_members = this.formatKpsCopy;
            delete this.text.delivery_at;

            this.onSendText().then(() => {
              this.onCallToastr('success', 'Text has been sent.', 'Success!')
              this.$emit('update')
              this.closeModal()
            }, (err) => {
              this.somethingWentWrong()
              console.error(err)
            })
          }
        }
      })
    },
    onSendSchedule() {
      swal({
        text: this.titleModalHasEzLink,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.titleButtonSend,
        cancelButtonText: 'Continue Editing',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          if (!this.members || !this.members.length) {
            this.somethingWentWrong()
            return
          }

          if (this.isEmail) {
            if (!this.$refs.sendEmail.validationForm) return
            this.isBusy = true
            this.email.is_sms = 0
            this.email.show_legislators = +!this.isShowingUnique
            this.email.is_admin_send = this.sendCopiesToAdmins ? 1 : 0
            this.email.delivery_at = `${this.date} ${this.time}`
            this.email.from_dashboard_page = true
            this.email.is_kps_send = this.isKpsSend ? 1 : 0
            this.email.kp2_members = this.formatKpsCopy

            this.onSendEmail().then(() => {
              this.onCallToastr('success', `Email has been scheduled.`, 'Success!')
              if (this.$route.name === 'scheduled-messages-page') {
                this.$emit('update')
              }
              this.closeModal()
            }, (err) => {
              this.somethingWentWrong()
              console.error(err)
            })
          } else {
            this.isBusy = true
            this.text.is_sms = 1
            this.text.show_legislators = +!this.isShowingUnique
            this.text.is_admin_send = this.sendCopiesToAdmins ? 1 : 0
            this.text.delivery_at = `${this.date} ${this.time}`
            this.text.from_dashboard_page = true
            this.text.is_kps_send = this.isKpsSend ? 1 : 0
            this.text.kp2_members = this.formatKpsCopy

            this.onSendText().then(() => {
              this.onCallToastr('success', `Text has been scheduled.`, 'Success!')
              if (this.$route.name === 'scheduled-messages-page') {
                this.$emit('update')
              }
              this.closeModal()
            }, (err) => {
              this.somethingWentWrong()
              console.error(err)
            })
          }
        }
      })
    },
  }
}
</script>

<template>
  <div>
    <v-text-field
      class="pt-0"
      hide-details
      outlined
      dense
      clearable
      placeholder="Name or keyword"
      prepend-inner-icon="search"
      v-model="searchText"
    ></v-text-field>

    <v-list class="member-list" :class="isMobile ? 'px-0' : 'mt-4'">
      <v-layout
        v-if="isMembersLoading && !members.items.length"
        class="align-center column pa-5"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          size="44"
          width="4"
        ></v-progress-circular>
          {{ 'Loading messages...' }}
      </v-layout>

      <template v-for="(member, index) in members.items">
        <v-divider v-if="index !== 0"></v-divider>
        <v-list-item
          :class="{
                  'activeDark': selectedMemberIndex === index && $vuetify.theme.dark,
                  'activeLight': selectedMemberIndex === index && !$vuetify.theme.dark,
                  'px-0 py-0': isMobile,
                  'vipLight': isVIP && member.is_my_kp && !$vuetify.theme.dark,
                  'vipDark': isVIP && member.is_my_kp && $vuetify.theme.dark
                }"
          @click="onSelectMember(index, 0)"
          :key="member.id"
        >
          <v-list-item-avatar
            v-if="!member.avatar_url"
            :style="kpPreferenceBorder(member)"
            :color="getColor(member.nickname)"
            class="justify-center"
            :class="isMobile ? 'mr-2' : 'mr-4'"
          >
            <span class="white--text headline">{{ member.nickname[0] }}</span>
          </v-list-item-avatar>
          <v-list-item-avatar v-else :class="isMobile ? 'mr-2' : 'mr-4'">
            <img
              v-if="member.avatar_url"
              :src="getThumbImageMedium(member.avatar_url)"
              :alt="member.nickname + ' '+ member.lastname"
            />
          </v-list-item-avatar>
          <v-list-item-content class="py-0">
            <v-list-item-title>
              <v-btn
                small
                text
                class="mr-1 no-line-break elevation-0 px-0"
                @click.stop="$refs.memberDetailModal.toggle(member)"
              >
                {{ member.nickname }} {{ member.lastname }}
              </v-btn>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ member.last_message | dateDiffFormatter(true) }} | {{ getTextFromHTML(member.message) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="isVIP && member.legislator_regions && member.legislator_regions.length">
              <div>
                {{ member.legislator_regions[0].name }} - {{ member.legislator_regions[0].member.fullname }}
              </div>
              <v-tooltip bottom v-if="member.legislator_regions.length > 1">
                <template v-slot:activator="{ on }">
                  <a v-on="on" class="d-flex align-center">
                    <span>{{ member.legislator_regions.length - 1 }} more region(s)</span>
                  </a>
                </template>
                <div>
                  <div
                    v-if="index != 0"
                    v-for="(data, index) in member.legislator_regions"
                    :key="index"
                    class="my-1"
                  >
                    {{ data.name }} - {{ data.member.fullname }}<br>
                  </div>
                </div>
              </v-tooltip>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="ml-0 my-0">
            <v-layout v-if="member.silence">
              <v-badge right overlap :value="member.unseen_email_count > 0" color="error">
                <v-btn size="18" icon ripple @click.stop="onSelectMember(index, 0)" :small="isMobile">
                  <v-icon color="secondary">mdi-comment-remove-outline</v-icon>
                </v-btn>
              </v-badge>
            </v-layout>
            <v-layout v-else>
              <v-badge right overlap :value="member.unseen_email_count > 0" color="error">
                <template v-slot:badge>
                  <span v-if="!!member.unseen_email_count">{{ member.unseen_email_count }}</span>
                </template>
                <v-btn icon @click.stop="onSelectMember(index, 0)" :small="isMobile">
                  <v-icon size="18" v-if="isBad(member.email)" color="error">
                    {{ selectedMemberIndex === index && isSms === 0 ? "mail" : "mail_outline" }}
                  </v-icon>
                  <v-icon
                    v-else
                    size="18"
                    :color="selectedMemberIndex === index && isSms === 0 ? 'primary' : getTextColor"
                  >
                    mail_outline
                  </v-icon>
                </v-btn>
              </v-badge>
              <v-badge right overlap :value="member.unseen_text_count > 0" color="error">
                <template v-slot:badge>
                  <span v-if="!!member.unseen_text_count">{{ member.unseen_text_count }}</span>
                </template>
                <v-btn icon ripple @click.stop="onSelectMember(index, 1)" :small="isMobile">
                  <v-icon
                    size="18"
                    :color="selectedMemberIndex === index && isSms === 1 ? 'primary' : member.bad_cell ?
                       'error' : getTextColor"
                  >
                    {{ member.bad_cell || member.bad_cell === null ? 'no_cell' : 'phone_iphone' }}
                  </v-icon>
                </v-btn>
              </v-badge>
            </v-layout>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>

    <v-btn
      color="primary"
      text
      block
      small
      @click="loadMoreMembers"
      :disabled="members.pagination.last_page <= members.pagination.page"
      :loading="isMembersLoading && !!members.items.length"
    >
      <span>Load more</span>
      <v-icon class="ml-2">keyboard_arrow_down</v-icon>
    </v-btn>


    <member-detail-modal
      ref="memberDetailModal"
      :selected-client="client"
      @openDistrictModal="openDistrictModal"
    ></member-detail-modal>

    <div id="htmlConvertContainer" v-show="false"></div>
  </div>
</template>

<script>
import _ from "lodash";
import MemberDetailModal from "@/components/members/member-detail-modal";
import { mapGetters } from "vuex";
import DistrictService from "@/services/DistrictService";
import ClientService from "@/services/ClientService";
import UserService from "@/services/UserService";

export default {
  name: "UserViewComponent",
  mixins: [DistrictService, ClientService, UserService],
  components: {
    'member-detail-modal': MemberDetailModal,
  },
  data() {
    return {
      searchText: null,
      selectedMemberIndex: null,
      isSms: 0,
      isMembersLoading: false,
      members: {
        pagination: {
          page: 1,
          itemsPerPage: 10,
          last_page: 0,
        },
        items: []
      },
    }
  },
  computed: {
    ...mapGetters('app', ['superAdminSelectedClient']),
    client() {
      return this.isSuperAdmin ? this.superAdminSelectedClient : this.userClient
    },
    getTextColor() {
      this.$vuetify.theme.dark ? 'rgba(231,227,252,.87)' : 'rgba(94,86,105,.87)'
    },
    selectedMember() {
      return this.members.items[this.selectedMemberIndex]
    },
  },
  filters: {
    dateFormatter(value) {
      return moment(value).format('hh:mm A')
    }
  },
  methods: {
    getMembersMessages(action = '') {
      this.isMembersLoading = true
      let data = {
        page: this.members.pagination.page,
        limit: this.members.pagination.itemsPerPage,
        search_text: this.searchText
      }

      return this.getClientMessageMembersWithPagination(this.client.id, data)
          .then(response => {
            if (action === 'loadMore') {
              this.members.items = this.members.items.concat(response.data.data ? response.data.data : response.data)
            } else {
              this.members.items = response.data.data ? response.data.data : response.data
            }
            this.members.pagination.last_page = response.data.last_page
            this.isMembersLoading = false
          })
          .catch((err) => {
            this.handleError(err)
          })
    },
    getTextFromHTML(html) {
      const element = document.getElementById('htmlConvertContainer')
      element.innerHTML = html
      return element.textContent
    },
    refresh() {
      this.isMembersLoading = true
      this.selectedMemberIndex = null
      this.members.items = []

      this.getMembersMessages().then(() => {
        this.isMembersLoading = false
      })
    },
    loadMoreMembers() {
      this.members.pagination.page++
      this.getMembersMessages('loadMore')
    },
    async onSelectMember(memberIndex, isSms) {
      this.isSms = isSms
      this.selectedMemberIndex = memberIndex

      const hasUnseenEmailMessages = this.selectedMember.unseen_email_count > 0
      const hasUnseenTextMessages = this.selectedMember.unseen_text_count > 0
      const hasCurrentUnseenMessages = (hasUnseenEmailMessages && !this.isSms) || (hasUnseenTextMessages && this.isSms)

      if (hasCurrentUnseenMessages) {
        await this.sawMessage(this.userId, {
          member_id: this.selectedMember.id,
          is_sms: this.isSms,
        })
      }

      if (isSms === 0) {
        this.selectedMember.unseen_email_count = 0
      } else {
        this.selectedMember.unseen_text_count = 0
      }

      this.members.items.splice(memberIndex, 1, this.selectedMember)

      const emitData = {
        member: this.selectedMember,
        isSms: isSms
      }

      this.$emit('onSelectMember', emitData)
    },
  },
  watch: {
    searchText: _.debounce(function () {
      this.members.items = []
      this.members.pagination.page = 1
      this.$emit('refresh')
    }, 300),
  }
}
</script>

<style lang="scss" scoped>
.activeLight {
  background-color: #f4f5fa;
}

.activeDark {
  background-color: #28243d;
}

.vipDark {
  background-color: rgba(145, 85, 253, 0.3);
}

.vipLight {
  background-color: rgba(197, 172, 246, 0.87);
}

.member-list {
  overflow-y: auto;
  height: calc(100vh - 340px);

  .v-list-item {
    padding: 0;
  }

  .member {
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }

    &.active {
      background-color: #eee;
    }
  }
}
</style>

<template>
  <div>
    <v-form ref="textForm">
      <v-flex xs12 class="d-flex justify-end mb-2 align-center px-2 body_variables">
        <v-flex xs12 sm8
                :class="!isMobile ? 'd-flex justify-space-between align-end' : 'd-flex justify-space-between align-end w-100'">
          <div class="caption grey--text">Message*</div>
          <v-spacer/>
          <v-btn
            v-if="badVariables"
            x-small
            outlined
            color="primary"
            @click="onCheckVariables"
          >
            Show Error
          </v-btn>
          <v-switch
            color="primary"
            v-model="isPreview"
            :label="isPreview ? 'Edit' : 'Show Preview'"
            :disabled="recipients.length === 0 || isLoading"
            class="d-inline-block px-2 sms-preview-switch"
            hide-details
            @change="onPreviewMessage"
          >
          </v-switch>
        </v-flex>
        <v-flex xs12 sm4>
          <v-select
            outlined
            v-model="variable"
            :items="variableList"
            class="subject_variable-select text_subject_variable-select"
            label="Body Variables"
            hide-details
            dense
          >
            <template v-slot:item="data">
              <v-list-item-content @click="onAddVariable(data.item)" v-text="data.item"></v-list-item-content>
            </template>
          </v-select>
        </v-flex>
      </v-flex>
      <p v-if="badVariables" class="red--text mb-0">
        You have bad variables! Please click on 'Show Error' button and fix them.
      </p>
      <v-flex xs12 id="editorContainer" class="px-2 mt-1 full-width">
        <div class="tiny_editor" v-if="!isPreview && startEditor">
          <div :class="disabledEditor ? 'disabledEditor' : ''"></div>
          <editor
            v-if="!isLoading"
            v-model="message"
            :api-key="TINY_MCE_API_KEY"
            :plugins="tinyPlugins"
            :toolbar="tinyToolbars"
            :init="tinyInit"
            :disabled="disabledEditor"
            @click="onChangeText"
            @input="onChangeText"
          ></editor>
        </div>
        <div v-if="isPreview && startEditor">
          <editor
            v-if="!isLoading"
            :value="formattedMessage"
            :api-key="TINY_MCE_API_KEY"
            :plugins="tinyPlugins"
            :toolbar="tinyToolbars"
            :init="tinyInit"
            disabled
          ></editor>
        </div>
        <div class="d-flex justify-end mt-2">
          <a v-if="message && recipients.length" @click="onOpenTestMessageModal" class="mr-4">
            Send a test...
          </a>
          <span class="text-counter" :class="finalMessageLength > 320 ? 'error--text' : ''">
            {{ finalMessageLength }}/320
          </span>
        </div>
      </v-flex>
    </v-form>
    <SuperAdminTestMessageModal
      ref="SuperAdminTestMessageModal"
      :isEmail="false"
      :recipient="recipients[0]"
      :message="message"
    />
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import TinyConfig from "@/mixins/tiny-mce-config"
import AppService from '@/services/AppService'
import SuperAdminTestMessageModal from '@/components/global/super-admin-test-message-modal'
import ShorterUrl from "@/mixins/shorterUrl";
import he from "he";

export default {
  name: 'SendText',
  mixins: [AppService, TinyConfig, ShorterUrl],
  components: {
    'editor': Editor,
    SuperAdminTestMessageModal
  },
  props: {
    recipients: {
      type: Array,
      default: function () {
        return []
      }
    },
    isBusy: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    propMessage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isPreview: false,
      formattedMessage: '',
      badVariables: false,
      variable: '',
      startEditor: false
    }
  },
  computed: {
    message: {
      get() {
        return this.propMessage
      },
      set(newValue) {
        this.$emit('setMessage', newValue)
      }
    },
    isLoading: {
      get() {
        return this.isBusy
      },
      set(newValue) {
        this.$emit('onLoading', newValue)
      }
    },
    tinyToolbars() {
      return []
    },
    variableList() {
      return [
        '{recipient_first_name}',
        '{recipient_last_name}',
        '{assoc_abbrev}',
        '{assoc_fullname}',
      ]
    },
    finalMessageLength() {
      let recipientFirstName = ''
      let recipientLastName = ''
      let assocAbbrev = ''
      let assocFullName = ''

      this.recipients.forEach(recipient => {
        if (recipientFirstName.length < (recipient.nickname || '').length) recipientFirstName = recipient.nickname
        if (recipientLastName.length < (recipient.lastname || '').length) recipientLastName = recipient.lastname
        if (assocAbbrev.length < (recipient.client.assoc_abbrev || '').length) assocAbbrev = recipient.client.assoc_abbrev
        if (assocFullName.length < (recipient.client.association || '').length) assocFullName = recipient.client.association
      })

      let message = this.message || ''
      message = message.replace(/{recipient_first_name}/g, recipientFirstName)
      message = message.replace(/{recipient_last_name}/g, recipientLastName)
      message = message.replace(/{assoc_abbrev}/g, assocAbbrev)
      message = message.replace(/{assoc_fullname}/g, assocFullName)
      message = this.html2Text(message)
      return message.length
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.startEditor = true
    })
  },
  methods: {
    onCheckVariables() {
      this.checkVariablesForEditor(this.message, this.variableList)
    },
    onPreviewMessage() {
      if (!this.isPreview || this.message === '') return;
      this.isLoading = true

      let payload = {
        message: this.message,
      }

      if (this.recipients[0].is_user) {
        payload.user_id = this.recipients[0].id
      } else {
        payload.member_id = this.recipients[0].id
      }

      this.getAppFormattedMessage(payload)
        .then((response) => {
          this.formattedMessage = response.data
        })
        .catch(() => {
          this.onCallToastr('error', 'Something went wrong, please try again later!', 'Failed!')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onAddVariable(variable) {
      variable += ' '
      tinymce.activeEditor.execCommand('mceInsertContent', false, variable);
    },
    async onChangeText() {
      this.$nextTick(async () => {
        this.searchIncorrectVariables(this.message, this.variableList)
        const newContent = await this.parsUrl(this.message)

        if (newContent !== this.message) {
          this.message = newContent
          this.disabledEditor = false
          document.activeElement.blur()
        }
      })
    },
    html2Text(html) {
      let find = /(<p><br[\/]?><[\/]?p>)/,
        re = new RegExp(find, 'g');

      let txt = html.replace(re, '\n');
      txt = txt.replace(/<[^>]*>?/gm, '')
      return he.decode(txt)
    },
    onOpenTestMessageModal() {
      this.$refs.SuperAdminTestMessageModal.open()
    },
  },
  watch: {
    badVariables: function () {
      this.searchIncorrectVariables(this.message, this.variableList)
      if (!this.badVariables) {
        this.checkVariablesForEditor(this.message, this.variableList)
      }
      this.$emit('badVariables', this.badVariables)
    },
    'message': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('finalMessageLength', this.finalMessageLength)
      }
    },
  }
}
</script>

import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import momentTZ from 'moment-timezone'

Vue.mixin({
  computed: {
    ...mapState('app', {
      AppState: state => state
    }),
    ...mapState('auth', {
      userData: state => state.userData,
      refreshedAt: state => state.refreshedAt
    }),
    ...mapGetters('auth', [
      'userId',
      'userMemberId',
      'userIsKP1',
      'userClientId',
      'userClient',
      'userName',
      'userEmail',
      'userRole',
      'userMailingAddress',
      'userCellPhone',
      'userPermissions',
    ]),
    isSuperAdmin () {
      return this.userRole === 'Super Admin'
    },
    isVIP () {
      return this.userRole === 'VIP'
    },
    isCoordinator () {
      return this.userRole === 'Coordinator'
    },
    isKP () {
      return this.userRole === 'KP'
    },
    isMember () {
      return this.userRole === 'Member'
    },
    isLegSupport () {
      return this.userRole === 'Leg Support'
    },
    phoneNumberRules () {
      return [
        v => (!v || !!v && v.length === 14) || 'Invalid phone number',
      ]
    },
    clientAdmins () {
      if(!this.userClient || !this.userClient.users_with_enabled_receive_copy_messages) return ''

      return this.userClient.users_with_enabled_receive_copy_messages.map(item => item.nickname).join(', ')
    },
    isDarkTheme: {
      get(){
        return this.$vuetify.theme.dark
      },
      set(newValue) {
        this.$vuetify.theme.dark = newValue
      }
    },
    appTheme() {
      if (this.isDarkTheme) {
        return this.$vuetify.theme.themes.dark
      }
      return this.$vuetify.theme.themes.light
    },
  },
  filters: {
    dateTimeFormatter (value) {
      if (!value) return ''
      const utc = moment.utc(value).toDate()
      return moment(utc).local().format('MMM D, YYYY hh:mm A')
    },
    dateTimeFormatterWithoutUTC (value, format) {
      if (!value) return ''
      if (!format) format = 'MMM D, YYYY hh:mm A'
      return moment(value).format(format)
    },
    timeFormatter (value) {
      if (!value) return ''
      const utc = moment.utc(value).toDate()
      return moment(utc).local().format('hh:mm A')
    },
    dateDiffFormatter (value, isUTC) {
      let date = null
      if (isUTC) {
        const utc = moment.utc(value).toDate()
        date = moment(utc).local()
      } else {
        date = moment(value)
      }
      const duration = moment.duration(moment().diff(date))
      if (duration.asYears() >= 1) return `${parseInt(duration.asYears())}yr`
      if (duration.asMonths() >= 1) return `${parseInt(duration.asMonths())}mo`
      if (duration.asDays() >= 1) return `${parseInt(duration.asDays())}day`
      if (duration.asHours() >= 1) return `${parseInt(duration.asHours())}hr`
      if (duration.asMinutes() >= 1) return `${parseInt(duration.asMinutes())}min`
      return '1min'
    },
    integerFormatter (value, prefix, targetLength, paddingString) {
      if (!value) return prefix + ''.padStart(targetLength, '-')
      return prefix + value.toString().padStart(targetLength, paddingString)
    },
    textFormatter (value, length) {
      if (value.length <= length) return value
      return value.substring(0, length) + '...'
    }
  },
  created() {
    // moment.tz.setDefault('Etc/UTC');
    moment.tz.setDefault('America/New_York');
  },
  methods: {
    formatDate(value, format= 'MMM D, YYYY') {
      if (!value) return ''
      const timezone = this.getTimezone(this.userClient);
      return moment(value).tz(timezone).format(format)
    },
    getTimezone(client){
      return client? client.timezone : 'America/New_York';
    },
    onCallToastr(type = 'success', message, title, timeOut = 2000) {
      toastr.clear()
      toastr.options.closeButton = true;

      if(type === 'warning') toastr.warning(message, title, {timeOut: timeOut})
      if(type === 'error') toastr.error(message, title, {timeOut: timeOut})
      if(type === 'success') toastr.success(message, title, {timeOut: timeOut})
    },
    handleError (err, useToastr) {
      this.isBusy = false
      switch (err.response.status) {
        case 400:
        case 401:
          this.error = err.response.data.message
          break
        case 422:
          _.forEach(err.response.data.errors, (error) => {
            this.error = error[0]
          })
          break
        default:
          this.error = 'Something went wrong!'
      }
      if (!useToastr) return
      this.onCallToastr('error', this.error, 'Error!')
    },
    isBad (text) {
      return text == null || /^BAD_/.test(text)
    },
    getThumbImage (imgUrl) {
      const baseUrl = 'https://res.cloudinary.com/kpdash/image/upload/'
      if (imgUrl.indexOf(baseUrl) === -1) {
        return imgUrl
      }
      const sections = imgUrl.split(baseUrl)
      return `${baseUrl}c_thumb,w_50,h_50,g_face,e_improve/${sections[1]}`
    },
    getTimeZonesList() {
      return momentTZ.tz.zonesForCountry('US')
    },
    getThumbImageMedium (imgUrl) {
      const baseUrl = 'https://res.cloudinary.com/kpdash/image/upload/'
      if (imgUrl.indexOf(baseUrl) === -1) {
        return imgUrl
      }
      const sections = imgUrl.split(baseUrl)
      return `${baseUrl}c_thumb,w_150,h_150,g_face,e_improve/${sections[1]}`
    },
    getThumbImageLarge (imgUrl) {
      const baseUrl = 'https://res.cloudinary.com/kpdash/image/upload/'
      if (imgUrl.indexOf(baseUrl) === -1) {
        return imgUrl
      }
      const sections = imgUrl.split(baseUrl)
      return `${baseUrl}c_thumb,w_135,h_200,g_face,z_0.75,e_improve/${sections[1]}`
    },
    getColor (name) {
      const colorList = [
        'primary',
        'success',
        'warning',
        'error',
      ]
      const index = name[0].charCodeAt(0)
      return colorList[index % colorList.length]
    },
    getProgressColor (progress) {
      if (progress < 0.3) return 'error'
      if (progress < 0.7) return 'warning'
      return 'success'
    },
    getColorFromText (text) {
      const colorList = [
        'red',
        'pink',
        'purple',
        'deep-purple',
        'indigo',
        'blue',
        'light-blue',
        'cyan',
        'teal',
        'green',
        'light-green',
        'amber',
        'orange',
        'deep-orange',
        'brown',
        'blue-grey',
      ]
      const sum = text.split('').reduce((a, b) => (a + b.charCodeAt(0) * b.charCodeAt(0)), 0)
      return colorList[sum % colorList.length]
    },
    getLocaleFromUTC (date, format) {
      const utc = moment.utc(date).toDate()
      return moment(utc).local().format(format)
    },
    getFormattedDate (date, format) {
      return moment(date).format(format)
    },
    removeAttachedFile(index) {
      this.files.splice(index, 1)
      this.currFiles.splice(index, 1)
    },
    removeAllAttachedFiles() {
      this.files = []
      this.currFiles = []
      this.isFilesInput = false
    },
    filesInputChanged () {
      this.currFiles = [
        ...this.currFiles,
        ...this.files
      ]
      this.currFiles = [...new Set(this.currFiles)]
      this.files = [...new Set(this.currFiles)]
    },
    packageData(obj, form, namespace) {
      const formData = form || new FormData()
      for(const property in obj) {

        let formKey

        if(obj.hasOwnProperty(property)) {
          if(namespace) {
            formKey = namespace + '[' + property + ']';
          } else {
            formKey = property;
          }

          if(typeof obj[property] === "boolean") {
            obj[property] = +obj[property]
          }

          if(typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
            this.packageData(obj[property], formData, property);
          } else {
            formData.append(formKey, obj[property]);
          }
        }
      }
      return formData;
    }
  },
});

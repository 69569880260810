<template>
  <v-dialog content-class="send_message" persistent max-width="1500" v-model="isModalOpen">
    <v-card>
      <v-progress-linear
        style="z-index: 6"
        color="primary"
        indeterminate
        height="4"
        v-if="recipientType && ((members && members.length === 0) || isLoading)"
      ></v-progress-linear>
      <v-icon
        class="d-flex ml-auto pt-2 pr-4 justify-end cursor-pointer mw-50"
        @click.native="closeModal()"
      >
        close
      </v-icon>
      <v-card-text>
        <v-layout wrap>
          <v-flex sm5 xs12>
            <v-card class="pt-0 mt-0" style="box-shadow: none !important;">
              <v-card-title class="pt-0 mt-0">
                <span class="title">Send a message</span>
                  <v-spacer></v-spacer>
                    <v-chip
                      x-small
                      class="ma-2"
                      color="primary"
                      label
                      href="https://help.kpdashboard.com/en/articles/8867317-sending-messages"
                      target="_blank"
                    >
                      Message/Variable Help
                      <v-icon right x-small>
                        mdi-open-in-new
                      </v-icon>
                   </v-chip>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-btn-toggle
                  class="mt-2 full-width"
                  v-model="recipientType"
                >
                  <v-btn
                    small
                    value="Advocates"
                    class=""
                    @click.native="onClickRecipientType('Advocates')"
                  >
                    ADVOCATES
                  </v-btn>
                  <v-btn
                    small
                    value="Coordinators"
                    class=""
                    @click.native="onClickRecipientType('Coordinators')"
                  >
                    COORDINATORS
                  </v-btn>
                  <div>
                    <v-menu
                      content-class="c-menu--popover"
                      :close-on-content-click="true"
                      :eager="true"
                      offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          small
                          value="KPs"
                          @click.native="onClickRecipientType('KPs')"
                        >
                          <span v-if="kpUnique === true">KPS: 1 Per Unique KP</span>
                          <span v-else-if="kpUnique === false">KPS: 1 Per Legislator</span>
                          <span v-else>KPS</span>
                          <v-icon v-if="!kpsDropDown" :color="isDarkTheme ? 'primary' : 'secondary'">mdi-chevron-down</v-icon>
                          <v-icon v-else-if="recipientType === 'KPs' && kpsDropDown" :color="isDarkTheme ? 'primary' : 'secondary'">mdi-chevron-up</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-radio-group v-model="kpUnique" small @change="kpsDropDown=false" class="c-card--popover px-3">
                          <v-radio label="1 Per Unique KP" :value="true" class="mb-4" ></v-radio>
                          <v-radio label="1 Per Legislator" :value="false"></v-radio>
                        </v-radio-group>
                      </v-list>
                    </v-menu>
                  </div>
                </v-btn-toggle>
                 <h3 v-if="kpUnique !== null" class="mt-4 full-width">Recipients ({{ selectedMembers.length }})</h3>

                <!-- SELECT MY OWN -->
                <v-flex v-if="!((recipientType === 'KPs' && kpUnique === null) || recipientType === null)" xs12 class="my-4" v-model="selectMyOwn">
                  <v-autocomplete
                      :label="`Select or search ${recipientType}`"
                      v-model="selectedMembers"
                      :items="members"
                      item-text="fullname"
                      :search-input.sync="searchInputFull"
                      @change="searchInputFull=''"
                      color="blue-grey lighten-2"
                      item-value="legislator.id"
                      return-object
                      hide-details
                      outlined
                      multiple
                      dense
                  >
                    <template v-slot:selection="{ item }"></template>
                    <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-avatar
                            class="avatar_image"
                            v-if="data.item.avatar_url"
                          >
                            <img
                              :alt="data.item.fullname"
                              :src="getThumbImage(data.item.avatar_url)"
                              :style="kpPreferenceBorder(data.item)"
                            >
                          </v-list-item-avatar>
                          <v-list-item-avatar
                            v-if="!data.item.avatar_url"
                            :style="kpPreferenceBorder(data.item)"
                            :color="getColor(data.item.fullname)"
                            class="justify-center"
                          >
                            <span class="white--text headline">{{ data.item.nickname[0] }}</span>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ data.item.fullname }} {{
                                data.item.legislator && data.item.legislator.id && !kpUnique ? `( ${data.item.legislator.fullname} )` : ''
                              }}
                              <v-icon left size="16" :color="isBad(data.item.email) ? 'error' : 'primary'">
                                email
                              </v-icon>
                              <v-icon
                                v-if="!data.item.bad_cell || data.item.cell !== null"
                                left
                                size="16"
                                color="primary"
                              >
                                phone_iphone
                              </v-icon>
                              <v-icon
                                v-else
                                left
                                size="16"
                                color="error"
                              >
                                no_cell
                              </v-icon>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                    </template>
                  </v-autocomplete>

                  <v-checkbox
                    v-if="recipientType !== 'Coordinators'"
                    color="primary"
                    :label="`Select all ${recipientType}`"
                    class="mt-2"
                    :input-value="allMembers"
                    @change="changeAllMembers"
                  ></v-checkbox>
                  <v-flex xs12 :class="recipientType === 'Coordinators' ? 'my-4' : 'mb-4'">
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header>Recipients ({{ selectedMembers.length }})</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-select
                            dense
                            outlined
                            return-object
                            :items="selectedMembers"
                            v-model="selectedMembers"
                            item-text="fullname"
                            :item-value="getSelectedItemValue()"
                            multiple
                            label="Select Advocate(s)"
                          >
                            <template v-slot:selection="{ item, index }">
                              <v-chip
                                  close
                                  label
                                  class="mt-2"
                                  v-on="on"
                                  :style="colorsLegislatorsParties(item, members)"
                                  @click:close="selectedMembers.splice(index, 1)"
                              >
                              {{ item.fullname }} {{
                                    item.legislator && item.legislator.id && !kpUnique ? `( ${item.legislator.fullname} )` : ''
                                  }}
                                  <v-icon class="ml-1" left size="16"
                                          :color="isBad(item.email) ? 'error' : 'primary'">
                                    email
                                  </v-icon>
                                  <v-icon
                                    v-if="!item.bad_cell || item.cell !== null"
                                    left
                                    size="16"
                                    color="primary"
                                  >
                                    phone_iphone
                                  </v-icon>
                                  <v-icon
                                    v-else
                                    left
                                    size="16"
                                    color="error"
                                  >
                                    no_cell
                                  </v-icon>
                              </v-chip>
                            </template>
                          </v-select>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-flex>
                  </v-flex>

                <!-- USE FILTER -->
                <v-flex xs12 v-if="recipientType === 'Advocates'">
                  <advocates-filter
                    :client="client"
                    :legislators="legislators"
                    :committeeList="committeeList"
                    @refreshAdvocates="refreshAdvocates"
                  ></advocates-filter>
                </v-flex>
                <v-flex xs12 v-if="recipientType === 'KPs' && kpUnique !== null">
                  <kps-filter
                    :client="client"
                    :legislators="legislators"
                    :committeeList="committeeList"
                    :sessionTasks="sessionTasks"
                    :filterTasks="filterTasks"
                    :filterTaskMenuData="filterTaskMenuData"
                    @refreshAdvocates="refreshKps"
                  ></kps-filter>
                </v-flex>

                <!-- SELECT KPS COPY -->
                <v-flex xs12 class="my-4" v-if="isKpsSend && kpsCopy.length > 0 && recipientType === 'KPs'">
                  <v-expansion-panels v-model="isOtherKpsOpen ">
                    <v-expansion-panel>
                      <v-expansion-panel-header>Recipients KP2+ ({{ kpsCopy.length }})</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-select
                          v-model="kpsCopy"
                          :items="kpsCopy"
                          label="Selected KP2+(s)"
                          item-text="member.fullname"
                          return-object
                          outlined
                          multiple
                          dense
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip
                              :style="kpPreferenceBorder(item.member)"
                              :key="item.id"
                              @click:close="closeKpsCopyChip(index)"
                              close
                              label
                            >
                              <v-icon left size="16" :color="isBad(item.member.email) ? 'error' : 'primary'">
                                email
                              </v-icon>
                              <v-icon
                                v-if="item.member.bad_cell || item.member.cell === null"
                                left
                                size="16"
                                color="error"
                              >
                                no_cell
                              </v-icon>
                              <v-icon
                                v-else
                                left
                                size="16"
                                color="primary"
                              >
                                phone_iphone
                              </v-icon>
                              {{ item.member.fullname }} {{ item.kp_position }} {{ !kpUnique ? `( ${item.legislator.fullname} )` : '' }}
                            </v-chip>
                          </template>
                        </v-select>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>

              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex sm7 xs12>
            <send-message
              ref="sendMessage"
              :client="client"
              :recipientType="recipientType"
              :members="selectedMembers"
              :kpsCopy="kpsCopy"
              :unique="kpUnique"
              :filter="filter"
              @changeKpsSend="changeKpsSend"
              @close="closeModal"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TinyConfig from "@/mixins/tiny-mce-config"

import ClientService from "@/services/ClientService";
import MemberService from "@/services/MemberService";
import filter from "@/components/kp-tasks/send-message-modal/advocates-filter"
import KpsFilter from "@/components/kp-tasks/send-message-modal/kps-filter"
import SendMessage from "@/components/kp-tasks/send-message-modal/send-message-component"
import StateService from "@/services/StateService";
import _ from "lodash";
import CustomFieldsFilter from "@/mixins/custom-fields-filter";
import SessionService from "@/services/SessionService";

export default {
  name: 'LargeMessageModal',
  mixins: [TinyConfig, ClientService, MemberService, StateService, CustomFieldsFilter, SessionService],
  components: {
    'advocates-filter': filter,
    'kps-filter': KpsFilter,
    'send-message': SendMessage,
  },
  props: {
    on: {
      type: Object
    },
    client: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      advocates: [],
      allMembers: false,
      isKpsSend: false,
      coordinators: [],
      displaySelected: {},
      isOtherKpsOpen: false,
      isModalOpen: false,
      isLoading: false,
      kps: [],
      recipientType: null,
      kpUnique: null,
      members: [],
      kpsCopy: [],
      selectMyOwn: false,
      searchInputFull: '',
      selectedMembers: [],
      useFilter: false,
      committeeList: [],
      legislators: [],
      sessionTasks: [],
      filterTasks: {},
      filter: {},
      filterTaskMenuData: {},
      kpsDropDown: false,
    }
  },
  computed: {},
  methods: {
    async toggle() {
      this.isModalOpen = true
      setTimeout(() => {
        this.$refs.sendMessage.init()
      }, 0)
      this.recipientType = null
      this.kpUnique = null
      await this.init()
    },
    init() {
      this.isLoading = true
      if (!(this.client && this.client.id)) return
      this.getAdvocates()
      this.getCoordinators()
      //this.getKps()
      this.getLegislators()
      this.getCommitteesList()
      this.getActiveSessionTasks()
      this.getSendMessageFilteredTasks()
      this.isLoading = false
    },
    getActiveSessionTasks() {
      this.getTasks(this.client.id)
        .then((response) => {
          this.sessionTasks = response.data.data ? response.data.data : response.data
          this.sessionTasks.forEach(task => {
            this.filterTasks[`${task.id}`] = false
          })

        })
        .catch(err => {
          this.handleError(err)
        })
    },
    async getSendMessageFilteredTasks() {
      let params = {
        kpUnique: this.kpUnique
      }
      const data = (await this.sendMessageFilteredTask(this.client.id, params)).data
      if(data) {
        data.forEach((messageFilter) => {
          this.filterTaskMenuData[messageFilter.id] = {
            All: messageFilter.all_count,
            Completed: messageFilter.completed_count,
            Uncompleted: messageFilter.all_count - messageFilter.completed_count,
            selected: 'All'
          }
        })
      }
    },
    changeKpsSend(value) {
      this.isKpsSend = value
    },
    changeFilter() {
      if (this.useFilter || this.recipientType === 'Coordinators') {
        this.selectedMembers = this.members
      } else {
        this.selectedMembers = []
      }
    },
    onClickRecipientType(value) {
      this.filter = []
      this.useFilter = false
      if (this.recipientType !== value) {
        this.recipientType = value
      }
      if(this.recipientType === 'KPs') {
        this.kpsDropDown = !this.kpsDropDown
      } else {
        this.kpsDropDown = false
        this.kpUnique = null
      }
      this.changeRecipients()
    },
    getKpsCopy() {
      this.kpsCopy = []
      if (this.kpUnique) {
        _.cloneDeep(this.selectedMembers)
          .forEach(item => {
            if (!item.legislator) return
            item.legislator.member_legislators.forEach(kp => {
              const isKp1 = this.kps.some(member => member.id === kp.member_id)
              const duplicate = this.kpsCopy.some(member => member.id === kp.member_id)
              if (kp.kp_position !== 1 && kp.send_copy && kp.end_date == null && !isKp1 && !duplicate) {
                this.kpsCopy.push({ ...kp, legislator: item.legislator })
              }
            })
          })
      } else {
        _.cloneDeep(this.selectedMembers).forEach(item => {
          if (!item.legislator) return
          item.legislator.member_legislators.forEach(kp => {
            if (kp.kp_position !== 1 && kp.send_copy && kp.end_date == null) {
              this.kpsCopy.push({ ...kp, legislator: item.legislator })
            }
          })
        })
      }
    },
    getLegislators() {
      let params = {
        order: 'lastname',
      }
      this.getClientLegislators(this.client.id, params)
        .then((response) => {
          this.legislators = response.data.data ? response.data.data : response.data
        })
        .catch(err => {
          this.handleError(err)
        })
    },
    getCommitteesList() {
      this.getStateCommittees(this.client.state_id)
        .then(response => {
          this.committeeList = response.data
        })
        .catch(err => {
          this.handleError(err)
        })
    },
    changeAllMembers(event) {
      if (event) {
        this.selectedMembers = this.members
      } else {
        this.selectedMembers = []
      }
    },
    getIndex(id, array) {
      return array.findIndex(item => item.id === id)
    },
    //changeKpUnique() {
      //this.members = _.cloneDeep(this.kps);
    //  this.selectedMembers = [];
    //  this.getKpsCopy()
    //},
    getSelectedItemValue(){
      let value = '';
      if(this.recipientType === 'KPs' && !this.kpUnique) {
        value = `legislator.id`;
      }
      return value;
    },
    getKps(isFilterSubmitted) {
      this.kps = [];
      this.isLoading = true
      this.filter.tasks = this.filter.tasks ? this.filter.tasks : []
      this.filter.exceptLegislators = this.filter.exceptLegislators ? this.filter.exceptLegislators : []
      this.filter.legislators = this.filter.legislators ? this.filter.legislators : []
      let params = {
        legislators: _.cloneDeep(this.filter.legislators).map(legislator => legislator.id),
        reduceLegislators: _.cloneDeep(this.filter.exceptLegislators).map(legislator => legislator.id),
        tasks: this.filter.tasks,
        kpUnique: this.kpUnique
      }
      
      this.getFirstKps(this.client.id, params)
        .then((response) => {
          const kps = response.data.data ? response.data.data : response.data;
          this.kps = kps.map(kp => {
            const memberLegislator = kp.member_legislator.find(memberLegislator => memberLegislator.legislator.id === kp.legislator_id)
            return {
              ...kp,
              legislator: memberLegislator.legislator
            }
          })

          this.changeRecipients()

          this.isLoading = false

          //set kps from api
          this.members = _.cloneDeep(this.kps);
          if(isFilterSubmitted) {
            this.selectedMembers = this.members;
          }
          this.getKpsCopy();
          
        })
        .catch(err => {
          this.isLoading = false
          this.handleError(err)
        })
    },
    getAdvocates() {
      this.isLoading = true
      this.filter.exceptLegislators = this.filter.exceptLegislators ? this.filter.exceptLegislators : []
      this.filter.legislators = this.filter.legislators ? this.filter.legislators : []
      this.filter.customFields = this.filter.customFields ? this.filter.customFields : []
      this.filter.kpPreferenceResults = this.filter.kpPreferenceResults ? this.filter.kpPreferenceResults : []
      let params = {
        unlimit: 1,
        showInactive: this.filter.showInactive ? this.filter.showInactive : null,
        showInvalid: this.filter.showInvalid ? this.filter.showInvalid : null,
        districtNotSet: this.filter.districtNotSet ? this.filter.districtNotSet : null,
        activeKP1: this.filter.activeKP1 ? this.filter.activeKP1 : null,
        legislators: _.cloneDeep(this.filter.legislators).map(legislator => legislator.id),
        reduceLegislators: _.cloneDeep(this.filter.exceptLegislators).map(legislator => legislator.id),
        custom_fields: this.collectCustomFieldsFilter(),
        kp_preference: this.filter.kpPreferenceResults
      }

      const clientId = this.client && this.client.id || '';
      this.getClientMembersWithPagination(clientId, params)
        .then((response) => {
          this.advocates = response.data.data ? response.data.data : response.data
          this.changeRecipients()
          this.isLoading = false
        })
        .catch(err => {
          this.isLoading = false
          this.handleError(err)

        })
    },
    getCoordinators() {
      this.getClientRegions(this.client.id)
        .then(response => {
          const data = response.data
          this.coordinators = data
            .filter(region => region.member && region.member.id)
            .map(region => {
              return {
                ...region.member,
                region: region
              }
            })
          this.changeRecipients()
        })
        .catch(err => {
          this.handleError(err)
        })
    },
    changeRecipients() {
      if (this.recipientType === 'Advocates') {
        this.selectedMembers = [];
        this.members = _.cloneDeep(this.advocates)
      } else if (this.recipientType === 'Coordinators') {
        this.members = _.cloneDeep(this.coordinators)
        this.selectedMembers = this.members
      } else if (this.recipientType === 'KPs') {
        //this.changeKpUnique()
      }
      this.changeFilter()
    },
    refreshAdvocates(filter) {
      this.useFilter = true
      this.filter = filter
      this.getAdvocates()
    },
    refreshKps(filter) {
      this.useFilter = true
      this.filter = filter
      this.getKps(true)
    },
    closeKpsCopyChip(memberIndex) {
      this.kpsCopy.splice(memberIndex, 1)
      if (this.kpsCopy.length < 1) {
        this.isKpsSend = false
      }
    },
    closeModal() {
      this.advocates = []
      this.allMembers = false
      this.isKpsSend = false
      this.coordinators = []
      this.displaySelected = {}
      this.isOtherKpsOpen = false
      this.isModalOpen = false
      this.isLoading = false
      this.kps = []
      this.recipientType = null
      this.kpUnique = null
      this.members = []
      this.kpsCopy = []
      this.selectMyOwn = false
      this.searchInputFull = ''
      this.selectedMembers = []
      this.useFilter = false
      this.committeeList = []
      this.legislators = []
      this.sessionTasks = []
      this.filterTasks = {}
      this.filter = {}
      this.filterTaskMenuData = {}
      this.kpsDropDown = false
      this.isModalOpen = false
    }
  },
  watch: {
    selectedMembers: function () {
      if (this.selectedMembers && this.selectedMembers.length === this.members.length) {
        this.allMembers = true
      } else {
        this.allMembers = false
      }

      _.cloneDeep(this.selectedMembers).forEach(item => {
        const memberLegislators = item && item.legislator && item.legislator.member_legislators || [];

        memberLegislators.forEach(kp => {
          if (kp.kp_position !== 1 && kp.send_copy && kp.end_date == null) {
            this.kpsCopy.push({ ...kp, legislator: item.legislator })
          }
        })

      })
    },

    kpUnique(newValue, oldValue) {
      if(newValue !== null) {
        this.getKps();
        this.getSendMessageFilteredTasks();
      }if (newValue !== oldValue) {
        this.selectedMembers = []  
      }
    }
  }
};
</script>
